import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { warningSwal, SuccessSwal } from "Util/Toast";
import EarningClasses from "./Earning.module.css";
import Moment from "react-moment";
import { Services } from "Services";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmationDiaolog } from "Util/Toast";
import Penalty from "../Penalty";
import { AccessControl } from "../../../../../Util/AccessControl";
import { AccessKeys } from "../../../../../Routes/config";
function Earnings() {
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const formData = watch();
    const [loading, setLoading] = useState(true);
    const [showPenalty, setShowPenalty] = useState(false);
    const [penaltyDetails, setPenaltyDetails] = useState({});
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [params] = useSearchParams();
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [earning, setEarning] = useState({
        AmountToCredit: 0,
        AmountToDebit: 0,
        Tutors_Earnings: [],
    });
    const get_Earnings = (body) => {
        if (!loading) {
            setLoading(true);
        }
        Services.Get_Tutor_Earnings("POST", JSON.stringify(body), token)
            .then((res) => {
                setLoading(false);
                if (res.Status === 1) {
                    setEarning({
                        Tutors_Earnings: res.Tutors_Earnings,
                        AmountToDebit: res.AmountToDebit,
                        AmountToCredit: res.AmountToCredit,
                    });
                } else if (res.Status === 0) {
                    warningSwal("Error", res?.Message);
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                warningSwal("Error", "Something went wrong ");
                console.log(err);
            });
    };
    useEffect(() => {
        let body = { TutorID: params.get("TutorId"), Type: "ALL" };
        get_Earnings(body);
    }, []);
    const onSubmit = (data) => {
        if (data.FromDate > data.ToDate) {
            warningSwal("Warning", "From Date should be less than To Date");
            return false;
        }
        let body = {
            TutorID: params.get("TutorId"),
            Type: "DATE",
            ...data,
        };
        get_Earnings(body);
        setLoading(true);
    };
    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        setSelectedRows(
            selectAll
                ? []
                : earning?.Tutors_Earnings?.map((row) => row.TutorEarningID)
        );
    };
    const toggleRowSelection = (rowId) => {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(selectedRows.filter((id) => id !== rowId));
        } else {
            setSelectedRows([...selectedRows, rowId]);
        }
    };
    const PAYOUT_CALL = (props) => {
        setLoading(true);

        let body = {
            EarningsIds: selectedRows,
            TutorID: params.get("TutorId"),
            CreditedAmount: props.creditSum,
            DebitedAmount: props.debitSum,
            AmountToPay: props.creditSum - props.debitSum,
        };
        Services.Create_Payouts("POST", JSON.stringify(body), token)
            .then((res) => {
                setTimeout(() => {
                    setSelectAll(false);
                    setSelectedRows([]);
                    reset();
                    let data = { TutorID: params.get("TutorId"), Type: "ALL" };
                    get_Earnings(data);
                }, 300);
                if (res.Status === 1) {
                    SuccessSwal("Successful", "Payout created successfully");
                } else if (res.Status === 0) {
                    warningSwal("Warning", res.Message);
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                warningSwal("Error", "Something went wrong ");
                console.log(err);
            });
    };
    const CreatePayoutHandler = () => {
        const selectedEarnings = earning?.Tutors_Earnings?.filter((earning) =>
            selectedRows.includes(earning.TutorEarningID)
        );

        // Initialize variables to store the sum of amounts for credit and debit
        let creditSum = 0;
        let debitSum = 0;

        // Iterate through the selected earnings
        selectedEarnings.forEach((earning) => {
            // Check the value of Credit_Or_Debit and accumulate the amount accordingly
            if (earning.Credit_Or_Debit === "CREDIT") {
                creditSum += earning.Amount;
            } else if (earning.Credit_Or_Debit === "DEBIT") {
                debitSum += earning.Amount;
            }
        });
        if (creditSum - debitSum < 0) {
            warningSwal("Warning", "Total Payout Amount cannot be negative");
            return;
        }
        ConfirmationDiaolog(
            "Are You sure you want to create Payout?",
            "Create Payout",
            PAYOUT_CALL,
            {
                debitSum,
                creditSum,
            }
        );
    };
    const checkPenalty = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("id");
        // id={TutorEarningID}
        let [earningData] = earning.Tutors_Earnings.filter(
            (item) => item.TutorEarningID == id
        );
        setPenaltyDetails(earningData);
        setShowPenalty(true);
    };
    return (
        <div className={EarningClasses["Container"]}>
            {showPenalty && (
                <Penalty
                    modal={showPenalty}
                    setModal={setShowPenalty}
                    getDetails={{
                        get_Earnings,
                        penaltyDetails: penaltyDetails,
                        TutorID: params.get("TutorId"),
                        ToDate: formData.ToDate,
                        FromDate: formData.FromDate,
                        payoutDetails: false,
                    }}
                />
            )}
            <h3>Earnings</h3>

            {/* <p>Filter By Date</p> */}
            <header>
                <form
                    className={EarningClasses["form"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("FromDate", {
                            required: true,
                        })}
                    />
                    <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("ToDate", {
                            required: true,
                        })}
                    />
                    <button>Filter</button>
                </form>
                <div>
                    <button
                        onClick={CreatePayoutHandler}
                        disabled={selectedRows.length == 0}
                        title={
                            selectedRows.length == 0
                                ? "Select Earnings to create Payout"
                                : ""
                        }>
                        Create Payout
                    </button>
                </div>
            </header>
            <div className={EarningClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            {earning?.Tutors_Earnings?.length > 0 && (
                                <th>
                                    <input
                                        type="checkbox"
                                        checked={selectAll}
                                        onChange={toggleSelectAll}
                                    />
                                </th>
                            )}
                            <th>Class Date</th>
                            <th>Class Time</th>
                            <th>Duration</th>
                            <th>Batch Name</th>
                            <th>Type</th>
                            <th>Credit</th>
                            <th> Debit</th>
                            <th>Status</th>
                            {AccessControl(AccessKeys.Tutor)?.edit === 1 && (
                                <th>Actions</th>
                            )}
                        </tr>
                    </thead>
                    {loading ? (
                        <tbody>
                            {[1, 1].map((item) => (
                                <tr className={EarningClasses["loading"]}>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    {AccessControl(AccessKeys.Tutor)?.edit ===
                                        1 && (
                                        <td>
                                            <Skeleton />
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            {earning?.Tutors_Earnings?.length > 0 ? (
                                earning?.Tutors_Earnings?.map((item) => {
                                    return (
                                        <tr
                                            onClick={() =>
                                                toggleRowSelection(
                                                    item.TutorEarningID
                                                )
                                            }>
                                            {" "}
                                            <td>
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRows.includes(
                                                        item.TutorEarningID
                                                    )}
                                                    onChange={() =>
                                                        toggleRowSelection(
                                                            item.TutorEarningID
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {
                                                        new Date(
                                                            item.Scheduled_StartDate
                                                        )
                                                    }
                                                </Moment>
                                            </td>
                                            <td>{item.Scheduled_StartTime}</td>
                                            <td>
                                                {item.DurationInMinutes} Min
                                            </td>
                                            <td
                                                style={{
                                                    maxWidth: "12rem",
                                                    wordBreak: "break-word",
                                                }}>
                                                {item.BatchName}
                                            </td>
                                            <td>
                                                {item.EarningType ===
                                                    "Lesson_Cancel" &&
                                                    "Lesson Cancel"}
                                                {item.EarningType ===
                                                    "Lesson_Complete" &&
                                                    "Lesson Complete"}
                                            </td>
                                            <td
                                                style={{
                                                    color:
                                                        String(
                                                            item.Credit_Or_Debit
                                                        ).toLowerCase() ===
                                                        "DEBIT".toLowerCase()
                                                            ? "red"
                                                            : "green",
                                                }}>
                                                {item.Credit_Or_Debit != "DEBIT"
                                                    ? isNaN(item.Amount)
                                                        ? 0.0
                                                        : `₹ ${Number(
                                                              item.Amount
                                                          ).toFixed(2)}`
                                                    : "-"}
                                            </td>
                                            <td
                                                style={{
                                                    color:
                                                        String(
                                                            item.Credit_Or_Debit
                                                        ).toLowerCase() ===
                                                        "DEBIT".toLowerCase()
                                                            ? "red"
                                                            : "green",
                                                }}>
                                                {item.Credit_Or_Debit == "DEBIT"
                                                    ? isNaN(item.Amount)
                                                        ? 0.0
                                                        : `₹ ${Number(
                                                              item.Amount
                                                          ).toFixed(2)}`
                                                    : "-"}
                                            </td>
                                            <td>
                                                <span
                                                    className={
                                                        item.Credit_Debit_Status ===
                                                        1
                                                            ? EarningClasses[
                                                                  "paid"
                                                              ]
                                                            : EarningClasses[
                                                                  "unpaid"
                                                              ]
                                                    }>
                                                    {item.Credit_Debit_Status ===
                                                    1
                                                        ? "paid"
                                                        : "pending"}
                                                </span>
                                            </td>
                                            {AccessControl(AccessKeys.Tutor)
                                                ?.edit === 1 && (
                                                <td>
                                                    {item.IsPenalityChallenged ===
                                                    2 ? (
                                                        <>
                                                            <button
                                                                id={
                                                                    item.TutorEarningID
                                                                }
                                                                onClick={
                                                                    checkPenalty
                                                                }
                                                                style={{
                                                                    padding:
                                                                        "0.5rem",
                                                                    border: "1px solid #ccc",
                                                                    backgroundColor:
                                                                        "white",
                                                                }}>
                                                                View Penalty
                                                                Challenge
                                                            </button>
                                                        </>
                                                    ) : (
                                                        "NA"
                                                    )}
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={8}
                                        style={{ textAlign: "center" }}>
                                        No Record Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    );
}

export default Earnings;
