import React from "react";
import daysClasses from "./index.module.css";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import TimePicker from "rc-time-picker";
import moment from "moment";
import { PreferredDays } from "../../../enrolled/enrolled/filter/helper";
import { AiOutlineClose } from "react-icons/ai";
function PreferredDay({ days, setDays }) {
    const [parent] = useAutoAnimate(/* optional config */);
    const handleDayChange = (index, event) => {
        const newDay = [...days];
        newDay[index].day = event.target.value;
        setDays(newDay);
    };

    const handleTimeChange = (index, event) => {
        const newTime = [...days];
        newTime[index].Time = moment(event).format("HH:mm");
        console.log(event);
        setDays(newTime);
    };
    function roundToNearest5Minutes(time) {
        const remainder = 5 - (time.minute() % 5);
        return moment(time).add(remainder, "minutes").startOf("minute");
    }
    const addDay = () => {
        const roundedTime = roundToNearest5Minutes(moment());
        const formattedTime = roundedTime.format("HH:mm"); // Format as "12:30"
        setDays([...days, { day: "", Time: formattedTime }]);
    };
    const removeDay = (index) => {
        const newDays = [...days];
        newDays.splice(index, 1);
        setDays(newDays);
    };
    return (
        <div className={daysClasses["skill-Wrapper"]}>
            <header>
                <h3>
                    Preferred Days <span className="important">*</span>
                </h3>
                <button type="button" onClick={addDay}>
                    + Add Day
                </button>
            </header>
            <div ref={parent} className={daysClasses["alldays"]}>
                {days.map((item, index) => (
                    <div key={index} className={daysClasses["skills"]}>
                        <div className={daysClasses["form-control"]}>
                            <label>Day:</label>
                            <select
                                value={item.day}
                                onChange={(event) =>
                                    handleDayChange(index, event)
                                }>
                                <option value="">--Select--</option>
                                {PreferredDays.map((item) => (
                                    <option value={item.value}>
                                        {item.value}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div
                            className={`${daysClasses["form-control"]} ${daysClasses["form-control-select"]}`}>
                            <div className={daysClasses["form-control"]}>
                                <label>Time:</label>
                                <TimePicker
                                    // onOpen={checkStartDate}
                                    value={moment(item.Time, "HH:mm")}
                                    defaultOpenValue={moment()}
                                    // focusOnOpen={true}
                                    minuteStep={5}
                                    allowEmpty={false}
                                    showSecond={false}
                                    placement={"topRight"}
                                    onChange={(date) => {
                                        handleTimeChange(index, date);
                                    }}
                                    format="h:mm a"
                                    use12Hours
                                />
                            </div>
                            {index > 0 && (
                                <button
                                    type="button"
                                    onClick={() => removeDay(index)}>
                                    <AiOutlineClose size={12} />
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PreferredDay;
