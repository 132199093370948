import React from "react";
import TutorClasses from "./Tutor.module.css";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
function TutorTable({ data }) {
    const Navigate = useNavigate();
    const clickHandler = (id, name, e) => {
        let status = e.currentTarget.getAttribute("status");
        Navigate(`/Tutor/Details?TutorId=${id}&tab=Profile`);
        localStorage.setItem("name", name);
        localStorage.setItem("status", status);
    };
    return (
        <>
            <div className={TutorClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Mobile</th>
                            <th>Fee Per Hour</th>
                            <th>Email</th>
                            <th>State</th>
                            <th>Created </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.length > 0 ? (
                            data?.map((item) => (
                                <tr
                                    status={item.TutorStatus}
                                    onClick={(e) => {
                                        clickHandler(
                                            item.TutorID,
                                            `${item.TutorName}`,
                                            e
                                        );
                                    }}>
                                    <td
                                        style={{
                                            maxWidth: "150px",
                                            wordBreak: "break-word",
                                        }}>
                                        {item.TutorName}{" "}
                                    </td>
                                    <td>
                                        {item?.CountryCode != "NA" &&
                                            item.CountryCode}{" "}
                                        {item.MobileNumber1}
                                    </td>
                                    <td>₹ {item.FeePerHour}</td>
                                    <td
                                        style={{
                                            maxWidth: "200px",
                                            wordBreak: "break-word",
                                        }}>
                                        {item.EmailID}
                                    </td>

                                    <td>{item.State} </td>
                                    <td>
                                        <Moment format="DD-MM-YYYY">
                                            {item.CreatedAt}
                                        </Moment>{" "}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td style={{ textAlign: "center" }} colSpan={7}>
                                    No Tutor Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default TutorTable;
