import React from "react";
import DasboardClasses from "../dashboard.module.css";
import SquareUser from "Assets/square-user.jpg";
function Balances() {
    return (
        <div className={DasboardClasses["tables_Container"]}>
            <div className={DasboardClasses["table"]}>
                <h3>Low Balance Users</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Parent Name</th>
                            <th>balance </th>
                            <th>Email</th>
                            <th>Contact Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                    </tbody>
                </table>
                <button>View More</button>
            </div>
            <div className={DasboardClasses["table"]}>
                <h3>Outstanding Balance Users</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Parent Name</th>
                            <th>balance </th>
                            <th>Email</th>
                            <th>Contact Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                        <tr>
                            <td>
                                {" "}
                                <div>
                                    <img src={SquareUser} alt="SquareUser" />{" "}
                                    Learn2read
                                </div>
                            </td>
                            <td>₹ 25</td>
                            <td>Test@gmail.com</td>
                            <td>9320222222</td>
                        </tr>
                    </tbody>
                </table>
                <button>View More</button>
            </div>
        </div>
    );
}

export default Balances;
