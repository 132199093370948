import { Helmet } from "react-helmet";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import Tabs from "Components/Tabs/Tabs";
import { AccessKeys } from "Routes/config";
import Layout from "Components/Layout/index";
import { AccessControl } from "Util/AccessControl";
import CommonClasses from "Styles/Common.module.css";
import Categories from "./categories";
function Tutor() {
    const Navigate = useNavigate();
    return (
        <Layout Active={"Categories"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Categories</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={CommonClasses["control-btns"]}>
                    {AccessControl(AccessKeys.Categories).write == 1 && (
                        <button
                            className={CommonClasses["button"]}
                            onClick={() => {
                                Navigate("/categories/add");
                            }}>
                            <BiPlus size={20} /> Create New Category
                        </button>
                    )}
                </div>
                <div className={CommonClasses["wrapper"]}>
                    <h3>Categories</h3>
                    <Categories />
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
