import React, { useState } from "react";
import SkillClasses from "./index.module.css";
import MainLoader from "../../../Components/loader/loader";
import { Services } from "../../../Services";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useSearchParams } from "react-router-dom";
function SkillForm({ skills, setSkills, path }) {
    const [params] = useSearchParams();
    const [parent] = useAutoAnimate(/* optional config */);
    const [loading, setloading] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const handleSkillChange = (index, event) => {
        const newSkills = [...skills];
        newSkills[index].Question = event.target.value;
        setSkills(newSkills);
    };

    const handleLevelChange = (index, event) => {
        const newSkills = [...skills];
        newSkills[index].Answer = event.target.value;
        setSkills(newSkills);
    };

    const addSkill = () => {
        setSkills([...skills, { Question: "", Answer: "" }]);
    };

    const removeSkill = (index, e) => {
        let id = e.currentTarget.getAttribute("id");
        console.log(path, id);
        if (params.get("id") && id) {
            setloading(true);
            Services.deletecourseQuestion("DELETE", null, token, id)
                .then((response) => {
                    setloading(false);
                    if (response.Status === 1) {
                        SuccessSwal("Success", response.Message);
                        const newSkills = [...skills];
                        newSkills.splice(index, 1);
                        setSkills(newSkills);
                    } else if (response.Status === 0) {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setloading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            const newSkills = [...skills];
            newSkills.splice(index, 1);
            setSkills(newSkills);
        }
    };

    return (
        <div className={SkillClasses["skill-Wrapper"]}>
            {loading && <MainLoader />}
            <header>
                <h3>Question</h3>
                <button type="button" onClick={addSkill}>
                    + Add Question
                </button>
            </header>
            <div ref={parent}>
                {skills.map((skill, index) => (
                    <div key={index} className={SkillClasses["skills"]}>
                        <div className={SkillClasses["form-control"]}>
                            <label>{index + 1}. Question</label>
                            <input
                                type="text"
                                value={skill.Question}
                                onChange={(event) =>
                                    handleSkillChange(index, event)
                                }
                            />
                        </div>
                        <div
                            className={`${SkillClasses["form-control"]} ${SkillClasses["form-control-select"]}`}>
                            <div>
                                <label>Answer</label>
                                <input
                                    type="text"
                                    value={skill.Answer}
                                    onChange={(event) =>
                                        handleLevelChange(index, event)
                                    }
                                />
                            </div>
                            {index > 0 && (
                                <button
                                    type="button"
                                    id={skill.QA_ID}
                                    onClick={(e) => removeSkill(index, e)}>
                                    Close
                                </button>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default SkillForm;
