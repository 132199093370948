import React, { useState } from "react";
import Modal from "react-modal";
import RepeatClasses from "./repeat.module.css";
import { Services } from "../../../Services";
import { AiOutlineClose } from "react-icons/ai";
import { Error_Dark, Success_Dark } from "../../../Util/Toast";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import swal from "sweetalert";
import { warningSwal, SuccessSwal } from "../../../Util/Toast";
import ReactLoading from "react-loading";
function Repeat({ modal, setModal, classdata, details }) {
    const [load, setLoad] = useState(false);
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        reset,
    } = useForm();
    const watchData = watch();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const onSubmit = async (data) => {
        if (!data.WeekDays || data?.WeekDays?.length == 0) {
            swal({
                title: "Error",
                text: "Please Select Days",
                icon: "error",
            });
            return;
        }

        setLoad(true);
        console.log(classdata);
        let body = JSON.stringify({
            ...data,
            BatchSize: classdata.BatchSize,
            ScheduleCreatedBy: JSON.parse(localStorage.getItem("admindata"))
                .AdminUserID,
            BatchName: classdata.BatchName,
            ClassDuration: classdata?.ClassDuration,
            BatchID: classdata.BatchID,
            Type: JSON.parse(localStorage.getItem("admindata")).Type,
            LessonNumber: classdata.LessonNumber,
            BatchID: classdata?.BatchID,
            ScheduleClassID: classdata?.ScheduleClassID,
            CourseName: classdata?.CourseName,
            CourseID: classdata?.CourseID,
            BatchName: classdata.BatchName,
            TutorID: classdata?.TutorID,
            TutorName: classdata?.TutorName,
            Students: classdata.Students,
            TutorEmailID: classdata?.TutorEmailID,
            Scheduled_EndDate: classdata?.Scheduled_EndDate,
            ZoomEmailID: classdata?.ZoomEmailID,
            WeekDays: data.WeekDays ? data.WeekDays : [],
            Scheduled_StartTime: classdata.Scheduled_StartTime,
            Scheduled_StartDate: classdata.Scheduled_StartDate,
            Scheduled_EndTime: classdata.Scheduled_EndTime,
            StartDateTime: `${String(classdata.Scheduled_StartDate).slice(
                0,
                10
            )} ${classdata.Scheduled_StartTime}`,
        });

        try {
            const Repeat = await Services.RepeatClass("POST", body, token);
            if (Repeat?.Status == 1) {
                SuccessSwal(Repeat.Message, "Classes Repeat Success!");
                details();
                setLoad(false);
                reset();
                setModal(false);
            }
            if (Repeat?.Status == 0) {
                setLoad(false);
                warningSwal("Warning", Repeat.Message);
            }
        } catch (err) {
            setLoad(false);
            Error_Dark("something went wrong try again");
            console.log(err);
        }
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={RepeatClasses["modal"]}
            overlayClassName={RepeatClasses["overlay"]}>
            <div className={RepeatClasses["wrapper"]}>
                <header>
                    <h3>Repeat Lesson</h3>
                    <button
                        onClick={() => {
                            setModal(false);
                        }}>
                        <AiOutlineClose />
                    </button>
                </header>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={RepeatClasses["form-control"]}>
                        <label htmlFor="Repeat">
                            Repeat <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <select
                            defaultValue={"Never"}
                            {...register("Repeat", {
                                required: true,
                            })}>
                            <option value="Weekly">Weekly</option>
                        </select>
                        {errors.Repeat && (
                            <span className={RepeatClasses["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>

                    <div className={`${RepeatClasses["form-control-select"]} `}>
                        <label>Select days:</label>
                        <div className={RepeatClasses["checkbox"]}>
                            {[
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday",
                                "Sunday",
                            ].map((day) => (
                                <label key={day}>
                                    <input
                                        type="checkbox"
                                        value={day}
                                        {...register("WeekDays")}
                                    />
                                    {day}
                                </label>
                            ))}
                        </div>
                    </div>
                    {/* <p>
                        Repeat on these weekdays,If set to weekly ,If no weekday
                        is selected,The lesson will be repeated once a week
                        starting from orignal lesson date{" "}
                    </p> */}
                    <div className={RepeatClasses["form-control-select"]}>
                        <label htmlFor="Selector">
                            Stop Selector <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <div className={RepeatClasses["radio-btns"]}>
                            <label htmlFor="html">
                                <input
                                    type="radio"
                                    id="html"
                                    name="Stop After"
                                    value="Stop On"
                                    {...register("Stop", {
                                        onChange: (e) => {
                                            setValue("StopAfter", "");
                                        },
                                    })}
                                />
                                Stop on
                            </label>
                            <label htmlFor="css">
                                <input
                                    type="radio"
                                    id="css"
                                    defaultChecked
                                    name="Stop After"
                                    value="Stop After"
                                    {...register("Stop")}
                                />
                                Stop after
                            </label>
                        </div>
                    </div>
                    <div className={RepeatClasses["form-control"]}>
                        <label htmlFor="Selector">
                            Stop {watchData.Stop === "Stop On" ? "on" : "after"}
                            <sup style={{ color: "red" }}>*</sup>
                        </label>
                        <input
                            type={
                                watchData.Stop === "Stop On" ? "date" : "input"
                            }
                            {...register("StopAfter", {
                                required: "This is required ",
                                validate: (value) =>
                                    watchData.Stop != "Stop On"
                                        ? value <= 100 ||
                                          "value must be less than or equal to 100"
                                        : true,
                            })}
                        />
                        {errors.StopAfter && (
                            <span className={RepeatClasses["error"]}>
                                {errors.StopAfter.message}
                            </span>
                        )}
                    </div>

                    {load ? (
                        <div
                            style={{
                                justifySelf: "center",
                                marginTop: "1rem",
                            }}>
                            {" "}
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        </div>
                    ) : (
                        <div className={RepeatClasses["Control-btns"]}>
                            <button
                                type="button"
                                onClick={() => setModal(false)}>
                                Cancel
                            </button>
                            <button type="submit">Save</button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
}

export default Repeat;
