import React, { useState, useEffect } from "react";
import notificationClasses from "./notifications.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
// import SkeletonLoad from "./TableLoad/load";
import useStore from "../../store";
import Skeleton from "react-loading-skeleton";
import { Error } from "../../Util/Toast";
import { useNavigate } from "react-router-dom";
import { setLocalStorageWithExpiration } from "../../Util/localData";
import Moment from "react-moment";
import { AiOutlineNotification } from "react-icons/ai";
function Tutor() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const { NotificationCount, setNotificationCount } = useStore(
        (item) => item
    );
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const getNotifications = () => {
        Services.Notifications(
            "GET",
            null,
            token,
            adminData?.Type,
            adminData?.AdminUserID
        )
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.ScheduleClassNotifications);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const Reset_NotificationCount = () => {
        Services.Reset_Notification_count("POST", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setNotificationCount(0);
                    setLocalStorageWithExpiration("Notification", 0);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        getNotifications();
        if (NotificationCount > 0) {
            Reset_NotificationCount();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Layout Active={"Notifications"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Notifications</title>
            </Helmet>
            <div className={notificationClasses["Container"]}>
                <div className={notificationClasses["control-btns"]}></div>
                <div className={notificationClasses["Tutor"]}>
                    <div className={notificationClasses["Notifications"]}>
                        <h3>Notifications</h3>
                        {loading ? (
                            <>
                                <p style={{ margin: "0.1rem" }}>
                                    <Skeleton height={"10px"} />
                                </p>
                                <p style={{ margin: "0.1rem" }}>
                                    <Skeleton height={"10px"} />
                                </p>
                                <p style={{ margin: "0.1rem" }}>
                                    <Skeleton height={"10px"} />
                                </p>
                            </>
                        ) : (
                            <>
                                {data.length > 0 ? (
                                    <ul>
                                        {data.map((item) => (
                                            <li
                                                className={
                                                    notificationClasses[
                                                        "notification_item"
                                                    ]
                                                }>
                                                <AiOutlineNotification
                                                    size={30}
                                                />
                                                <div
                                                    className={
                                                        notificationClasses[
                                                            "right"
                                                        ]
                                                    }>
                                                    <h5>
                                                        <b>{item.Title}</b>
                                                    </h5>
                                                    <p>{item.Message}</p>
                                                    <p>
                                                        <Moment fromNow>
                                                            {item.CreatedAt}
                                                        </Moment>
                                                    </p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <p>No New Notification</p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
