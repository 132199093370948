import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./generate.module.css";
import { useForm } from "react-hook-form";

import { Services } from "../../../../Services";
import { Error } from "../../../../Util/Toast";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
import ReactLoading from "react-loading";
Modal.setAppElement("#root");

const Filter = ({
    setShowPaymentModal,
    showPaymentModal,
    data,
    enrollCourseId,
    getAllCategories,
}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [load, setLoad] = useState(false);
    const [courses, setCourses] = useState([]);

    // CLOSE FORM
    const closeModal = () => {
        setShowPaymentModal(false);
        reset();
    };
    let [requiredData] = data.filter(
        (item) => item.EnrollCourseID == enrollCourseId
    );
    // FORM SUBMIT
    const onSubmit = (formdata) => {
        let body = JSON.stringify({
            EmailID: requiredData.EmailID,
            EnrollCourseID: requiredData.EnrollCourseID,
            CourseName: requiredData.CourseName,
            CourseID: requiredData.CourseID,
            PricePerClass: Number(formdata.PricePerClass),
            PaidForSessions: Number(formdata.PaidForSessions),
            MobileNumber: requiredData.MobileNumber,
            ParentName: requiredData.ParentName,
            PricePaid: Math.round(
                Number(formdata.PricePerClass) *
                    Number(formdata.PaidForSessions)
            ),
        });

        setLoad(true);
        Services.GenerateLink("POST", body, token)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    getAllCategories();
                    setLoad(false);
                    swal("Requested", response.Message, "success");
                    closeModal();
                } else if (response.Status === 0) {
                    Error(response.Message);
                    setLoad(false);
                }
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
                Error("something went wrong please try again");
            });
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Modal
            isOpen={showPaymentModal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Payment Link</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <input
                                placeholder="Price per class"
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                step={0.01}
                                {...register("PricePerClass", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.PricePerClass && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <input
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                placeholder="No of Sessions"
                                {...register("PaidForSessions", {
                                    required: "this is required",
                                    validate: (val) =>
                                        Number(val) > 150
                                            ? `session cannot be greater than 150`
                                            : true,
                                })}
                            />
                        </div>
                        {errors.PaidForSessions && (
                            <span className={Styles["error"]}>
                                {errors.PaidForSessions.message}
                            </span>
                        )}
                    </div>
                    {load ? (
                        <div
                            style={{
                                justifySelf: "center",
                                marginTop: "1rem",
                            }}>
                            {" "}
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        </div>
                    ) : (
                        <div className={Styles["buttons"]}>
                            <button type="submit" className={Styles["submit"]}>
                                Generate
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default Filter;
