import React from "react";
import Skeleton from "react-loading-skeleton";
import ProfileClasses from "../Profile/profile.module.css";
function SkeletonLoading() {
    return (
        <div className={ProfileClasses["Skeleton-Container"]}>
            <div className={ProfileClasses["skeleton-left"]}>
                <div className={ProfileClasses["contact"]}>
                    <Skeleton width={"100px"} height={"100px"} />
                    <Skeleton width={"100px"} height={"10px"} count={3} />
                </div>
                <div
                    className={ProfileClasses["content"]}
                    style={{ marginTop: "0.8rem" }}>
                    <Skeleton height={"10px"} count={4} />
                </div>
            </div>
            <div className={ProfileClasses["skeleton-rightside"]}>
                <Skeleton height={"10px"} count={3} />

                <div
                    style={{ marginTop: "3rem" }}
                    className={ProfileClasses["content"]}>
                    <Skeleton height={"10px"} count={4} />
                </div>
            </div>
        </div>
    );
}

export default SkeletonLoading;
