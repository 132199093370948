import React, { useState, useEffect } from "react";
import CourseClasses from "./lessonPlan.module.css";
import Layout from "../../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../../Services";
import SkeletonLoad from "./TableLoad/load";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { AiOutlineFilePdf } from "react-icons/ai";
import { warningSwal } from "../../../Util/Toast";
import { useSearchParams } from "react-router-dom";
import { BsFiletypePpt } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { AccessKeys } from "../../../Routes/config";
import { AccessControl } from "../../../Util/AccessControl";
import { MdDelete } from "react-icons/md";
import swal from "sweetalert";
function Tutor() {
    const Navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const GetCourses = () => {
        Services.get_Course_Lessons("GET", null, token, searchParams.get("id"))
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.LessonPlans);
                } else if (response.Status === 0) {
                    warningSwal(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        GetCourses();
        // eslint-disable-next-line
    }, []);
    const editHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        Navigate(
            `/courses/Lessonplan/Add?id=${searchParams.get("id")}&planid=${id}`
        );
    };
    const backButton = () => {
        Navigate(-1);
    };
    const DeletePlan = async (id) => {
        setLoading(true);
        try {
            const plan = await Services.Delete_Lesson_plan(
                "DELETE",
                null,
                token,
                id
            );
            if (plan.Status == 1) {
                GetCourses();
            } else {
                warningSwal("Warning", plan.Message);
                // Error(batchDetails.Message);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const DeleteHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        swal(`Are You sure you want to Delete Lesson Plan?`, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "No",
                catch: {
                    text: "Yes",
                    value: "Logout",
                    className: CourseClasses["Delete"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    // cancelClass_API();
                    DeletePlan(id);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Lesson Plan</title>
            </Helmet>
            <div className={CourseClasses["Container"]}>
                <div className={CourseClasses["control-btns"]}>
                    <button
                        onClick={backButton}
                        className={CourseClasses["back"]}>
                        Back
                    </button>
                    <button
                        className={CourseClasses["button"]}
                        onClick={() => {
                            localStorage.setItem("count", data.length);
                            Navigate(
                                `/courses/Lessonplan/Add?id=${searchParams.get(
                                    "id"
                                )}`
                            );
                        }}>
                        <BiPlus size={20} /> Add New Plan
                    </button>
                </div>
                <div className={CourseClasses["Tutor"]}>
                    <h3>Lesson Plan</h3>
                    {/* <h2>All</h2>{" "} */}
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={CourseClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Plan Number</th>
                                        <th>Topic</th>
                                        <th>Song URL </th>
                                        <th>Lesson PPT</th>
                                        <th>Story URL</th>
                                        <th>Homework URL</th>
                                        <th>Games URL</th>
                                        {AccessControl(AccessKeys.Courses)
                                            ?.edit === 1 && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr>
                                            <td>{item.PlanNumber}</td>
                                            <td>{item.Topic}</td>
                                            <td style={{ textAlign: "left" }}>
                                                <div
                                                    className={
                                                        CourseClasses["links"]
                                                    }>
                                                    {item.SongURLs.length > 0 &&
                                                        item.SongURLs.map(
                                                            (links) => (
                                                                <a
                                                                    href={
                                                                        links.ResourceUrl
                                                                    }
                                                                    target="_blank"
                                                                    rel="norefferer">
                                                                    {
                                                                        links.ResourceName
                                                                    }
                                                                </a>
                                                            )
                                                        )}
                                                </div>
                                            </td>

                                            <td>
                                                {" "}
                                                {item.OneDrivelink &&
                                                    item.OneDrivelink !=
                                                        "NA" && (
                                                        <a
                                                            href={
                                                                item.OneDrivelink
                                                            }
                                                            target="_blank"
                                                            rel="noreferrer">
                                                            <BsFiletypePpt
                                                                size={33}
                                                            />
                                                        </a>
                                                    )}
                                            </td>
                                            <td>
                                                {" "}
                                                <div
                                                    className={
                                                        CourseClasses["links"]
                                                    }>
                                                    {item.StoryURLs.length >
                                                        0 &&
                                                        item.StoryURLs.map(
                                                            (links) => (
                                                                <a
                                                                    href={
                                                                        links.ResourceUrl
                                                                    }
                                                                    target="_blank"
                                                                    rel="norefferer">
                                                                    {
                                                                        links.ResourceName
                                                                    }
                                                                </a>
                                                            )
                                                        )}
                                                </div>
                                            </td>
                                            <td>
                                                {" "}
                                                {item.HomeworkURL != "NA" && (
                                                    <a
                                                        href={item.HomeworkURL}
                                                        target="_blank"
                                                        rel="noreferrer">
                                                        {" "}
                                                        <AiOutlineFilePdf
                                                            size={35}
                                                        />
                                                    </a>
                                                )}
                                            </td>
                                            <td>
                                                {item.GamesURL.length > 0 &&
                                                    item.GamesURL.map(
                                                        (link) => (
                                                            <>
                                                                <a
                                                                    href={
                                                                        link.ResourceUrl
                                                                    }
                                                                    target="_blank"
                                                                    rel="norefferer">
                                                                    {
                                                                        link.ResourceName
                                                                    }{" "}
                                                                    <br />
                                                                </a>{" "}
                                                                <br />
                                                            </>
                                                        )
                                                    )}
                                            </td>
                                            {AccessControl(AccessKeys.Courses)
                                                ?.edit === 1 && (
                                                <td>
                                                    <div
                                                        className={
                                                            CourseClasses[
                                                                "Action-btns"
                                                            ]
                                                        }>
                                                        <button
                                                            onClick={
                                                                editHandler
                                                            }
                                                            id={
                                                                item.LessonPlanID
                                                            }>
                                                            <FaRegEdit />
                                                        </button>
                                                        <button
                                                            onClick={
                                                                DeleteHandler
                                                            }
                                                            id={
                                                                item.LessonPlanID
                                                            }>
                                                            <MdDelete
                                                                color="red"
                                                                size={22}
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Lesson Plan Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
