import React, { useState, useEffect } from "react";
import Layout from "../../../../Components/Layout";
import StudentClasses from "./student.module.css";
import { BiPlus } from "react-icons/bi";
import { Helmet } from "react-helmet";
import Skeleton from "../Students/TableLoad/load";
import { Services } from "../../../../Services";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import useStore from "../../../../store";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
function Students() {
    const Navigate = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchparams, setSearchParams] = useSearchParams();
    const [forcepage, setforcepage] = useState(0);
    const RecordCount = useStore((item) => item.NoOfRecords);
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    const [searchString, setSearchString] = useState("");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllStudents = (skip) => {
        let body = {
            toSkip: skip,
            SearchString: searchString,
            whether_Name_filter: 0,
        };
        if (searchString.length > 0) {
            body = { ...body, whether_Name_filter: 1 };
        }
        Services.GetStudents("POST", JSON.stringify(body), token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Students);
                    if (
                        response.Students.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            let Page = searchparams.get("page");

            setLoading(true);
            getAllStudents((Number(Page) - 1) * RecordCount);
            setforcepage(Number(searchparams.get("page")) - 1);
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [searchString]);
    // useEffect(() => {
    //     Services.GetStudents("POST", null, token)
    //         .then((response) => {
    //             console.log(response);
    //             setTimeout(() => {
    //                 setLoading(false);
    //             }, 200);
    //             if (response.Status === 1) {
    //                 setData(response.Students);
    //             } else if (response.Status === 0) {
    //                 toast.error(`${response.Message}`, {
    //                     position: "top-center",
    //                     autoClose: 1000,
    //                     hideProgressBar: true,
    //                     closeOnClick: true,
    //                     pauseOnHover: true,
    //                     draggable: true,
    //                     progress: undefined,
    //                 });
    //             }
    //         })
    //         .catch((err) => {
    //             // alert(err);
    //             alert("something went wrong please try again");
    //             console.log(err);
    //         });
    // }, []);
    const detailsNav = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        Navigate(`/Students/details?id=${id}`);
        localStorage.setItem("name", name);
    };
    const handlePageChange = (selectedObject) => {
        setSearchParams({ page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        getAllStudents(selectedObject.selected * RecordCount);
        setLoading(true);
    };
    const searchHandler = (e) => {
        setSearchParams({ page: 1 });
        setforcepage(0);
        setSearchString(e.target.value);
    };
    return (
        <Layout Active={"Students"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Students</title>
            </Helmet>
            <div className={StudentClasses["Container"]}>
                <div className={StudentClasses["control-btns"]}>
                    <button
                        className={StudentClasses["button"]}
                        onClick={() => {
                            Navigate("/student/add");
                        }}>
                        <BiPlus size={20} /> Create New Student
                    </button>
                </div>
                <div className={StudentClasses["Students"]}>
                    <h3>Students</h3>
                    {/* <select>
                        <option value="">Label Filter</option>
                    </select> */}
                    <div className={StudentClasses["search"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            value={searchString}
                            placeholder="Search by name "
                        />
                        <IoMdSearch
                            className={StudentClasses["search-icon"]}
                            size={26}
                        />
                    </div>
                    {loading ? (
                        <Skeleton count={3} />
                    ) : (
                        <div className={StudentClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Gender</th>
                                        <th>Parent Name</th>
                                        <th>Academic Year</th>
                                        <th>School Name</th>
                                        <th>Created </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((item) => (
                                            <tr
                                                onClick={detailsNav}
                                                id={item.StudentID}
                                                key={item.StudentID}
                                                name={`${item.FirstName} ${item.LastName}`}>
                                                <td>
                                                    {item.FirstName}{" "}
                                                    {item.LastName}
                                                </td>
                                                <td>{item.Gender}</td>
                                                <td>{item.ParentName}</td>
                                                <td>{item.AcademicYear}</td>
                                                <td>{item.SchoolName}</td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.CreatedAt}
                                                    </Moment>{" "}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>No Data Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
                {/* <Skeleton count={4} /> */}
            </div>
        </Layout>
    );
}

export default Students;
