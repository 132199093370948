import React from "react";
import eventClasses from "./events.module.css";
import { useNavigate } from "react-router-dom";
function Events({ event, closeTooltip }) {
    const navigate = useNavigate();
    const navHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        window.open(`/scheduledClasses/Details?scheduleID=${id}`, "_blank");
    };
    return (
        <div className={eventClasses["event-container"]} id="event-details">
            <h3>{event.BatchName}</h3>
            <div className={eventClasses["content"]}>
                <p>
                    Tutor : <span>{event.TutorName}</span>
                </p>
                <p>
                    BatchSize : <span>{event.BatchSize}</span>
                </p>
                <p>
                    CourseName : <span>{event.CourseName}</span>
                </p>
            </div>
            <div className={eventClasses["actions"]}>
                <button id={event.ScheduleClassID} onClick={navHandler}>
                    More Details
                </button>
                <button onClick={closeTooltip}>close</button>
            </div>
        </div>
    );
}

export default Events;
