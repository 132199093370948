import React, { useState, useRef, useEffect } from "react";
import NotificationClasses from "./topNav.module.css";
import Moment from "react-moment";
import { AiOutlineNotification } from "react-icons/ai";
import { Services } from "../../Services";
import Skeleton from "react-loading-skeleton";
import useStore from "../../store";
function Notifications({ setIsNotificationVisible }) {
    const NotificationRef = useRef(null);
    const { Notifications_store, setNotifications } = useStore((item) => item);
    const [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const handleClickOutside = (event) => {
        if (
            NotificationRef.current &&
            !NotificationRef.current.contains(event.target)
        ) {
            setIsNotificationVisible(false);
        }
    };

    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const getNotifications = () => {
        Services.Notifications(
            "GET",
            null,
            token,
            adminData?.Type,
            adminData?.AdminUserID
        )
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setNotifications({
                        expiry: new Date().getTime() + 2 * 60 * 1000,
                        data: response.ScheduleClassNotifications,
                    });
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        if (
            Notifications_store?.expiry &&
            Notifications_store?.expiry > new Date().getTime()
        ) {
            setLoading(false);
        } else {
            getNotifications();
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div
            ref={NotificationRef}
            className={NotificationClasses["notification_container"]}>
            {loading ? (
                <ul>
                    <li>
                        <Skeleton height={"10px"} width={"320px"} />
                    </li>
                    <li>
                        <Skeleton height={"10px"} width={"320px"} />
                    </li>
                    <li>
                        <Skeleton height={"10px"} width={"320px"} />
                    </li>
                </ul>
            ) : (
                <ul>
                    {Notifications_store.data.map((item) => (
                        <li
                            className={
                                NotificationClasses["notification_item"]
                            }>
                            <AiOutlineNotification />
                            <div className={NotificationClasses["right"]}>
                                <p>
                                    <b>{item.Title}</b>
                                </p>
                                <p>{item.Message}</p>
                                <p>
                                    <Moment fromNow>{item.CreatedAt}</Moment>
                                </p>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default Notifications;
