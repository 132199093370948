import React from "react";
import ReviewClasses from "./review.module.css";
import Skeleton from "./Skeleton";
function Review() {
    return (
        <div className={ReviewClasses["Container"]}>
            <div className={ReviewClasses["over-view"]}>
                <header>
                    <h3>Review</h3>
                    <div className={ReviewClasses["header-left"]}>
                        <div>
                            <h4> No of Ratings</h4>
                            <p>0</p>
                        </div>
                        <div>
                            <h4> Average Ratings</h4>
                            <p>No Reviews</p>
                        </div>
                    </div>
                    <div className={ReviewClasses["header-right"]}>
                        <div>
                            <h4> Reviewd Hours</h4>
                            <p>0.0 Hours</p>
                        </div>
                        <div>
                            <h4> Latest Reviews</h4>
                            <p>No Reviews</p>
                        </div>
                    </div>
                </header>
            </div>
            <div className={ReviewClasses["review"]}>
                <p>No reviews</p>
            </div>
        </div>
    );
}

export default Review;
