import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./filter.module.css";
import { useForm } from "react-hook-form";

import { Services } from "../../../Services";
import { Error } from "../../../Util/Toast";
import { useParams, useSearchParams } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import { AiOutlineClose } from "react-icons/ai";
import { PreferredDays, Preferred_Time } from "./helper";
Modal.setAppElement("#root");

const Filter = ({
    showFilter,
    setShowFilter,
    setFilterLoad,
    setData,
    getAllCategories,
    setFilterData,
    filterData,
    BatchStatus,
}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [courses, setCourses] = useState([]);
    const [params, setParams] = useSearchParams();
    const [Tutors, setTutors] = useState([]);
    const [selected, setSelected] = useState([]);
    const FetchDropDowns = async () => {
        try {
            const [response1, response2] = await Promise.all([
                Services.getCourses("GET", null, token),
                Services.GetTutors(
                    "POST",
                    JSON.stringify({ TutorStatus: 1 }),
                    token
                ),
            ]);
            let prevFilter = {};
            if (filterData.filter) {
                filterData.filterData.forEach((item) => {
                    if (item.key == "Days") {
                        setSelected(
                            String(item.value)
                                .split(",")
                                .map((item) => {
                                    return { label: item, value: item };
                                })
                        );
                    } else if (item.key == "fromLesson-toLesson") {
                        let value = String(item.value).split("-");
                        prevFilter = {
                            ...prevFilter,
                            FromPlanNumber_Batch: value[0],
                            ToPlanNumber_Batch: value[1],
                        };
                    } else if (item.key == "Kids Count") {
                        let value = String(item.value).split("-");
                        prevFilter = {
                            ...prevFilter,
                            FromCount: value[0],
                            ToCount: value[1],
                        };
                    } else {
                        prevFilter = {
                            ...prevFilter,
                            [item.key]: item.value,
                        };
                    }
                });
                reset(prevFilter);
            }
            setCourses(response1.Courses);
            setTutors(response2.Tutors);
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        FetchDropDowns();
    }, []);
    // CLOSE FORM
    const closeModal = () => {
        setShowFilter(false);
    };

    // FORM SUBMIT
    const onSubmit = (formdata) => {
        let body = {};
        let Filters = [];
        if (formdata.BatchType.length > 0) {
            body = {
                ...body,
                BatchType: formdata.BatchType,
                whether_Batch_filter: 1,
            };

            Filters.push({
                key: "BatchType",
                value: formdata.BatchType,
                option: formdata.BatchType,
                display: "Batch Type",
                whether_Batch_filter: 1,
            });
        }
        if (formdata.BatchName.length > 0) {
            body = {
                ...body,
                BatchName: formdata.BatchName,
                whether_BatchName_filter: 1,
            };

            Filters.push({
                key: "BatchName",
                value: formdata.BatchName,
                option: formdata.BatchName,
                display: "Batch Name",
                whether_BatchName_filter: 1,
            });
        }
        if (selected.length > 0) {
            body = {
                ...body,
                Days: selected.map((item) => item.value),
                whether_Days_filter: 1,
            };
            Filters.push({
                key: "Days",
                value: selected.map((item) => item.value).join(","),
                option: selected.map((item) => item.value).join(","),
                whether_Days_filter: 1,
                display: "Days",
            });
        }

        if (
            formdata.FromPlanNumber_Batch.length > 0 ||
            formdata.ToPlanNumber_Batch.length > 0
        ) {
            if (formdata.ToPlanNumber_Batch.length == 0) {
                alert("to Lesson Plan is required");
                return;
            }
            if (formdata.FromPlanNumber_Batch.length == 0) {
                alert("From Lesson Plan is required");
                return;
            }
            if (formdata.FromPlanNumber_Batch > formdata.ToPlanNumber_Batch) {
                alert("Invalid Lesson Range");
                return;
            }
            body = {
                ...body,
                FromPlanNumber_Batch: formdata.FromPlanNumber_Batch,
                ToPlanNumber_Batch: formdata.ToPlanNumber_Batch,
                whether_PlanNumber_filter: 1,
            };
            Filters.push({
                key: "fromLesson-toLesson",
                display: "Plan Number",
                value: `${formdata.FromPlanNumber_Batch}-${formdata.ToPlanNumber_Batch}`,
                option: `${formdata.FromPlanNumber_Batch}-${formdata.ToPlanNumber_Batch}`,
                whether_PlanNumber_filter: 1,
            });
        }
        if (formdata.FromCount.length > 0 || formdata.ToCount.length > 0) {
            if (formdata.ToCount.length == 0) {
                alert("Student Count is required");
                return;
            }
            if (formdata.FromCount.length == 0) {
                alert("Student Count is required");
                return;
            }
            if (formdata.FromCount > formdata.ToCount) {
                alert("Invalid  Range");
                return;
            }
            body = {
                ...body,
                FromCount: formdata.FromCount,
                ToCount: formdata.ToCount,
                whether_StudentsCount_filter: 1,
            };
            Filters.push({
                key: "Kids Count",
                display: "Kids Count",
                value: `${formdata.FromCount}-${formdata.ToCount}`,
                option: `${formdata.FromCount}-${formdata.ToCount}`,
                whether_StudentsCount_filter: 1,
            });
        }

        if (formdata.CourseID.length > 0) {
            body = {
                ...body,
                CourseID: formdata.CourseID,
                whether_Course_filter: 1,
            };
            Filters.push({
                key: "CourseID",
                value: formdata.CourseID,
                whether_Course_filter: 1,
                display: "Course",
                option: courses.filter(
                    (item) => item.CourseID == formdata.CourseID
                )[0].CourseName,
            });
        }
        if (formdata.TutorID.length > 0) {
            body = {
                ...body,
                TutorID: formdata.TutorID,
                whether_Tutor_filter: 1,
            };
            let tutor = Tutors.filter(
                (item) => item.TutorID == formdata.TutorID
            )[0];
            Filters.push({
                key: "TutorID",
                value: formdata.TutorID,
                whether_Tutor_filter: 1,
                display: "Tutor",
                option: `${tutor.FirstName} ${
                    tutor.LastName && tutor.LastName !== "NA" && tutor.LastName
                }`,
            });
        }
        if (Filter.length > 0) {
            setFilterData((prev) => {
                return {
                    filter: true,
                    filterData: Filters,
                    filterBody: { ...body },
                };
            });
            setParams({ page: 1, tab: BatchStatus });
        }
        closeModal();
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Modal
            isOpen={showFilter}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Filters</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("CourseID", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Course Name</option>
                                {courses.map((item) => (
                                    <option value={item.CourseID}>
                                        {item.CourseName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("BatchType", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Batch Type</option>
                                <option value="One-to-One">One-to-One</option>
                                <option value="Group">Group</option>
                            </select>
                        </div>
                    </div>

                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("TutorID", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Select Tutor</option>
                                {Tutors.map((item) => (
                                    <option value={item.TutorID}>
                                        {item.FirstName && item.FirstName}{" "}
                                        {item.LastName &&
                                            item.LastName !== "NA" &&
                                            item.LastName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div style={{ gridColumn: "1/-1" }}>
                        <div className={Styles["date-input-container"]}>
                            <MultiSelect
                                options={PreferredDays}
                                value={selected}
                                onChange={setSelected}
                                labelledBy="Days"
                            />
                        </div>
                    </div>
                    <div style={{ gridColumn: "1/-1" }}>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <input
                                    type="text"
                                    placeholder="search by Batch name"
                                    {...register("BatchName")}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={Styles["combiner"]}>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <input
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    placeholder="From Lesson Number"
                                    {...register("FromPlanNumber_Batch", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <input
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    placeholder="To Lesson number"
                                    {...register("ToPlanNumber_Batch", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={Styles["combiner"]}>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <input
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    placeholder="From Student Count"
                                    {...register("FromCount", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>{" "}
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <input
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    placeholder="To Student Count"
                                    {...register("ToCount", {
                                        required: false,
                                    })}
                                />
                            </div>
                        </div>
                    </div>

                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            Filter
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default Filter;
