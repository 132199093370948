import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../../../Components/Layout";
import Mainloader from "../../../../Components/loader/loader";
import AddClasses from "./add.module.css";
import { useForm, Controller } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "../../../../Services";
import { useNavigate } from "react-router-dom";
import { warningSwal, SuccessSwal } from "../../../../Util/Toast";
import { useParams } from "react-router-dom";
import { preferredDay_Group } from "./helper";

import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import { Country, State, City } from "country-state-city";
import ReactFlagsSelect from "react-flags-select";
import { PhoneNumberValidate } from "Components/validators";
function Index() {
    const Navigate = useNavigate();
    let Countries = Country.getAllCountries();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [courses, setCourses] = useState([]);
    const [mainload, setMainLoad] = useState(false);
    const [phoneNumber, setphoneNumber] = useState("");
    const [parentDetails, setParentDetails] = useState({});
    const [Students, setStudents] = useState([]);
    const [IsParentExist, setIsParentExist] = useState(0);
    const [selectedcountry, setSelectedCountry] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    const {
        register,
        handleSubmit,
        reset,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const ageCalculation = (birthdate) => {
        const birthDate = new Date(birthdate);
        const currentDate = new Date();

        const ageInMilliseconds = currentDate - birthDate;
        const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); // Taking into account leap years

        const years = Math.floor(ageInYears);
        const months = Math.floor((ageInYears - years) * 12);

        return `${years}.${months}`;
    };
    const FetchDropDowns = async () => {
        try {
            const [response1, response2] = await Promise.all([
                Services.getCourses("GET", null, token),
                Services.Student_Registration_Dropdown("POST", null, token),
            ]);
            setLoading(false);
            if (response2.Status == 1) {
                setStudents(response2.Students);
            }
            setCourses(response1.Courses);
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    // Checks if  mobile number already exists in DB
    const phoneNumberCheck = (e) => {
        setphoneNumber(e.target.value);
    };
    // Handles Debounce
    useEffect(() => {
        let DebounceTimer;
        if (phoneNumber.length > 0) {
            // let CountryCode = watchFormData.PrimaryCountryCode.split("-");
            DebounceTimer = setTimeout(() => {
                GetParentDetails(phoneNumber, `+${selectedcountry.PhoneCode}`);
            }, 400);
        } else {
            ClearPrefillData();
        }
        return () => {
            clearTimeout(DebounceTimer);
        };
    }, [phoneNumber]);

    // Get Parent By Mobile Number API Call
    async function GetParentDetails(MobileNumber, CountryCode) {
        let body = {
            CountryCode,
            MobileNumber,
        };
        try {
            const response = await Services.getParentDetailsByNumber(
                "POST",
                JSON.stringify(body),
                null
            );
            if (response.Status == 1) {
                if (response.Parent.length > 0) {
                    let parent = response.Parent[0];

                    setParentDetails(parent);
                    setValue("ParentLastName", parent.LastName);
                    setValue("ParentFirstName", parent.FirstName);
                    setValue("ParentName", parent.ParentName);
                    setValue("EmailID", parent.EmailID);
                    setValue("SecondaryMobileNo", parent.SecondaryMobileNo);
                    setValue(
                        "SecondaryMobNo_CountryCode",
                        parent.SecondaryMobNo_CountryCode.slice(1)
                    );
                    if (parent?.Students?.length > 0) {
                        setValue("StudentID", parent.Students[0].StudentID);
                        setValue("SchoolName", parent.Students[0].SchoolName);
                        setValue(
                            "StudentFirstName",
                            parent.Students[0].FirstName
                        );
                        setValue(
                            "StudentLastName",
                            parent.Students[0].LastName
                        );
                    }
                    setValue("Address", parent.Address);
                    setValue("city", parent.City);
                    setValue("State", parent.State);
                    setValue("Pincode", parent.Pincode);
                    setIsParentExist(1);
                } else {
                    ClearPrefillData();
                }
            } else {
                Error(response.Message);
                ClearPrefillData();
            }
        } catch (err) {
            console.log(err);
            Error("user Not found");
        }
    }
    useEffect(() => {
        FetchDropDowns();
    }, []);
    const phoneChangeHandler = (data) => {
        console.log(data);
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            console.log(selectedCountry);
            setSelectedCountry({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    // Clears prefill Form Data
    const ClearPrefillData = () => {
        setValue("ParentFirstName", "");
        setValue("ParentLastName", "");
        setValue("SecondaryMobileNo", "");
        setValue("SecondaryMobNo_CountryCode", "91");
        setValue("EmailID", "");
        setValue("SchoolName", "");
        setValue("Address", "");
        setValue("city", "");
        setValue("State", "");
        setValue("Pincode", "");
        setValue("StudentFirstName", "");
        setValue("StudentLastName", "");
        setIsParentExist(0);
        setParentDetails([]);
    };
    const NullCheck_Names = (value) => {
        if (!value) {
            return "This field is required";
        }

        // Check for alphabetic characters without numbers or special characters
        if (/^[a-zA-Z ]+$/.test(value)) {
            return true; // Validation passed
        } else {
            return "Only alphabetic characters are allowed";
        }
    };
    const onSubmit = (data) => {
        let [selectedCourse] = courses.filter(
            (item) => item.CourseID == data.CourseID
        );
        if (IsParentExist == 0) {
            warningSwal(
                "Parent Not Found",
                "Parent not registered for this Number"
            );
            return;
        }
        let selectedStudent =
            parentDetails?.Students?.length > 0
                ? parentDetails.Students.filter(
                      (item) => item.StudentID == data.StudentID
                  )
                : null;

        let body = {
            ...data,
            CountryCode: parentDetails.CountryCode,
            StudentLastName: selectedStudent[0].LastName,
            StudentFirstName: selectedStudent[0].FirstName,
            Country: parentDetails.Country,
            City: parentDetails.City,
            State: parentDetails.State,
            SecondaryMobileNo: parentDetails.SecondaryMobileNo,
            SecondaryMobNo_CountryCode:
                parentDetails.SecondaryMobNo_CountryCode,
            TotalSessions: selectedCourse.NoOfSessions,
            CourseName: selectedCourse.CourseName,
            Price: selectedCourse.One_One_Price,
            ParentID: parentDetails.ParentID,
            DOB:
                parentDetails?.Students?.length == 0
                    ? data?.DOB
                    : selectedStudent[0]?.DOB,
            StudentAge:
                parentDetails?.Students?.length == 0
                    ? ageCalculation(data.DOB)
                    : ageCalculation(selectedStudent[0]?.DOB),

            IsParentExist,

            IsStudentExist: parentDetails?.Students?.length > 0 ? 1 : 0,
            PreferredStartTimes: [data?.Time1, data?.Time2, data?.Time3],
        };
        delete body.Time1;
        delete body.Time2;
        delete body.Time3;

        setLoading(true);
        Services.Student_Registration_ADD(
            "POST",
            JSON.stringify({
                ...body,
            }),
            token
        )
            .then((response) => {
                if (response.Status === 1) {
                    SuccessSwal("Success", response.Message);
                    Navigate(-1);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const handleTimeChange = (type, event) => {
        if (type == "time1") {
            setValue("Time1", moment(event).format("HH:mm"));
            clearErrors("Time1");
        }
        if (type == "time2") {
            setValue("Time2", moment(event).format("HH:mm"));
            clearErrors("Time2");
        }
        if (type == "time3") {
            setValue("Time3", moment(event).format("HH:mm"));
            clearErrors("Time3");
        }
    };
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 2);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 12);
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    const StudentChangeHandler = (e) => {
        let id = e.target.value;
        if (id) {
            let [StudentData] = parentDetails?.Students?.filter(
                (item) => item.StudentID == id
            );
            setValue("SchoolName", StudentData.SchoolName);
            setValue("StudentFirstName", StudentData.StudentFirstName);
            setValue("StudentLastName", StudentData.StudentLastName);
        }
    };
    return (
        <Layout Active={"Enrolled-list"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Registration</title>
            </Helmet>
            <div className={AddClasses["Container"]}>
                <div className={AddClasses["wrapper"]}>
                    <h3>Add Registration</h3>
                    {mainload ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Contact <span>*</span>
                                </label>
                                <div className={AddClasses["select-code"]}>
                                    <input
                                        type="number"
                                        style={{ textIndent: "5rem" }}
                                        onWheel={blurHandler}
                                        onScroll={blurHandler}
                                        {...register("MobileNumber", {
                                            required: "this field is required",
                                            onChange: (e) => {
                                                phoneNumberCheck(e);
                                            },
                                            validate: PhoneNumberValidate,
                                        })}
                                    />
                                    <div>
                                        <ReactFlagsSelect
                                            selected={selectedcountry.Country}
                                            showSelectedLabel={false}
                                            onSelect={phoneChangeHandler}
                                            fullWidth={false}
                                            // customLabels={customLabels}
                                            placeholder=""
                                            className={AddClasses["select-btn"]}
                                        />
                                    </div>
                                </div>
                                {errors.MobileNumber && (
                                    <span>{errors.MobileNumber.message}</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Email <span>*</span>
                                </label>
                                <input
                                    disabled={IsParentExist == 1}
                                    className={
                                        errors?.EmailID
                                            ? AddClasses["error"]
                                            : ""
                                    }
                                    type="email"
                                    placeholder="Your Email"
                                    {...register("EmailID", { required: true })}
                                />
                                {errors.EmailID && (
                                    <span className={AddClasses["error-msg"]}>
                                        This field is required
                                    </span>
                                )}
                            </div>

                            {parentDetails?.Students?.length > 0 ? (
                                <div className={AddClasses["form-control"]}>
                                    <label htmlFor="">
                                        {" "}
                                        Select Student<span>*</span>
                                    </label>
                                    <select
                                        className={
                                            errors?.StudentID
                                                ? AddClasses["error"]
                                                : ""
                                        }
                                        placeholder="Select Student"
                                        {...register("StudentID", {
                                            required: true,
                                            onChange: (e) =>
                                                StudentChangeHandler(e),
                                        })}>
                                        {parentDetails?.Students?.map(
                                            (item) => (
                                                <option value={item.StudentID}>
                                                    {item.FirstName}{" "}
                                                    {item.LastName}
                                                </option>
                                            )
                                        )}
                                    </select>
                                    {errors.StudentID && (
                                        <span
                                            className={AddClasses["error-msg"]}>
                                            This field is required
                                        </span>
                                    )}
                                </div>
                            ) : (
                                <>
                                    {" "}
                                    <div className={AddClasses["form-control"]}>
                                        <label htmlFor="">
                                            Student First Name<span>*</span>
                                        </label>
                                        <input
                                            className={
                                                errors?.StudentFirstName
                                                    ? AddClasses["error"]
                                                    : ""
                                            }
                                            type="text"
                                            placeholder="Child's First Name"
                                            {...register("StudentFirstName", {
                                                required:
                                                    " This field is required",
                                                validate: NullCheck_Names,
                                            })}
                                        />
                                        {errors.StudentFirstName && (
                                            <span
                                                className={
                                                    AddClasses["error-msg"]
                                                }>
                                                {
                                                    errors.StudentFirstName
                                                        .message
                                                }
                                            </span>
                                        )}
                                    </div>
                                    <div className={AddClasses["form-control"]}>
                                        <label htmlFor="">
                                            Student Last Name<span>*</span>
                                        </label>
                                        <input
                                            className={
                                                errors?.StudentLastName
                                                    ? AddClasses["error"]
                                                    : ""
                                            }
                                            type="text"
                                            placeholder="Child's Last Name"
                                            {...register("StudentLastName", {
                                                required:
                                                    " This field is required",
                                                validate: NullCheck_Names,
                                            })}
                                        />
                                        {errors.StudentLastName && (
                                            <span
                                                className={
                                                    AddClasses["error-msg"]
                                                }>
                                                {errors.StudentLastName.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className={AddClasses["form-control"]}>
                                        <label htmlFor="name">
                                            Name of the School <span>*</span>
                                        </label>
                                        <input
                                            disabled={
                                                IsParentExist == 1 &&
                                                parentDetails?.Students
                                                    ?.length > 0
                                            }
                                            className={
                                                errors?.SchoolName
                                                    ? AddClasses["error"]
                                                    : ""
                                            }
                                            type="text"
                                            placeholder="Name of the school"
                                            {...register("SchoolName", {
                                                required:
                                                    "this is a required field",
                                                validate: NullCheck_Names,
                                            })}
                                        />
                                        {errors.SchoolName && (
                                            <p
                                                className={
                                                    AddClasses["error-msg"]
                                                }>
                                                {errors?.SchoolName.message}
                                            </p>
                                        )}
                                    </div>
                                    <div className={AddClasses["form-control"]}>
                                        <label htmlFor="">
                                            DOB<span>*</span>
                                        </label>
                                        <input
                                            className={
                                                errors?.DOB
                                                    ? AddClasses["error"]
                                                    : ""
                                            }
                                            type={"date"}
                                            placeholder="Child's Birth Date"
                                            {...register("DOB", {
                                                required: true,
                                            })}
                                            min={
                                                (
                                                    new Date().getFullYear() -
                                                    12
                                                ).toString() + "-01-01"
                                            }
                                            max={
                                                (
                                                    new Date().getFullYear() - 2
                                                ).toString() + "-12-31"
                                            }
                                        />
                                        {errors.DOB && (
                                            <span
                                                className={
                                                    AddClasses["error-msg"]
                                                }>
                                                This field is required
                                            </span>
                                        )}
                                    </div>
                                </>
                            )}

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Batch Size <span>*</span>
                                </label>

                                <select
                                    defaultValue={""}
                                    {...register("BatchType", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Batch Size
                                    </option>

                                    <option value="One-to-One">
                                        One-to-One
                                    </option>
                                    <option value={"Group"}>Group</option>
                                </select>
                                {errors.BatchType && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Last Name">
                                    Course <span>*</span>
                                </label>
                                <select
                                    defaultValue={""}
                                    {...register("CourseID", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Course
                                    </option>
                                    {courses?.map((item) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>
                                {errors.CourseID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="">
                                    preferred Time 1 <span>*</span>
                                </label>
                                <Controller
                                    name="Time1"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TimePicker
                                                className={
                                                    errors?.Time1
                                                        ? AddClasses["error"]
                                                        : ""
                                                }
                                                defaultOpenValue={moment()}
                                                minuteStep={5}
                                                allowEmpty={false}
                                                showSecond={false}
                                                placeholder="preferred  Time 1"
                                                // placement={"topRight"}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    handleTimeChange(
                                                        "time1",
                                                        date
                                                    );
                                                }}
                                                format="h:mm a"
                                                use12Hours
                                            />
                                            {errors.Time1 && (
                                                <p
                                                    className={
                                                        AddClasses["error-msg"]
                                                    }>
                                                    this field s required
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="">
                                    preferred Time 2 <span>*</span>
                                </label>
                                <Controller
                                    name="Time2"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TimePicker
                                                className={
                                                    errors?.Time2
                                                        ? AddClasses["error"]
                                                        : ""
                                                }
                                                defaultOpenValue={moment()}
                                                minuteStep={5}
                                                allowEmpty={false}
                                                showSecond={false}
                                                placeholder="preferred  Time 2"
                                                // placement={"topRight"}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    handleTimeChange(
                                                        "time2",
                                                        date
                                                    );
                                                }}
                                                format="h:mm a"
                                                use12Hours
                                            />
                                            {errors.Time2 && (
                                                <p
                                                    className={
                                                        AddClasses["error-msg"]
                                                    }>
                                                    this field s required
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="">
                                    preferred Time 3 <span>*</span>
                                </label>
                                <Controller
                                    name="Time3"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <TimePicker
                                                className={
                                                    errors?.Time3
                                                        ? AddClasses["error"]
                                                        : ""
                                                }
                                                defaultOpenValue={moment()}
                                                minuteStep={5}
                                                allowEmpty={false}
                                                showSecond={false}
                                                placeholder="preferred  Time 3"
                                                // placement={"topRight"}
                                                onChange={(date) => {
                                                    field.onChange(date);
                                                    handleTimeChange(
                                                        "time3",
                                                        date
                                                    );
                                                }}
                                                format="h:mm a"
                                                use12Hours
                                            />
                                            {errors.Time3 && (
                                                <p
                                                    className={
                                                        AddClasses["error-msg"]
                                                    }>
                                                    this field s required
                                                </p>
                                            )}
                                        </>
                                    )}
                                />
                            </div>

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Preferred Days <span>*</span>
                                </label>

                                <select
                                    defaultValue={""}
                                    {...register("PreferredDays", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Preferred Days
                                    </option>
                                    {preferredDay_Group.map((item) => (
                                        <option value={item.label}>
                                            {item.value}
                                        </option>
                                    ))}
                                </select>
                                {errors.PreferredDays && (
                                    <span>This field is required</span>
                                )}
                            </div>

                            {/* <div className={AddClasses["form-control"]}>
                                <label htmlFor="MobileNumber">EmailID</label>
                                <input
                                    {...register("EmailID", {
                                        required: false,
                                    })}
                                />
                                {errors.EmailID && (
                                    <span>This field is required</span>
                                )}
                            </div> */}

                            <button disabled={loading}>
                                {" "}
                                {loading ? (
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
