import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../../../Components/Layout";
import Mainloader from "../../../../Components/loader/loader";
import AddClasses from "./add.module.css";
import { useForm, Controller } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "../../../../Services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { warningSwal, SuccessSwal } from "../../../../Util/Toast";
import { NullCheck, ValidateInput } from "../../../../Components/validators";
import { useParams } from "react-router-dom";
import { preferredDay_Group, Preferred_Time, customStyles } from "./helper";
import Select from "react-select";
function Index() {
    const Navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [mainload, setMainLoad] = useState(false);
    const [Tutors, setTutors] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const FetchDropDowns = async () => {
        try {
            const [response1, response2] = await Promise.all([
                Services.getCourses("GET", null, token),
                Services.GetTutors(
                    "POST",
                    JSON.stringify({ TutorStatus: 1 }),
                    token
                ),
            ]);
            if (response2.Status == 1) {
                setTutors(response2.Tutors);
            }
            const enrolled = JSON.parse(sessionStorage.getItem("enrolled"));
            setCourses(response1.Courses);
            reset({
                ...enrolled,
                PreferredStartTimes: enrolled.PreferredStartTimes.map(
                    (item) => ({
                        label: item,
                        value: item,
                    })
                ),
            });
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        FetchDropDowns();
    }, []);
    const onSubmit = (data) => {
        let dat = { ...data };
        delete dat.PaymentStatus;
        Services.updateEnrolledCourse(
            "PUT",
            JSON.stringify({
                ...dat,
                SessionsPurchased: data.SessionsPurchased,
                PreferredStartTimes: data.PreferredStartTimes.map(
                    (item) => item.value
                ),
            }),
            token,
            id
        )
            .then((response) => {
                if (response.Status === 1) {
                    SuccessSwal("Success", response.Message);
                    Navigate(-1);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Layout Active={"Enrolled-list"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Edit Enrolled-list</title>
            </Helmet>
            <div className={AddClasses["Container"]}>
                <div className={AddClasses["wrapper"]}>
                    <h3>Edit enrolled List</h3>
                    {mainload ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="First Name">Parent Name</label>
                                <input
                                    disabled
                                    {...register("ParentName", {
                                        required: false,
                                    })}
                                />
                                {errors.ParentName && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Last Name">Student Name</label>
                                <input
                                    disabled
                                    {...register("StudentName", {
                                        required: false,
                                    })}
                                />
                                {errors.StudentName && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="MobileNumber">
                                    Mobile Number
                                </label>
                                <input
                                    {...register("MobileNumber", {
                                        required: false,
                                    })}
                                />
                                {errors.MobileNumber && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Demo Taken By">
                                    Demo Taken By
                                </label>
                                <select
                                    {...register("DemoTakenBy", {
                                        required: false,
                                    })}>
                                    <option value={0}>Demo Taken By</option>
                                    {Tutors?.map((item) => (
                                        <option value={item.TutorID}>
                                            {item.TutorName}
                                        </option>
                                    ))}
                                </select>
                                {errors.DemoTakenBy && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Batch Size{" "}
                                </label>

                                <select
                                    defaultValue={""}
                                    {...register("BatchSize", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Select Batch Size
                                    </option>

                                    <option value="One-to-One">
                                        One-to-One
                                    </option>
                                    <option value={"Group"}>Group</option>
                                </select>
                                {errors.BatchSize && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div
                                className={AddClasses["form-control"]}
                                style={{ gridColumn: "1/-1" }}>
                                <label htmlFor="ContactNumber">
                                    Preferred Times
                                </label>

                                <Controller
                                    name="PreferredStartTimes"
                                    control={control}
                                    defaultValue={""}
                                    rules={{
                                        required: "This field is required",
                                    }}
                                    render={({ field }) => (
                                        <>
                                            <Select
                                                {...field}
                                                options={Preferred_Time}
                                                styles={customStyles}
                                                isSearchable={false}
                                                placeholder="Preferred Times as per IST times "
                                                isMulti
                                                closeMenuOnSelect={false}
                                                className={AddClasses["select"]}
                                                classNames={{
                                                    control: (state) =>
                                                        AddClasses["control"],
                                                    indicatorSeparator: () =>
                                                        AddClasses["indicator"],
                                                    valueContainer: () =>
                                                        AddClasses[
                                                            "valueContainer"
                                                        ],
                                                    input: () =>
                                                        AddClasses["input"],
                                                    indicatorsContainer: () =>
                                                        AddClasses[
                                                            "indicationContainer"
                                                        ],
                                                    singleValue: () =>
                                                        AddClasses[
                                                            "singleValue"
                                                        ],
                                                    option: () =>
                                                        AddClasses["option"],
                                                }}
                                            />
                                            {errors.PreferredStartTimes && (
                                                <span
                                                    className={
                                                        AddClasses["error-msg"]
                                                    }>
                                                    Please select min 3 time
                                                    slots
                                                </span>
                                            )}
                                        </>
                                    )}
                                />
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Preferred Days
                                </label>

                                <select
                                    defaultValue={""}
                                    {...register("PreferredDays", {
                                        required: true,
                                    })}>
                                    <option value="" disabled>
                                        Preferred Days
                                    </option>
                                    {preferredDay_Group.map((item) => (
                                        <option value={item.label}>
                                            {item.value}
                                        </option>
                                    ))}
                                </select>
                                {errors.PreferredDays && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="Last Name">Course</label>
                                <select
                                    {...register("CourseID", {
                                        required: true,
                                    })}>
                                    {courses?.map((item) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>
                                {errors.CourseID && (
                                    <span>This field is required</span>
                                )}
                            </div>

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="MobileNumber">EmailID</label>
                                <input
                                    {...register("EmailID", {
                                        required: false,
                                    })}
                                />
                                {errors.EmailID && (
                                    <span>This field is required</span>
                                )}
                            </div>

                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Sessions Purchased
                                </label>
                                <input
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    {...register("SessionsPurchased", {
                                        required: "this field is required",
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.SessionsPurchased && (
                                    <span>
                                        {errors?.SessionsPurchased.message}
                                    </span>
                                )}
                            </div>
                            <div className={AddClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Price per class
                                </label>
                                <input
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    step={0.01}
                                    {...register("PricePerClass", {
                                        required: "this field is required",
                                        validate: (val) =>
                                            ValidateInput(
                                                val,
                                                "Price per class cannot be negative"
                                            ),
                                    })}
                                />
                                {errors.PricePerClass && (
                                    <span>{errors?.PricePerClass.message}</span>
                                )}
                            </div>

                            <button disabled={loading}>
                                {" "}
                                {loading ? (
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
