import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./generate.module.css";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Services } from "../../../../../Services";
import { Error } from "../../../../../Util/Toast";
import { AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import ReactLoading from "react-loading";
import { Success_Dark } from "../../../../../Util/Toast";
Modal.setAppElement("#root");

const Filter = ({ setShowPaymentModal, showPaymentModal, Transactions }) => {
    const [searchparams] = useSearchParams();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [load, setLoad] = useState(false);

    // CLOSE FORM
    const closeModal = () => {
        setShowPaymentModal(false);
        reset();
    };
    // FORM SUBMIT
    const onSubmit = (formdata) => {
        let body = JSON.stringify({
            ...formdata,
            ParentID: searchparams.get("Id"),
        });

        setLoad(true);
        Services.Generate_manual_PaymentLink("POST", body, token)
            .then((response) => {
                if (response.Status === 1) {
                    Transactions();
                    setLoad(false);
                    Swal.fire({
                        title: "Payment Link Generated ",
                        showDenyButton: true,
                        icon: "warning",
                        showCancelButton: false,
                        confirmButtonText: "Copy Link",
                        denyButtonText: "Close",
                        customClass: {
                            actions: Styles["popup-actions"],
                            cancelButton: "order-1 right-gap",
                            confirmButton: Styles["logout-popup"],
                            denyButton: "order-3",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Copy the text inside the text field

                            navigator.clipboard.writeText(response.PaymentLink);
                            Success_Dark("linked Copied to clipboard");
                        }
                    });
                    closeModal();
                } else if (response.Status === 0) {
                    Error(response.Message);
                    setLoad(false);
                }
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
                Error("something went wrong please try again");
            });
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Modal
            isOpen={showPaymentModal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Payment Link</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <input
                                placeholder="Amount"
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                step={0.01}
                                {...register("Amount", {
                                    required: true,
                                })}
                            />
                        </div>
                        {errors.Amount && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>

                    {load ? (
                        <div
                            style={{
                                justifySelf: "center",
                                marginTop: "1rem",
                            }}>
                            {" "}
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        </div>
                    ) : (
                        <div className={Styles["buttons"]}>
                            <button type="submit" className={Styles["submit"]}>
                                Generate
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default Filter;
