import React from "react";
import ProfileClasses from "../Profile/profile.module.css";
import Skeleton from "react-loading-skeleton";
function SkeletonLoad() {
    return (
        <div
            className={ProfileClasses["Skeleton-Container"]}
            style={{ gap: "1rem" }}>
            <div
                className={ProfileClasses["content"]}
                style={{ marginTop: "0.8rem" }}>
                <Skeleton height={"10px"} count={4} />
            </div>
            <div
                className={ProfileClasses["content"]}
                style={{ marginTop: "0.8rem" }}>
                <Skeleton height={"10px"} count={4} />
            </div>
        </div>
    );
}

export default SkeletonLoad;
