import React from "react";
import DashboardClasses from "../dashboard.module.css";
import Summary from "./Summary";
import { GrCompliance } from "react-icons/gr";
import { MdOutlineInsertComment } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";

function Complains() {
    return (
        <div className={DashboardClasses["sales"]}>
            <header>
                <h3>Complaints Metrics</h3>
            </header>
            <div className={DashboardClasses["Summary_container"]}>
                <Summary
                    Count={`2000`}
                    icon={<MdOutlineInsertComment size={28} />}
                    heading={"Total Complaints"}
                />
                <Summary
                    Count={`2000`}
                    icon={<MdOutlinePendingActions size={28} />}
                    heading={"pending Complaints"}
                />
                <Summary
                    Count={`245`}
                    icon={<GrCompliance size={28} />}
                    heading={"Resolved Complaints"}
                />
            </div>
        </div>
    );
}

export default Complains;
