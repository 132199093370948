import React, { useRef, useState } from "react";
import Modal from "react-modal";
import Styles from "./index.module.css";
import { Services } from "Services";
import ReactLoading from "react-loading";
import { warningSwal, SuccessSwal } from "Util/Toast";
Modal.setAppElement("#root");
function ComplaintModal({ modal, setModal, getDetails, getAllCategories }) {
    const inputRef = useRef();
    const [loading, setLoading] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const closeModal = () => {
        setModal(false);
    };
    const resolveHanlder = (status) => {
        setLoading(true);
        Services.ReverseTutorPenalty(
            "POST",
            JSON.stringify({
                TutorEarningID: getDetails.penaltyDetails.TutorEarningID,
                IsPenalityChallenged: status,
            }),
            token,
            getDetails.ComplaintID
        )
            .then((response) => {
                if (response.Status === 1) {
                    closeModal();
                    let body = { TutorID: getDetails.TutorID, Type: "ALL" };
                    if (getDetails.FromDate || getDetails.ToDate) {
                        body = {
                            ...body,
                            FromDate: getDetails.FromDate,
                            Type: "DATE",
                            ToDate: getDetails.ToDate,
                        };
                    }
                    if (getDetails.payoutDetails) {
                        getDetails.get_Earnings({
                            PayoutID: getDetails.payoutId,
                        });
                    } else {
                        getDetails.get_Earnings(body);
                    }
                    SuccessSwal("Success", response.Message);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["modal_Container"]}>
                <header>
                    <h3>Challenge request</h3>
                </header>
                <div className={Styles["Complaint_Details"]}>
                    <div>
                        <p>{getDetails.penaltyDetails?.Reason}</p>
                        {/* <h3>Comments :</h3>
                        <p>{getDetails.penaltyDetails?.Reason}</p> */}
                    </div>
                </div>
                {loading ? (
                    <div className={Styles["loader"]}>
                        <ReactLoading
                            color="green"
                            type="spokes"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    <div className={Styles["control_Buttons"]}>
                        {getDetails?.Status != 1 && (
                            <button
                                className={Styles["resolve"]}
                                onClick={() => {
                                    resolveHanlder(1);
                                }}>
                                Approve
                            </button>
                        )}
                        <button
                            className={Styles["close"]}
                            onClick={() => {
                                resolveHanlder(3);
                            }}>
                            Reject
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ComplaintModal;
