import React, { useState } from "react";
import Modal from "react-modal";
import Styles from "./add.module.css";
import { config } from "../../../s3config";
import { FaFolder } from "react-icons/fa";
import { FileUploader } from "react-drag-drop-files";
import S3FileUpload from "react-s3";
import ReactLoading from "react-loading";
import Mainloader from "../../../Components/loader/loader";
import { IoMdClose } from "react-icons/io";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Add({ shwoModal, setModal }) {
    const [uploadProgress, setUploadProgress] = useState(0);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [file, setFile] = useState("");
    const [load, setLoad] = useState(false);
    const [Mainload, setMainLoad] = useState(false);
    function filehandleChange(files) {
        setLoad(true);
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/webp"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );
            const options = {
                ...config,
                dirName: "Courses",
                onProgress: (progress) => {
                    setUploadProgress(progress);
                },
            };

            S3FileUpload.uploadFile(newfile, options)
                .then((res) => {
                    setLoad(false);
                    setFile(`${timestamp}.${String(files.type).split("/")[1]}`);
                })
                .catch((err) => {});
        } else {
            alert(`${files.name} is neither PNG orjpeg`);
            return false;
        }
        return true;
    }
    const fileuploadDesign = (
        <div className={Styles["add-item"]}>
            <FaFolder className={Styles["upload-icon"]} />
            <h5>Drag & Drop Your Files here</h5>
        </div>
    );
    const submitHandler = () => {
        setMainLoad(true);
    };
    const closehandler = () => {
        setModal(false);
        setLoad(false);
        setFile("");
    };
    return (
        <div>
            {Mainload && <Mainloader />}

            <Modal
                isOpen={shwoModal}
                contentLabel="Modal"
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <header>
                        <h3>Upload Csv File</h3>
                        <button onClick={closehandler}>
                            <IoMdClose />
                        </button>
                    </header>
                    <div className={Styles["file-upload"]}>
                        <div className={Styles["file-upload-wrapper"]}>
                            <FileUploader
                                classes={FileUploader["upload-image"]}
                                multiple={false}
                                handleChange={filehandleChange}
                                name="file"
                                children={fileuploadDesign}
                                // types={fileTypes}
                            />
                        </div>
                    </div>
                    <div className={Styles["actionHandler"]}>
                        {load ? (
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        ) : (
                            <button onClick={submitHandler}>Upload</button>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default Add;
