import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import MainLoader from "../../../Components/loader/loader";
import AddQuestion from "./AddQuestion";
import Swal from "sweetalert";
import { CheckIsObjectEmpty, compareObjects } from "Util/Util";
import { NullCheck } from "../../../Components/validators";
import { Courseschema } from "./CourseValidators";
import { yupResolver } from "@hookform/resolvers/yup";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [categories, setCategories] = useState([]);
    const [mainLoad, setMainLoader] = useState(true);
    const [courseDetails, setCourseDetails] = useState({});
    const [questions, setquestions] = useState([{ Question: "", Answer: "" }]);
    const [base64, setbase64] = useState("");
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(Courseschema),
    });
    const formData = watch();
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    function getAllCategories() {
        Services.getCategories("GET", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    if (!params.get("id")) {
                        setMainLoader(false);
                    }
                    setCategories(
                        response?.Categories?.filter(
                            (item) => item.Status === 1
                        )
                    );
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }
    useEffect(() => {
        getAllCategories();

        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        if (params.get("id") && categories.length > 0) {
            setEdit(true);
            Services.getcourseDetailById("GET", null, token, params.get("id"))
                .then((response) => {
                    if (response.Status === 1) {
                        sessionStorage.setItem(
                            "Alldata",
                            JSON.stringify(response.Courses.Question_Answers)
                        );
                        setCourseDetails(response.Courses);
                        reset({
                            Status: response.Courses.Status,
                            Duration: response.Courses.Duration,
                            CourseName: response.Courses.CourseName,
                            CourseID: response.Courses.CourseID,
                            CategoryName: response.Courses.CategoryName,
                            CategoryID: response.Courses.CategoryID,
                            MinStudents: response.Courses.MinStudents,
                            MaxStudents: response.Courses.MaxStudents,
                            IsGroupAvailable: response.Courses.IsGroupAvailable,

                            Batch: response.Courses.Batch,
                            ToAge: response.Courses.ToAge,
                            FromAge: response.Courses.FromAge,
                            Overview: response.Courses.Overview,
                            MinSessionsToPurchase:
                                response.Courses.MinSessionsToPurchase,
                            NoOfSessions: response.Courses.NoOfSessions,
                            Language: response.Courses.Language,
                            WritingActivity: response.Courses.WritingActivity,
                            Group_Price: response.Courses.Group_Price,
                            Group_Duration: response.Courses.Group_Duration,
                            One_One_Price: response.Courses.One_One_Price,
                            One_One_Duration: response.Courses.One_One_Duration,
                            One_One_Available:
                                response.Courses.One_One_Available,
                            ClassPerWeek: response.Courses.ClassPerWeek,
                            What_You_Learn: response.Courses.What_You_Learn,
                        });
                        setbase64(response.Courses.CourseImage);
                        setquestions(response.Courses.Question_Answers);
                        setTimeout(() => {
                            setMainLoader(false);
                        }, 300);
                    } else if (response.Status === 0) {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }

        // eslint-disable-next-line
    }, [categories]);
    function filehandleChange(files) {
        console.log(files.type);
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/webp"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "Courses",
            })
                .then((res) => {
                    setbase64(res.location);
                    setValue(
                        "CourseImage",
                        `${timestamp}.${String(files.type).split("/")[1]}`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG orjpeg`);
            return false;
        }
        return true;
    }
    const dataCheck = (data, Message) => {
        // eslint-disable-next-line
        let returnData = data.some((item) => {
            if (item.Question.length === 0 || item.Answer.length === 0) {
                Swal({
                    icon: "error",
                    title: Message,
                });
                return true;
            }
        });

        return returnData;
    };
    const compareDataObjects = (obj1, obj2) => {
        const result = [];
        console.log(obj1, obj2);
        for (const obj1Item of obj1) {
            if (!obj1Item.QA_ID) {
                // Include object from obj1 without comparison if QA_ID is missing
                result.push(obj1Item);
            } else {
                const matchingObj2 = obj2.find(
                    (obj2Item) => obj2Item.QA_ID === obj1Item.QA_ID
                );

                if (
                    !matchingObj2 ||
                    matchingObj2.Question !== obj1Item.Question ||
                    matchingObj2.Answer !== obj1Item.Answer
                ) {
                    // Include object from obj1 if there is no matching object in obj2 or questions/answers are different
                    result.push(obj1Item);
                }
            }
        }

        return result;
    };
    const onSubmit = (data) => {
        if (questions) {
            let error = dataCheck(questions, "Oops... QA cannot be empty");

            if (error) {
                return;
            }
        }
        if (edit) {
            console.log(data);
            let comparupdatequestions = compareDataObjects(
                questions,
                JSON.parse(sessionStorage.getItem("Alldata"))
            );
            let exisitingData = courseDetails;
            delete exisitingData.Question_Answers;
            let updatedData = compareObjects({ ...data }, exisitingData);
            console.log(updatedData);
            if (
                !CheckIsObjectEmpty(updatedData) ||
                !CheckIsObjectEmpty(comparupdatequestions)
            ) {
                setLoading(true);
                Services.updateCourses(
                    "PUT",
                    JSON.stringify({
                        ...updatedData,
                        Questions: comparupdatequestions,
                    }),
                    token,
                    params.get("id")
                )
                    .then((response) => {
                        if (response.Status === 1) {
                            Navigate(-1);
                            SuccessSwal("Course Updated", response.Message);
                        } else if (response.Status === 0) {
                            setLoading(false);
                            warningSwal("Warning", response.Message);
                        }
                    })
                    .catch((err) => {
                        // alert(err);
                        setLoading(false);
                        alert("something went wrong please try again");
                        console.log(err);
                    });
            } else {
                warningSwal("Warning", "Nothing to update");
            }
        } else {
            setLoading(true);
            Services.allCourses(
                "POST",
                JSON.stringify({ ...data, Questions: questions }),
                token
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Course Added", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.jpeg ,*.png will be accepted)</p>
        </div>
    );
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Course</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Course" : "Update Course"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Course Name <span className="important">*</span>
                            </label>
                            <input
                                {...register("CourseName", {
                                    required: true,
                                })}
                            />
                            {errors.CourseName && (
                                <span>{errors.CourseName.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="category Name">
                                Category Name{" "}
                                <span className="important">*</span>
                            </label>
                            <select
                                defaultValue={""}
                                {...register("CategoryID", {
                                    required: true,
                                })}>
                                <option value="">Select Category</option>
                                {categories.map((item) => (
                                    <option value={item.CategoryID}>
                                        {item.CategoryName}
                                    </option>
                                ))}
                            </select>
                            {errors.CategoryID && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="Level">
                                Level <span className="important">*</span>
                            </label>
                            <select
                                defaultValue={""}
                                {...register("Level", {
                                    required: true,
                                })}>
                                <option value="Beginner">beginner</option>
                                <option value="Intermediate">
                                    intermediate
                                </option>
                                <option value="Advanced">Advanced</option>
                            </select>
                            {errors.Level && (
                                <span>This field is required</span>
                            )}
                        </div>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="FromAge">
                                From Age <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                step={0.1}
                                {...register("FromAge")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.FromAge && (
                                <span>{errors?.FromAge.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ToAge">
                                To Age <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                step={0.1}
                                {...register("ToAge")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.ToAge && (
                                <span>{errors.ToAge.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="BatchSize">
                                Batch <span className="important">*</span>
                            </label>
                            <input
                                {...register("Batch", {
                                    required: "This field is required",
                                    validate: {
                                        NullCheck,
                                    },
                                })}
                            />
                            {errors.Batch && (
                                <span>{errors.Batch?.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="MinStudents">
                                Min Students{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                {...register("MinStudents")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.MinStudents && (
                                <span>{errors.MinStudents?.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="MaxStudents">
                                Max Students{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                {...register("MaxStudents")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.MaxStudents && (
                                <span>{errors.MaxStudents?.message}</span>
                            )}
                        </div>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="ClassPerWeek">
                                Classes Per Week{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                {...register("ClassPerWeek")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.ClassPerWeek && (
                                <span>{errors.ClassPerWeek.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="One_One_Available">
                                One One Available{" "}
                                <span className="important">*</span>
                            </label>
                            <select
                                defaultValue={""}
                                {...register("One_One_Available", {
                                    required: true,
                                    validate: NullCheck,
                                })}>
                                <option value="1">Available</option>
                                <option value="0">Not Available</option>
                            </select>
                            {errors.One_One_Available && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="One_One_Available">
                                Group Available{" "}
                                <span className="important">*</span>
                            </label>
                            <select
                                defaultValue={""}
                                {...register("IsGroupAvailable", {
                                    required: true,
                                })}>
                                <option value="1">Available</option>
                                <option value="0">Not Available</option>
                            </select>
                            {errors.One_One_Available && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="One_One_Duration">
                                One-on-One Duration{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                {...register("One_One_Duration")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.One_One_Duration && (
                                <span>{errors.One_One_Duration?.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="One_One_Price">
                                One-on-One Price{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                {...register("One_One_Price")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                step={0.01}
                            />
                            {errors.One_One_Price && (
                                <span>{errors?.One_One_Price.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="Group_Duration">
                                Group Duration{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                {...register("Group_Duration")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.Group_Duration && (
                                <span>{errors.Group_Duration?.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="Group_Price">
                                Group Price <span className="important">*</span>
                            </label>
                            <input
                                type="number"
                                {...register("Group_Price")}
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                step={0.01}
                            />
                            {errors.Group_Price && (
                                <span>{errors.Group_Price.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="WritingActivity">
                                Writing Available{" "}
                                <span className="important">*</span>
                            </label>
                            <select
                                {...register("WritingActivity", {
                                    required: true,
                                })}>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            {errors.WritingActivity && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="Language">
                                Language <span className="important">*</span>
                            </label>
                            <input {...register("Language")} />
                            {errors.Language && (
                                <span>{errors?.Language?.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="NoOfSessions">
                                No Of Sessions{" "}
                                <span className="important">*</span>
                            </label>
                            <input
                                {...register("NoOfSessions")}
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                            />
                            {errors.NoOfSessions && (
                                <span>{errors.NoOfSessions?.message}</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="MinSessionsToPurchase">
                                Min Sessions To Purchase{" "}
                                <span className="important">*</span>
                            </label>
                            <input {...register("MinSessionsToPurchase")} />
                            {errors.MinSessionsToPurchase && (
                                <span>
                                    {errors?.MinSessionsToPurchase?.message}
                                </span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="SightWords">
                                Sight Words <span className="important">*</span>
                            </label>
                            <select
                                {...register("SightWords", {
                                    required: true,
                                })}>
                                <option value={"Yes"}>Yes</option>
                                <option value={"No"}>No</option>
                            </select>
                            {errors.SightWords && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Status <span className="important">*</span>
                            </label>
                            <select
                                {...register("Status", {
                                    required: true,
                                })}>
                                <option value="1">Active</option>
                                <option value="2">Inactive</option>
                            </select>
                            {errors.Status && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div
                            className={TutorClasses["form-control"]}
                            style={{ gridColumn: "1 / -1" }}>
                            <label htmlFor="Overview">
                                Overview <span className="important">*</span>
                            </label>
                            <textarea
                                rows="5"
                                {...register("Overview")}></textarea>
                            {errors.Overview && (
                                <span>{errors.Overview.message}</span>
                            )}
                        </div>
                        <div
                            className={TutorClasses["form-control"]}
                            style={{ gridColumn: "1 / -1" }}>
                            <label htmlFor="What_You_Learn">
                                What You Learn{" "}
                                <span className="important">*</span>
                            </label>
                            <textarea
                                rows="5"
                                {...register("What_You_Learn")}></textarea>
                            {errors.What_You_Learn && (
                                <span>{errors?.What_You_Learn?.message}</span>
                            )}
                        </div>
                        <AddQuestion
                            skills={questions}
                            setSkills={setquestions}
                            path={params.get("id")}
                        />
                        <div className={TutorClasses["file-upload-wrapper"]}>
                            <header>
                                <h3>Upload Image</h3>
                            </header>
                            <FileUploader
                                classes={TutorClasses["upload-image"]}
                                multiple={false}
                                handleChange={filehandleChange}
                                name="file"
                                children={fileuploadDesign}
                                // types={fileTypes}
                            />
                        </div>

                        {base64 && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                <div className={TutorClasses["image"]}>
                                    <img
                                        src={base64}
                                        alt="uploaded-data"
                                        onClick={() => {
                                            window.open(base64, "_blank");
                                        }}
                                    />
                                </div>
                            </div>
                        )}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
