import React, { useState, useEffect, useRef } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { warningSwal } from "../../../Util/Toast";
import ReactLoading from "react-loading";
import MainLoader from "../../../Components/loader/loader";
import { AiOutlineClose } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import PreferredDays from "./PreferredDays/PreferredDays";
import Swal from "sweetalert";
import moment from "moment";
import Select from "react-select";
import { compareObjects, CheckIsObjectEmpty } from "Util/Util";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddTutor() {
    const [params] = useSearchParams();
    const dropDownRef = useRef();
    const [loading, setLoading] = useState(false);
    const [lessonPlan, setLessonPlan] = useState([]);
    const Navigate = useNavigate();
    const [mainLoad, setMainLoader] = useState(true);
    const [courses, setCourses] = useState([]);

    const [searchTerm, setSearchTerm] = useState("");
    const [options, setOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [edit, setEdit] = useState(false);
    const [searchApi, setSearchAPI] = useState([]);
    const [days, setDays] = useState([
        { day: "", Time: roundToNearest5Minutes(moment()).format("HH:mm") },
    ]);
    const [tutors, setTutors] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchString, setTutorSearchString] = useState("");
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    let batchDetails = JSON.parse(localStorage.getItem("batchDetails"));
    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        control,
        formState: { errors },
    } = useForm();
    const formData = watch();
    function roundToNearest5Minutes(time) {
        const remainder = 5 - (time.minute() % 5);
        return moment(time).add(remainder, "minutes").startOf("minute");
    }
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        if (params.get("BatchId") && params.get("type") != "draft") {
            setEdit(true);
        }
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleOutsideClick = (event) => {
        if (
            dropDownRef.current &&
            !dropDownRef.current.contains(event.target)
        ) {
            // Clicked outside the dropdown, so close it
            setOptions([]); // Clear the options to close the dropdown
        }
    };

    const FetchDropDowns = async () => {
        try {
            const [response1] = await Promise.all([
                Services.getCourses("GET", null, token),
            ]);

            setCourses(response1.Courses?.filter((item) => item.Status === 1));
            if (params.get("BatchId")) {
                setLessonPlan([{ PlanNumber: batchDetails?.PlanNumber_Batch }]);
                setTimeout(() => {
                    setDays(batchDetails.Days);
                    // getLessonPlans(batchDetails?.CourseID);
                    reset({
                        TutorID: batchDetails?.TutorID,
                        CourseID: batchDetails?.CourseID,
                        BatchSize: batchDetails?.BatchSize,
                        Duration: batchDetails?.Duration,
                        StartDate: String(batchDetails.StartDate).slice(0, 10),
                        BatchName: batchDetails?.BatchName,
                        PlanNumber_Batch: batchDetails?.PlanNumber_Batch,
                        TutorInstructions: batchDetails?.TutorInstructions,
                    });
                    setTutors([
                        {
                            label: batchDetails?.TutorName,
                            value: batchDetails?.TutorID,
                        },
                    ]);
                    setSelectedTutor({
                        label: batchDetails?.TutorName,
                        value: batchDetails?.TutorID,
                    });
                    setMainLoader(false);
                }, 200);
            } else {
                setMainLoader(false);
            }
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        FetchDropDowns();
        // eslint-disable-next-line
    }, []);
    const handleInputChange = (event) => {
        if (!formData.CourseID) {
            alert("please Select Course");
            return;
        }

        if (!formData.BatchSize) {
            alert("please Select BatchSize");
            return;
        }

        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString?.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors?.filter(
                        (item) => item.TutorStatus == 1
                    )?.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                        Email: tutor.EmailID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const handleSearch = async () => {
        if (!formData.CourseID) {
            alert("please Select Course");
            return;
        }

        if (!formData.BatchSize) {
            alert("please Select BatchSize");
            return;
        }

        if (searchTerm?.length === 0) {
            alert("Enter valid Mobile number");
            return;
        }

        Services.searchParentByMobileNumber(
            "GET",
            null,
            token,
            searchTerm,
            formData.CourseID,
            formData.BatchSize
        )
            .then((response) => {
                if (response?.EnrolledStudents?.length === 0) {
                    alert("NO Student Found");
                    return;
                }

                const data = response?.EnrolledStudents?.map((item) => {
                    return { id: item.StudentID, label: item.StudentName };
                });
                setSearchAPI(response?.EnrolledStudents);
                setOptions(data);
            })
            .catch((error) => {
                console.error("Error fetching options:", error);
            });
    };

    const buttonHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        // eslint-disable-next-line
        if (
            selectedOptions.filter((item) => item.StudentID == id)?.length > 0
        ) {
            alert("student Already Added ");
            return;
        }
        setOptions((prev) => {
            // eslint-disable-next-line
            return prev.filter((item) => item.id != id);
        });
        setSearchTerm("");
        // eslint-disable-next-line
        let selectedData = searchApi?.filter((item) => item.StudentID == id);

        if (formData.BatchSize === "One-to-One") {
            setSelectedOptions(selectedData);
        } else {
            setSelectedOptions((prev) => {
                return [...prev, ...selectedData];
            });
        }
    };

    const FormatDateTime = (inputDateTime) => {
        // Parse the input value as a Date object
        const inputdate = new Date(inputDateTime);

        // Check if the date is valid
        if (!isNaN(inputdate.getTime())) {
            const time = inputdate.toLocaleTimeString("en-US", {
                hour12: false,
            });

            const date = inputdate.toISOString().split("T")[0];

            return { time, date };
        }
    };

    const dataCheck = (data, Message) => {
        let returnData = data.some((item) => {
            console.log(item);
            if (item.day?.length == 0 || item.Time?.length == 0) {
                Swal({
                    icon: "error",
                    title: Message,
                });
                return true;
            }
        });

        return returnData;
    };
    const onSubmit = (data) => {
        if (days) {
            let error = dataCheck(
                days,
                "Oops... Preferred Day cannot be empty"
            );

            if (error) {
                return;
            }
        }
        const courseName = courses.filter(
            (item) => item.CourseID == data.CourseID
        );
        const loginData = JSON.parse(localStorage.getItem("admindata"));

        if (!edit) {
            // let dateTime = FormatDateTime();

            let body = {
                ...data,
                CourseName: courseName[0].CourseName,
                AdminUserType: loginData.Type,
                GroupCounter: courseName[0].GroupCounter,
                TutorID: selectedTutor.value,
                TutorName: selectedTutor.label,
                Students: selectedOptions,
                StartDate: data.StartDate,
                Time: "",
                Days: days,
                BatchCreatedBy: JSON.parse(localStorage.getItem("admindata"))
                    .AdminUserID,
            };

            if (selectedOptions.length === 0) {
                alert("please Add students");
                return;
            }
            setLoading(true);
            Services.create_batch("POST", JSON.stringify(body), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);

                        Swal({
                            title: "Batch Created ",
                            text: response.Message,
                            icon: "success",
                            button: "OK",
                        });
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            const loginData = JSON.parse(localStorage.getItem("admindata"));
            let prevData = JSON.parse(localStorage.getItem("batchDetails"));
            let Updatedbody = {
                StartDate: data.StartDate,
                Days: days,
                Status: data.Status,
            };

            if (selectedTutor.value != prevData.TutorID) {
                Updatedbody = {
                    ...Updatedbody,
                    TutorID: selectedTutor.value,
                    TutorName: selectedTutor.label,
                };
            }
            if (data.BatchName != prevData.BatchName) {
                Updatedbody = { ...Updatedbody, BatchName: data.BatchName };
            }
            if (data.CourseID != prevData.CourseID) {
                Updatedbody = {
                    ...Updatedbody,
                    CourseID: data.CourseID,
                    CourseName: courseName[0].CourseName,
                };
            }
            if (data.BatchSize != prevData.BatchSize) {
                Updatedbody = {
                    ...Updatedbody,
                    BatchSize: data.BatchSize,
                };
            }
            if (data.PlanNumber_Batch != prevData.PlanNumber_Batch) {
                Updatedbody = {
                    ...Updatedbody,
                    PlanNumber_Batch: data.PlanNumber_Batch,
                };
            }
            if (data.TutorInstructions != prevData.TutorInstructions) {
                Updatedbody = {
                    ...Updatedbody,
                    TutorInstructions: data.TutorInstructions,
                };
            }
            if (batchDetails.Status != 2) {
                delete Updatedbody.Status;
            }
            var moment1 = moment(batchDetails.StartDate);
            var moment2 = moment(data.StartDate);

            // Compare if they represent the same day
            var isSameDay = moment1.isSame(moment2, "day");
            if (isSameDay) {
                delete Updatedbody.StartDate;
            }

            let newBody = compareObjects(Updatedbody, batchDetails);
            if (CheckIsObjectEmpty(newBody)) {
                warningSwal("Warning", "Nothing to update");
                return;
            }
            Services.updateBatchDetails(
                "PUT",
                JSON.stringify(newBody),
                token,
                params.get("BatchId")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        localStorage.removeItem("batchDetails");
                        Navigate(-1);

                        Swal({
                            title: "Batch updated ",
                            text: response.Message,
                            icon: "success",
                            button: "OK",
                        });
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };
    const deleteHandler = (e) => {
        let studentid = e.currentTarget.getAttribute("studentid");
        // eslint-disable-next-line
        let newData = selectedOptions.filter(
            // eslint-disable-next-line
            (item) => item.StudentID != studentid
        );
        setSelectedOptions(newData);
    };
    const backButton = () => {
        Navigate(-1);
    };
    const handleTutorChange = (searchString) => {
        setTutorSearchString(searchString);
    };
    const onTutorSelect = (searchString) => {
        setSelectedTutor(searchString);
        setValue("TutorEmailID", searchString.Email);
    };
    return (
        <Layout Active={"Batches"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Edit Batch" : "Create Batch"}</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <button className={TutorClasses["back"]} onClick={backButton}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{edit ? "Edit Batch" : "Create Batch"}</h3>
                    {mainLoad ? (
                        <MainLoader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {edit && (
                                <div className={TutorClasses["form-control"]}>
                                    <label htmlFor="Duration">
                                        Batch Name{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <input
                                        type="text"
                                        disabled={edit}
                                        placeholder="Batch Name"
                                        {...register("BatchName", {
                                            required: "Batch Name is required",
                                        })}
                                    />
                                    {errors.BatchName && (
                                        <span>This field is required</span>
                                    )}
                                </div>
                            )}
                            <Controller
                                name="Status"
                                control={control}
                                defaultValue={1} // Default value for status is 1 (submit)
                                render={({ field }) => (
                                    <input type="hidden" {...field} />
                                )}
                            />

                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Tutor <span className="important">*</span>
                                </label>

                                <Controller
                                    name="TutorID"
                                    control={control}
                                    defaultValue={1} // Default value for status is 1 (submit)
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            options={tutors}
                                            isDisabled={
                                                params.get("BatchId")
                                                    ? (batchDetails?.IsTutorAccepted ==
                                                          2 &&
                                                          batchDetails?.Status ==
                                                              1) ||
                                                      (batchDetails?.IsTutorAccepted ==
                                                          0 &&
                                                          batchDetails?.Status ==
                                                              2)
                                                        ? false
                                                        : true
                                                    : false
                                            }
                                            onInputChange={handleTutorChange}
                                            value={selectedTutor}
                                            onChange={onTutorSelect}
                                            placeholder="Search Tutor"
                                            // noOptionsMessage={() => "No Student Found"}
                                            isLoading={tutorSearchLoad}
                                            styles={{
                                                // Set the width to 100%
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: "100%",
                                                }),
                                            }}
                                        />
                                    )}
                                />
                                {errors.TutorID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Course <span className="important">*</span>
                                </label>

                                <select
                                    disabled={edit}
                                    defaultValue={""}
                                    {...register("CourseID", {
                                        required: true,
                                        onChange: (e) => {
                                            setOptions([]);
                                            setValue("TutorLessonPlan", "");
                                            // getLessonPlans(e.target.value);
                                            setSelectedOptions(
                                                [],
                                                setSearchAPI([])
                                            );
                                        },
                                    })}>
                                    <option value="" disabled>
                                        Select Course
                                    </option>
                                    {courses?.map((item) => (
                                        <option value={item.CourseID}>
                                            {item.CourseName}
                                        </option>
                                    ))}
                                </select>
                                {errors.CourseID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Batch Size{" "}
                                    <span className="important">*</span>  {" "}
                                </label>

                                <select
                                    disabled={edit}
                                    defaultValue={""}
                                    {...register("BatchSize", {
                                        required: true,
                                        onChange: (e) => {
                                            setOptions([]);
                                            setSelectedOptions(
                                                [],
                                                setSearchAPI([])
                                            );
                                        },
                                    })}>
                                    <option value="" disabled>
                                        Select Batch Size
                                    </option>

                                    <option value="One-to-One">
                                        One-to-One
                                    </option>
                                    <option value={"Group"}>Group</option>
                                </select>
                                {errors.BatchSize && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            {/* <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Starting Lesson Plan
                                    <span className="important">*</span>  {" "}
                                </label>

                                <select
                                    {...register("PlanNumber_Batch", {
                                        required: true,
                                    })}
                                    defaultValue={""}>
                                    <option value={""}>
                                        Starting Lesson Plan
                                    </option>
                                    {lessonPlan.map((item) => (
                                        <option value={item.PlanNumber}>
                                            {item.PlanNumber}
                                        </option>
                                    ))}
                                </select>
                                {errors.PlanNumber_Batch && (
                                    <span>This field is required</span>
                                )}
                            </div> */}
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Start Date{" "}
                                    <span className="important">*</span>{" "}
                                </label>

                                <input
                                    {...register("StartDate", {
                                        required: true,
                                    })}
                                    type="date"
                                    placeholder="Start Date"
                                    min={new Date().toISOString().split("T")[0]}
                                />
                                {errors.StartDate && (
                                    <span>This field is required</span>
                                )}
                            </div>

                            {!edit && (
                                <div className={TutorClasses["form-control"]}>
                                    <label htmlFor="Duration">
                                        Duration{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <select
                                        name="duration"
                                        {...register("Duration", {
                                            required: true,
                                        })}
                                        defaultValue={""}>
                                        <option value="" disabled>
                                            Duration
                                        </option>
                                        {Array.from(
                                            { length: 24 },
                                            (_, i) => (i + 1) * 5
                                        ).map((item) => (
                                            <option value={item}>
                                                {item} min
                                            </option>
                                        ))}
                                    </select>
                                    {errors.Duration && (
                                        <span>This field is required</span>
                                    )}
                                </div>
                            )}
                            {!edit && (
                                <div
                                    className={`${TutorClasses["form-control"]}`}
                                    ref={dropDownRef}
                                    style={{
                                        // gridRow: "3/-1",
                                        gridColumn: "1 / -1",
                                    }}>
                                    <label htmlFor="Student">
                                        {" "}
                                        Student{" "}
                                        <span className="important">*</span>
                                    </label>
                                    <div>
                                        <div
                                            className={
                                                TutorClasses["students-select"]
                                            }>
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                placeholder="Enter phone number (without country code)"
                                                onChange={handleInputChange}
                                                style={{ width: "97%" }}
                                            />
                                            <button
                                                className={
                                                    TutorClasses["numbersearch"]
                                                }
                                                onClick={handleSearch}
                                                type="button">
                                                Search
                                            </button>
                                            {options.length > 0 && (
                                                <div
                                                    className={
                                                        TutorClasses[
                                                            "students-options"
                                                        ]
                                                    }>
                                                    {options.length > 0 &&
                                                        options.map(
                                                            (option) => (
                                                                <div
                                                                    key={
                                                                        option.id
                                                                    }>
                                                                    <button
                                                                        id={
                                                                            option.id
                                                                        }
                                                                        type="button"
                                                                        onClick={
                                                                            buttonHandler
                                                                        }>
                                                                        {" "}
                                                                        {
                                                                            option.label
                                                                        }
                                                                    </button>
                                                                </div>
                                                            )
                                                        )}
                                                </div>
                                            )}
                                        </div>
                                        {selectedOptions.length > 0 && (
                                            <div
                                                className={
                                                    TutorClasses[
                                                        "selected-studentdata"
                                                    ]
                                                }>
                                                {selectedOptions.map((item) => {
                                                    return (
                                                        <div
                                                            key={`${item.StudentID}21`}>
                                                            <button
                                                                onClick={
                                                                    deleteHandler
                                                                }
                                                                studentid={
                                                                    item.StudentID
                                                                }
                                                                type="button">
                                                                <AiOutlineClose />
                                                            </button>
                                                            <p>
                                                                {
                                                                    item.StudentName
                                                                }
                                                            </p>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {/* <div
                                style={{
                                    gridColumn: "1/-1",
                                    display: "grid",
                                    gap: "0.5rem",
                                }}>
                                <label htmlFor="Days">
                                    Days <span className="important">*</span>
                                </label>
                               
                            </div> */}
                            <PreferredDays days={days} setDays={setDays} />
                            <div
                                className={TutorClasses["form-control"]}
                                style={{
                                    gridColumn: "1 / -1",
                                }}>
                                <label htmlFor="ContactNumber">
                                    Tutor Instructions{" "}
                                    <span className="important">*</span>
                                </label>

                                <textarea
                                    rows={5}
                                    {...register("TutorInstructions", {
                                        required: true,
                                    })}></textarea>
                                {errors.TutorInstructions && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            {loading ? (
                                <div className={TutorClasses["submit-button"]}>
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                </div>
                            ) : (
                                <div className={TutorClasses["submit-button"]}>
                                    <button
                                        disabled={loading}
                                        type="button"
                                        onClick={() => {
                                            setValue("Status", 1);
                                            handleSubmit(onSubmit)();
                                        }}>
                                        {" "}
                                        Submit
                                    </button>
                                    {(!edit || batchDetails.Status == 2) && (
                                        <button
                                            disabled={loading}
                                            type="button"
                                            onClick={() => {
                                                setValue("Status", 2);
                                                handleSubmit(onSubmit)();
                                            }}>
                                            {" "}
                                            Save as Draft
                                        </button>
                                    )}
                                </div>
                            )}
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default AddTutor;
