import React, { useEffect, useState } from "react";
import CommunicationClasses from "./communications.module.css";
import { useSearchParams } from "react-router-dom";
import { Services } from "Services";
import Moment from "react-moment";
import Skeleton from "./skeleton";
import Modalcomponent from "./Modal";
import { Error, Success_Dark, Error_Dark } from "../../../../../Util/Toast";
function Communications() {
    const [Load, setLoad] = useState(true);
    const [modal, setModal] = useState(false);
    const [Message, setMessage] = useState(null);
    const [Data, setData] = useState([]);
    const [Params] = useSearchParams();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        let body = {
            SentFor: Params.get("Id"),
            SentForType: 4,
        };
        Services.getCommunications("POST", JSON.stringify(body), token)
            .then((response) => {
                if (response.Status === 1) {
                    setData(response?.Count);
                    setLoad(false);
                } else if (response.Status === 0) {
                    Error(response.Message);
                    setLoad(false);
                }
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
                Error("something went wrong please try again");
            });
    }, []);
    return (
        <div className={CommunicationClasses["Container"]}>
            {modal && (
                <Modalcomponent
                    showModal={modal}
                    setShowModal={setModal}
                    Message={Message}
                    setMessage={setMessage}
                />
            )}
            <h3>Releated Emails</h3>
            {Load ? (
                <Skeleton />
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Send Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Data.map((item) => (
                            <tr>
                                <td>{item.Subject}</td>
                                <td>
                                    <button
                                        className={
                                            CommunicationClasses["modal-btn"]
                                        }
                                        onClick={() => {
                                            setMessage(item);
                                            setModal(true);
                                        }}>
                                        Click
                                    </button>
                                </td>
                                <td>
                                    <Moment
                                        // add={{ hours: 5, minutes: 30 }}
                                        format={"DD-MM-YY HH:mm"}>
                                        {item?.CreatedAt}
                                    </Moment>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Communications;
