import React, { useEffect, useState } from "react";
import CourseClasses from "./course.module.css";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Tableload from "./TableLoad/load";
import { Services } from "../../../../../Services";
import { toast } from "react-toastify";
function Tutor() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.GetTutorDetails("GET", null, token, params.get("TutorId"))
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Tutors.Courses);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    const courseHandler = (event) => {
        let id = event.currentTarget.getAttribute("id");
        Navigate(`/courses/Lessonplan?id=${id}`);
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Courses</title>
            </Helmet>
            <div className={CourseClasses["Container"]}>
                <div className={CourseClasses["Tutor"]}>
                    {loading ? (
                        <Tableload />
                    ) : data?.length > 0 ? (
                        <div className={CourseClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Course Image</th>
                                        <th>Course Name</th>
                                        <th>Level</th>
                                        <th>Language</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr
                                            id={item.CourseID}
                                            onClick={courseHandler}>
                                            <td>
                                                {" "}
                                                <img
                                                    style={{
                                                        width: "6rem",
                                                        height: "5rem",
                                                    }}
                                                    src={item.CourseImage}
                                                    alt="category-img"
                                                />
                                            </td>
                                            <td>{item.CourseName}</td>

                                            <td>{item.Level}</td>
                                            <td>{item.Language}</td>
                                            <td
                                                style={{
                                                    width: "150px",
                                                    textAlign: "center",
                                                }}>
                                                <div
                                                    className={
                                                        CourseClasses["Prices"]
                                                    }>
                                                    <p>
                                                        One-to-One
                                                        <b>
                                                            {" "}
                                                            : ₹{" "}
                                                            {item.One_One_Price}
                                                        </b>
                                                        ,
                                                    </p>
                                                    <p>
                                                        Group
                                                        <b>
                                                            {" "}
                                                            : ₹{" "}
                                                            {item.Group_Price}
                                                        </b>
                                                    </p>
                                                </div>
                                            </td>
                                            {/* <td>
                                                {item.Status === 1 ? (
                                                    <div
                                                        className={
                                                            CourseClasses[
                                                                "Status-active"
                                                            ]
                                                        }>
                                                        <span>Active</span>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            CourseClasses[
                                                                "Status-inactive"
                                                            ]
                                                        }>
                                                        <span>Inactive</span>
                                                    </div>
                                                )}
                                            </td> */}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Courses Found</p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Tutor;
