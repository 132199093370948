import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import MainLoader from "../../../Components/loader/loader";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [mainLoad, setMainLoad] = useState(false);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        if (params.get("id")) {
            setEdit(true);
            let values = JSON.parse(localStorage.getItem("configvalue"));
            if (values) {
                reset({ ...values });
            }
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        setLoading(true);
        if (edit) {
            Services.update_config(
                "PUT",
                JSON.stringify(data),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            Services.add_config("POST", JSON.stringify(data), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Configuration"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Configuration</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>
                        {!edit ? "Add Configuration" : "Update Configuration"}
                    </h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}
                        {!edit && (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="First Name">
                                    Key <span className="important">*</span>
                                </label>
                                <input
                                    {...register("Key", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.Key && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        )}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Value <span className="important">*</span>
                            </label>
                            <input
                                {...register("Value", {
                                    required: true,
                                    validate: NullCheck,
                                })}
                            />
                            {errors.Key && <span>This field is required</span>}
                        </div>

                        {!edit && (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="First Name">
                                    Section <span className="important">*</span>
                                </label>
                                <input
                                    {...register("Section", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.Section && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        )}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Description <span className="important">*</span>
                            </label>
                            <input
                                {...register("Description", {
                                    required: true,
                                    validate: NullCheck,
                                })}></input>
                            {errors.Description && (
                                <span>This field is required</span>
                            )}
                        </div>

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
