import React, { useState, useEffect } from "react";
import ContactClasses from "./index.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
import SkeletonLoad from "./TableLoad/load";
import { warningSwal } from "../../Util/Toast";
import Moment from "react-moment";
function Enquiry() {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        Services.Contact_us("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.ContactForms);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories(2);
        // eslint-disable-next-line
    }, []);

    return (
        <Layout Active={"ContactUs"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact US</title>
            </Helmet>
            <div className={ContactClasses["Container"]}>
                <div className={ContactClasses["control-btns"]}></div>
                <div className={ContactClasses["Tutor"]}>
                    <h3> Contact Us </h3>
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={ContactClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name </th>
                                        <th>MobileNumber </th>
                                        <th>EmailID </th>
                                        <th>Comments </th>
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr>
                                            <td>
                                                {" "}
                                                {item.FirstName} {item.LastName}
                                            </td>
                                            <td> {item.MobileNumber}</td>
                                            <td>{item.EmailID}</td>
                                            <td
                                                style={{
                                                    maxWidth: "200px",
                                                    wordBreak: "break-all",
                                                }}>
                                                {item.Comments}
                                            </td>

                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Records Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Enquiry;
