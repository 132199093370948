import React, { useState, useEffect } from "react";
import CourseClasses from "./course.module.css";
import { Services } from "../../../Services";
import SkeletonLoad from "./TableLoad/load";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { warningSwal } from "../../../Util/Toast";
import { AccessKeys } from "../../../Routes/config";
import { AccessControl } from "../../../Util/AccessControl";

function Tutor() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        Services.getCourses("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Courses);
                } else if (response.Status === 0) {
                    warningSwal(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories(2);
        // eslint-disable-next-line
    }, []);
    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/courses/update?id=${id}`);
    };
    const lessonPlanHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/courses/Lessonplan?id=${id}`);
    };
    const logHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        Navigate("/ActivityLog", {
            state: {
                Active: "Courses",
                ActionByType: 8,
                ActionFor: id,
                name: name,
                Page: "Courses",
            },
        });
    };
    return (
        <>
            {loading ? (
                <SkeletonLoad count={3} />
            ) : data.length > 0 ? (
                <div className={CourseClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Course Image</th>
                                <th>Course Name</th>
                                <th>Category</th>
                                <th>Price</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr
                                    onClick={logHandler}
                                    id={item.CourseID}
                                    name={item.CourseName}>
                                    <td>
                                        {" "}
                                        <img
                                            style={{
                                                width: "6rem",
                                                height: "5rem",
                                            }}
                                            src={item.CourseImage}
                                            alt="category-img"
                                        />
                                    </td>
                                    <td>{item.CourseName}</td>

                                    <td>{item.CategoryName}</td>
                                    <td
                                        style={{
                                            width: "150px",
                                            textAlign: "center",
                                        }}>
                                        <div
                                            className={CourseClasses["Prices"]}>
                                            <p>
                                                One-to-One
                                                <b> : ₹ {item.One_One_Price}</b>
                                                ,
                                            </p>
                                            <p>
                                                Group
                                                <b> : ₹ {item.Group_Price}</b>
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        {item.Status === 1 ? (
                                            <div
                                                className={
                                                    CourseClasses[
                                                        "Status-active"
                                                    ]
                                                }>
                                                <span>Active</span>
                                            </div>
                                        ) : (
                                            <div
                                                className={
                                                    CourseClasses[
                                                        "Status-inactive"
                                                    ]
                                                }>
                                                <span>Inactive</span>
                                            </div>
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                CourseClasses["Action-btns"]
                                            }>
                                            {AccessControl(AccessKeys.Courses)
                                                ?.edit === 1 && (
                                                <button
                                                    id={item.CourseID}
                                                    onClick={editHandler}
                                                    className={
                                                        CourseClasses[
                                                            "edit-course"
                                                        ]
                                                    }>
                                                    <FaRegEdit />
                                                </button>
                                            )}
                                            <button
                                                className={
                                                    CourseClasses["view-plan"]
                                                }
                                                id={item.CourseID}
                                                onClick={lessonPlanHandler}>
                                                View Lesson Plan
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Courses Found</p>
                </div>
            )}
        </>
    );
}

export default Tutor;
