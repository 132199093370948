import React, { useState, useEffect } from "react";
import Layout from "../../../../Components/Layout";
import detailsClass from "./index.module.css";

import Profile from "./Profile/profile";
import { Helmet } from "react-helmet";
import Activity from "./Activity/Activity";
import Communications from "./Communications";
import Actionbtns from "./Actionbtns";
import Review from "./Reviews";

import Tabs from "./Tabs";
import Courses from "./course/course";
import { useNavigate } from "react-router-dom";
import { AccessKeys } from "../../../../Routes/config";
import { useSearchParams } from "react-router-dom";
import { AccessControl } from "../../../../Util/AccessControl";
import Earnings from "./Earnings/earnings";
import Payouts from "./Payouts/Payout";
import Batches from "./Batches/links";
function Index() {
    const [searchParams] = useSearchParams();
    const Navigate = useNavigate();

    const [isActive, setIsActive] = useState("");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (searchParams.get("tab")) {
            setIsActive(searchParams.get("tab"));
        } else {
            setIsActive("Profile");
        }
    }, [searchParams.get("tab")]);
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Tutor"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {data
                        ? `${data.FirstName} ${
                              data.LastName != "NA" && data.LastName
                          } `
                        : "Tutor"}
                </title>
            </Helmet>
            <div className={detailsClass["Container"]}>
                <button onClick={backButton} className={detailsClass["back"]}>
                    Back
                </button>
                <div className={detailsClass["wrapper"]}>
                    {data && (
                        <h3>
                            <span></span>Tutor:
                            {`${data.FirstName} ${
                                data.LastName != "NA" && data.LastName
                            } `}
                            {AccessControl(AccessKeys.Tutor)?.edit === 1 && (
                                <Actionbtns data={data} setData={setData} />
                            )}
                        </h3>
                    )}

                    <Tabs
                        isActive={isActive}
                        setIsActive={setIsActive}
                        setLoading={setLoading}
                    />

                    {isActive === "Profile" && (
                        <Profile
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            setData={setData}
                        />
                    )}
                    {isActive === "Activity" && <Activity />}
                    {isActive === "Email" && <Communications />}
                    {isActive === "Review" && <Review />}
                    {isActive === "Courses" && <Courses />}
                    {isActive === "Earnings" && <Earnings />}
                    {isActive === "Payouts" && <Payouts />}
                    {isActive === "Batches" && <Batches />}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
