import React, { useState, useEffect } from "react";
import { Services } from "../../Services";
import { warningSwal } from "../../Util/Toast";
import LogClasses from "./log.module.css";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "Components/Layout";
function Log({ ActionFor, ActionByType }) {
    const [Activity, setActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const location = useLocation();
    const Navigate = useNavigate();
    useEffect(() => {
        if (location.state.ActionFor && location.state.ActionByType) {
            Services.ActivityLogs(
                "GET",
                null,
                token,
                location.state.ActionFor,
                location.state.ActionByType
            )
                .then((res) => {
                    console.log(res);
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                    if (res.Status === 1) {
                        setActivity(res.ScheduleClasess);
                    } else if (res.Status === 0) {
                        warningSwal("Warning", res.Message);
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    }, []);
    return (
        <Layout Active={location.state.Active ? location.state.Active : ""}>
            <div className={LogClasses["Container"]}>
                <ol className={LogClasses["breadcrumb"]}>
                    <li
                        style={{ cursor: "pointer" }}
                        onClick={() => Navigate(-1)}>
                        <span>{location?.state?.Page} </span>
                    </li>
                    <li className={LogClasses["current"]}>
                        <span>{location?.state?.name}</span>
                    </li>

                    {/* <p> {location?.state?.Name}</p>
                    <span>&rsaquo;</span>
                    <p>Lesson Plan</p> */}
                </ol>
                <div className={LogClasses["Activity-feed"]}>
                    <h3>Activity Feed</h3>
                    {loading ? (
                        <>
                            <p style={{ margin: "0.1rem" }}>
                                <Skeleton height={"10px"} />
                            </p>
                            <p style={{ margin: "0.1rem" }}>
                                <Skeleton height={"10px"} />
                            </p>
                            <p style={{ margin: "0.1rem" }}>
                                <Skeleton height={"10px"} />
                            </p>
                        </>
                    ) : (
                        <ul>
                            {Activity.length > 0
                                ? Activity.map((item) => (
                                      <li>
                                          {" "}
                                          <span>
                                              {item.ActionByName} •{" "}
                                              {item.Action}
                                          </span>
                                          <span>
                                              {" "}
                                              <Moment format="Do MMM YYYY hh:mm a">
                                                  {new Date(item.CreatedAt)}
                                              </Moment>
                                          </span>
                                      </li>
                                  ))
                                : "No Logs found"}
                        </ul>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Log;
