import React, { useState, useEffect } from "react";
import ContactClasses from "./index.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
import SkeletonLoad from "./TableLoad/load";
import { warningSwal } from "../../Util/Toast";
import Modal from "./Modal";
import Moment from "react-moment";
function Enquiry() {
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState({});
    const [showModal, setModal] = useState(false);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        if (!loading) {
            setLoading(true);
        }
        Services.Complaints("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Complaints);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories();
        // eslint-disable-next-line
    }, []);

    return (
        <Layout Active={"Complaint"}>
            {showModal && (
                <Modal
                    getDetails={details}
                    modal={showModal}
                    setModal={setModal}
                    getAllCategories={getAllCategories}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Complaints</title>
            </Helmet>
            <div className={ContactClasses["Container"]}>
                <div className={ContactClasses["control-btns"]}></div>
                <div className={ContactClasses["Tutor"]}>
                    <h3> Complaints</h3>
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={ContactClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Parent Name </th>
                                        <th>EmailID </th>
                                        <th>Course Instructor Name </th>
                                        <th>Complaint Against </th>
                                        <th>Created At</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr
                                            onClick={() => {
                                                setModal(true);
                                                setDetails(item);
                                            }}>
                                            <td> {item.ParentName}</td>
                                            <td
                                                style={{
                                                    maxWidth: "10rem",
                                                    wordBreak: "break-word",
                                                }}>
                                                {" "}
                                                {item.EmailID}
                                            </td>
                                            <td>{item.CourseInstructorName}</td>
                                            <td style={{ maxWidth: "200px" }}>
                                                {item.ComplaintAgainst}
                                            </td>

                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                            <td>
                                                {item?.Status == 1 ? (
                                                    <span
                                                        className={
                                                            ContactClasses[
                                                                "resolved"
                                                            ]
                                                        }>
                                                        Resolved
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={
                                                            ContactClasses[
                                                                "pending"
                                                            ]
                                                        }>
                                                        Pending
                                                    </span>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Data Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Enquiry;
