import React, { useState, useEffect } from "react";
import ProfileData from "../../profileData/Profile";
import SkeletonLoad from "./Skeleton";
import ProfileClasses from "./profile.module.css";
import { Services } from "../../../../../Services";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import user from "../../../../../Assets/square-user.jpg";
import TutorSkeleton from "../../../TutorManagement/Tutor/TableLoad/load";
import TutorTable from "../../../TutorManagement/Tutor/TutorTable";
function Profile({ loading, setLoading, data, setData }) {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [permissions, setPermissions] = useState();
    const [LoadingData, setLoadingData] = useState(true);
    const [Tutors, setTutors] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getTutors = () => {
        let body = JSON.stringify({ AdminUserID: params.get("ID") });
        Services.getLinkedTutorByAdminId("POST", body, token)
            .then((response) => {
                setTimeout(() => {
                    setLoadingData(false);
                }, 200);
                if (response.Status === 1) {
                    setTutors(response.Tutors);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        Services.getAdminDetailsByID("GET", null, token, params.get("ID"))
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.AdminUsers);
                    setPermissions(
                        response.AdminUsers?.AdminRoles?.filter((item) => {
                            const permissions = Object.keys(
                                item.Permissions
                            ).filter((key) => item.Permissions[key] === 1);
                            return permissions.length > 0; // If any permission is 1, keep the object
                        }).map((item) => {
                            const permissions = Object.keys(
                                item.Permissions
                            ).filter((key) => item.Permissions[key] === 1);
                            return {
                                name: item.name,
                                Permissions: permissions,
                            };
                        })
                    );
                    if (response.AdminUsers.Type === 2) {
                        getTutors();
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    const update = () => {
        Navigate(`/Update-admin?id=${data.AdminUserID}`);
    };
    return (
        <div>
            {loading ? (
                <SkeletonLoad />
            ) : (
                <>
                    <ProfileData
                        data={{ ...data, ProfilePicture: user }}
                        update={update}
                        id={data.AdminUserID}
                    />
                    <div className={ProfileClasses["profile-data"]}>
                        <div>
                            <h3>Permissions</h3>
                            {permissions.length > 0 ? (
                                <div className={ProfileClasses["Permissions"]}>
                                    {permissions.map((item) => (
                                        <div
                                            className={
                                                ProfileClasses[
                                                    "permission_single"
                                                ]
                                            }>
                                            <h4>{item?.name}</h4>
                                            <p>
                                                {item.Permissions.map(
                                                    (item) => (
                                                        <span
                                                            className={
                                                                ProfileClasses[
                                                                    item
                                                                ]
                                                            }>
                                                            {item}
                                                        </span>
                                                    )
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <p>No Permission Found</p>
                            )}
                            {/* <p>View Accounting</p> */}
                        </div>
                        <div>
                            <h3>Clients</h3>
                            <span>No Clinets Found</span>
                        </div>
                    </div>
                    <h3 style={{ margin: "1rem 0" }}> Associated Tutors</h3>
                    {data.Type === 2 ? (
                        LoadingData ? (
                            <TutorSkeleton count={3} />
                        ) : (
                            <TutorTable
                                data={Tutors}
                                endIndex={undefined}
                                startIndex={0}
                            />
                        )
                    ) : (
                        ""
                    )}
                </>
            )}
        </div>
    );
}

export default Profile;
