import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";
import Permissions from "../config";

function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        if (params.get("id")) {
            setEdit(true);
            let select = JSON.parse(sessionStorage.getItem("Role"));
            reset({ Status: select[0].Status, RoleName: select[0].RoleName });
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (data) => {
        let formData = { RoleName: data.RoleName };

        if (edit) {
            formData = { ...formData, Status: data.Status };
            setLoading(true);
            Services.updaterole(
                "PUT",
                JSON.stringify(data),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            formData = {
                ...formData,
                AccessItems: Permissions,
            };
            setLoading(true);
            Services.addrole("POST", JSON.stringify(formData), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    return (
        <Layout Active={"Roles"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Role</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Role" : "Update Role"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Role Name <span className="important">*</span>
                            </label>
                            <input
                                {...register("RoleName", {
                                    required: true,
                                    validate: NullCheck,
                                })}
                            />
                            {errors.RoleName && (
                                <span>This field is required</span>
                            )}
                        </div>
                        {/* {!edit && (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="items">
                                    Access Items <br />
                                 
                                </label>
                                <div className={TutorClasses["select"]}>
                                    <Select
                                        onChange={changeHandler}
                                        className="react-select-container"
                                        closeMenuOnSelect={false}
                                        components={animatedComponents}
                                        isMulti
                                        options={menuItem}
                                    />
                                    {errors?.AccesstIems && (
                                        <p style={{ color: "red" }}>
                                            {errors?.AccessItems?.message}
                                        </p>
                                    )}
                                </div>
                            </div>
                        )} */}
                        {edit && (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="First Name">
                                    Status<span className="important">*</span>
                                </label>
                                <select
                                    {...register("Status", {
                                        required: true,
                                    })}>
                                    <option value="1">Active</option>
                                    <option value="2">Inactive</option>
                                </select>
                                {errors.Status && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        )}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
