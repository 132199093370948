import React, { useState, useEffect } from "react";
import TutorClasses from "./batches.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
import SkeletonLoad from "./TableLoad/load";
import { warningSwal } from "../../Util/Toast";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import Filter from "./filter/filter";
import useStore from "../../store";
import Loader from "../../Components/loader/fallingLines";
import { useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import ReactPaginate from "react-paginate";
function Tutor() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [forcepage, setforcepage] = useState(0);
    const RecordCount = useStore((item) => item.NoOfRecords);
    const [showPaginate, setShowPagiate] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState();
    // Filter States
    const [filterLoad, setFilterLoad] = useState(false);
    const [pageCount, setpageCount] = useState(1);
    const [showFilter, setShowFilter] = useState(false);
    const [BatchStatus, setBatchStatus] = useState("NEEDAPPROVAL");
    const [filterData, setFilterData] = useState({
        filter: false,
        filterData: [],
        filterBody: {},
    });
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    // Get All Batches
    const Get_Classes = (ActiveTab, toSkip) => {
        let body = JSON.stringify({
            Type: ActiveTab,
            toSkip: toSkip,
            ...filterData.filterBody,
        });
        Services.Get_Classes("POST", body, token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Batches);
                    if (
                        response.Batches.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        // Sets Current Active Tab
        let ActiveTab = searchParams.get("tab");
        let Page = searchParams.get("page");

        if (ActiveTab) {
            setBatchStatus(ActiveTab);
            Get_Classes(ActiveTab, (Number(Page) - 1) * RecordCount);
            setforcepage(Number(searchParams.get("page")) - 1);
        } else {
            Get_Classes("NEEDAPPROVAL", (Number(Page) - 1) * RecordCount);
            setforcepage(Number(searchParams.get("page")) - 1);
        }
        // eslint-disable-next-line
    }, [filterData]);
    const detailsHandler = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        Navigate(`/Batches/Details?BatchId=${id}`);
        localStorage.setItem("name", name);
    };
    const NavHandler = (e) => {
        let buttonType = e.currentTarget.getAttribute("btn-type");
        setBatchStatus(buttonType);
        setLoading(true);

        Get_Classes(buttonType, 0);
        setSearchParams({ page: 1, tab: buttonType });
    };
    const handlePageChange = (selectedObject) => {
        console.log(selectedObject);
        // setcurrentPage(selectedObject.selected);
        // handleFetch();
        setSearchParams({
            tab: BatchStatus,
            page: selectedObject.selected + 1,
        });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        setLoading(true);

        Get_Classes(BatchStatus, selectedObject.selected * RecordCount);
    };
    return (
        <Layout Active={"Batches"}>
            {filterLoad && <Loader />}
            {showFilter && (
                <Filter
                    setShowFilter={setShowFilter}
                    showFilter={showFilter}
                    setFilterLoad={setFilterLoad}
                    setData={setData}
                    getAllCategories={Get_Classes}
                    setFilterData={setFilterData}
                    filterData={filterData}
                    BatchStatus={BatchStatus}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Batches</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["control-btns"]}>
                    <button
                        className={TutorClasses["button"]}
                        onClick={() => {
                            Navigate("/Batches/add");
                        }}>
                        <BiPlus size={20} /> Create Batch
                    </button>
                    <button
                        className={TutorClasses["button"]}
                        onClick={() => setShowFilter(true)}>
                        Filter
                    </button>
                </div>
                {filterData?.filter && (
                    <div className={TutorClasses["filteredOptions"]}>
                        <div className={TutorClasses["options"]}>
                            <span>Filters:</span>
                            {filterData.filterData.map((item) => (
                                <div>
                                    <p>{item.display}</p>
                                    <p>{item.option}</p>
                                </div>
                            ))}
                        </div>
                        <button
                            onClick={() => {
                                setFilterData({
                                    filter: false,
                                    filterData: [],
                                    filterBody: {},
                                });
                                setLoading(true);
                                Get_Classes(BatchStatus, 0);
                            }}>
                            Clear Filter
                        </button>
                    </div>
                )}
                <div className={TutorClasses["Tutor"]}>
                    <h3>Batches</h3>
                    <div className={TutorClasses["Navigator"]}>
                        <button
                            btn-type="NEEDAPPROVAL"
                            className={
                                BatchStatus === "NEEDAPPROVAL"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Need Approval
                        </button>
                        <button
                            btn-type="APPROVED_No_Classes"
                            className={
                                BatchStatus === "APPROVED_No_Classes"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            New
                        </button>
                        <button
                            btn-type="APPROVED"
                            className={
                                BatchStatus === "APPROVED"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Ongoing
                        </button>
                        <button
                            btn-type="REJECTED"
                            className={
                                BatchStatus === "REJECTED"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Rejected
                        </button>
                        <button
                            btn-type="DRAFT"
                            className={
                                BatchStatus === "DRAFT"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Draft
                        </button>
                        <button
                            btn-type="COMPLETED"
                            className={
                                BatchStatus === "COMPLETED"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Finished
                        </button>
                        <button
                            btn-type="Gone_Cold"
                            className={
                                BatchStatus === "Gone_Cold"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Gone Cold
                        </button>
                        {/* <button
                            btn-type="Batch_Dissolved"
                            className={
                                BatchStatus === "Batch_Dissolved"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Batch_Dissolved
                        </button>
                        <button
                            btn-type="Parent_Discontinued"
                            className={
                                BatchStatus === "Parent_Discontinued"
                                    ? TutorClasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Parent Discontinued
                        </button> */}
                    </div>

                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data?.length > 0 ? (
                        <div className={TutorClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Batch Name</th>
                                        <th>Batch Size</th>
                                        <th>Course Name</th>
                                        <th>Tutor Name</th>
                                        {BatchStatus === "COMPLETED" && (
                                            <th>Completion Reason</th>
                                        )}
                                        <th>Created At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item) => {
                                        return (
                                            <tr
                                                onClick={detailsHandler}
                                                id={item.BatchID}
                                                name={item.TutorName}>
                                                <td
                                                    style={{
                                                        maxWidth: "200px",
                                                    }}>
                                                    {item.BatchName}
                                                </td>
                                                <td>{item.BatchSize}</td>
                                                <td>{item.CourseName}</td>
                                                <td>{item.TutorName}</td>
                                                {BatchStatus ===
                                                    "COMPLETED" && (
                                                    <td
                                                        style={{
                                                            maxWidth: "8rem",
                                                        }}>
                                                        {
                                                            item.BatchFinishComments
                                                        }
                                                    </td>
                                                )}
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item?.CreatedAt}
                                                    </Moment>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            {showPaginate && (
                                <div
                                    style={{
                                        justifySelf: "right",
                                    }}>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        // pageRange={2}
                                        forcePage={forcepage}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        breakClassName={"page-item"}
                                        breakLinkClassName={"page-link"}
                                        containerClassName={"pagination"}
                                        pageClassName={"page-item"}
                                        pageLinkClassName={"page-link"}
                                        previousClassName={"page-item"}
                                        previousLinkClassName={"page-link"}
                                        nextClassName={"page-item"}
                                        nextLinkClassName={"page-link"}
                                        activeClassName={"active"}
                                    />
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Records Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
