import React from "react";
import qualificationClasses from "./index.module.css";
import { useAutoAnimate } from "@formkit/auto-animate/react";
function Qualification({ qualification, setQualification }) {
    const [parent] = useAutoAnimate(/* optional config */);
    const handleChange = (index, event) => {
        const type = event.currentTarget.getAttribute("inputtype");
        const newqualification = [...qualification];
        newqualification[index][type] = event.target.value;
        setQualification(newqualification);
    };

    // const handleLevelChange = (index, event) => {
    //     const newqualification = [...qualification];
    //     newqualification[index].level = event.target.value;
    //     setQualification(newqualification);
    // };

    const addSkill = () => {
        setQualification([
            ...qualification,
            {
                Qualification: "",
                Institution: "",
                Grade: "",
                Year: "",
            },
        ]);
    };

    const removeSkill = (index) => {
        const newqualification = [...qualification];
        newqualification.splice(index, 1);
        setQualification(newqualification);
    };
    console.log(qualification);
    return (
        <div className={qualificationClasses["skill-Wrapper"]}>
            <header>
                <h3>Qualification</h3>
                <button type="button" onClick={addSkill}>
                    + Add Qualification
                </button>
            </header>
            <div ref={parent}>
                {qualification.map((skill, index) => (
                    <div
                        key={index}
                        className={qualificationClasses["qualification"]}>
                        <div className={qualificationClasses["form-control"]}>
                            <label>Qualification:</label>
                            <input
                                type="text"
                                inputtype="Qualification"
                                value={skill.Qualification}
                                onChange={(event) => handleChange(index, event)}
                            />
                        </div>
                        {/* <div className={qualificationClasses["form-control"]}>
                            <label>Qualification Level:</label>
                            <select
                                value={skill.QualificationLevel}
                                inputtype={"QualificationLevel"}
                                onChange={(event) =>
                                    handleChange(index, event)
                                }>
                                <option value="">--Select--</option>
                                <option value="Beginner">Beginner</option>
                                <option value="Intermediate">
                                    Intermediate
                                </option>
                                <option value="Advanced">Advanced</option>
                            </select>
                        </div> */}
                        <div className={qualificationClasses["form-control"]}>
                            <label>Name of Institution:</label>
                            <input
                                type="text"
                                inputtype="Institution"
                                value={skill.Institution}
                                onChange={(event) => handleChange(index, event)}
                            />
                        </div>
                        <div className={qualificationClasses["form-control"]}>
                            <label>Grade/Percentage:</label>
                            <input
                                type="text"
                                inputtype="Grade"
                                value={skill.Grade}
                                onChange={(event) => handleChange(index, event)}
                            />
                        </div>
                        <div
                            className={`${qualificationClasses["form-control"]} ${qualificationClasses["last"]}`}>
                            <label>Year:</label>
                            <div>
                                <input
                                    type="text"
                                    inputtype="Year"
                                    value={skill.Year}
                                    onChange={(event) =>
                                        handleChange(index, event)
                                    }
                                />
                                {index > 0 && (
                                    <button
                                        type="button"
                                        onClick={() => removeSkill(index)}>
                                        Remove
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Qualification;
