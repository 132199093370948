import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./communications.module.css";
import { IoMdClose } from "react-icons/io";

const MailComponent = ({ showModal, setShowModal, setMessage, Message }) => {
    // const [checkbox, setcheckbox] = useState(false);

    const closeModal = () => {
        setShowModal(false);
        setMessage(false);
    };
    return (
        <Modal
            isOpen={showModal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["Message-Container"]}>
                <div className={Styles["Message-header"]}>
                    <h3>Mail</h3>
                    <button onClick={closeModal}>
                        <IoMdClose />
                    </button>
                </div>
                <div className={Styles["message-content"]}>
                    <h3>Subject:{Message?.Subject}</h3>
                    <p>
                        <b>Message</b>:
                        <span
                            dangerouslySetInnerHTML={{
                                __html: Message.Message,
                            }}></span>{" "}
                    </p>
                    <button onClick={closeModal}>Close</button>
                </div>
            </div>
        </Modal>
    );
};

export default MailComponent;
