import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { warningSwal } from "Util/Toast";
import EarningClasses from "./Earning.module.css";
import Moment from "react-moment";
import { Services } from "Services";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";

function Earnings({ setPayoutId, setType, Type }) {
    const Navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [params] = useSearchParams();

    const [earning, setEarning] = useState([]);
    const get_Earnings = (body) => {
        Services.Get_Payout("POST", JSON.stringify(body), token)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setEarning(res.Get_Payouts);
                } else if (res.Status === 0) {
                    warningSwal("Error", res?.Message);
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                warningSwal("Error", "Something went wrong ");
                console.log(err);
            });
    };
    useEffect(() => {
        get_Earnings({ TutorID: params.get("TutorId") });
    }, []);
    const detailHandler = (e) => {
        setType(2);
        setPayoutId(e.currentTarget.getAttribute("id"));
    };
    return (
        <>
            <h3>Payouts</h3>

            {/* <p>Filter By Date</p> */}
            {/* <header>
                <form
                    className={EarningClasses["form"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("FromDate", {
                            required: true,
                        })}
                    />
                    <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("ToDate", {
                            required: true,
                        })}
                    />
                    <button>Filter</button>
                </form>
                <div>
                    <button
                        onClick={CreatePayoutHandler}
                        disabled={selectedRows.length == 0}
                        title={
                            selectedRows.length == 0
                                ? "Select Earnings to create Payout"
                                : ""
                        }>
                        Create Payout
                    </button>
                </div>
            </header> */}
            <div className={EarningClasses["Table"]}>
                <table>
                    <thead>
                        <tr>
                            <th>Payout ID</th>
                            <th>Payout Date</th>
                            <th>Classes</th>
                            <th>Amount</th>
                            <th>Payout Status</th>
                        </tr>
                    </thead>
                    {loading ? (
                        <tbody>
                            {[1, 1].map((item) => (
                                <tr className={EarningClasses["loading"]}>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    ) : (
                        <tbody>
                            {earning?.length > 0 ? (
                                earning?.map((item) => {
                                    return (
                                        <tr
                                            onClick={detailHandler}
                                            id={item.PayoutID}>
                                            <td>{item.PayoutID}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                            <td>{item?.EarningsIds?.length}</td>
                                            <td
                                                style={{
                                                    color:
                                                        item.AmountToPay < 0
                                                            ? "red"
                                                            : "green",
                                                }}>
                                                ₹{" "}
                                                {isNaN(item.AmountToPay)
                                                    ? 0.0
                                                    : Number(
                                                          item.AmountToPay
                                                      ).toFixed(2)}
                                            </td>
                                            <td>
                                                <span
                                                    className={
                                                        item.PayoutStatus === 1
                                                            ? EarningClasses[
                                                                  "paid"
                                                              ]
                                                            : EarningClasses[
                                                                  "unpaid"
                                                              ]
                                                    }>
                                                    {item.PayoutStatus === 1
                                                        ? "paid"
                                                        : "pending"}
                                                </span>{" "}
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <td
                                        colSpan={8}
                                        style={{ textAlign: "center" }}>
                                        No Record Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>
            </div>
        </>
    );
}

export default Earnings;
