import React, { useEffect, useState } from "react";
import ReviewClasses from "./review.module.css";
import { Services } from "Services";
import Skeleton from "./Skeleton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
function Review() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    console.log(params.get("TutorId"));
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [Data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        Services.getAllTutorReviews(
            "POST",
            JSON.stringify({
                TutorID: params.get("TutorId"),
            }),
            token
        )
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setData(res.Reviews);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <div className={ReviewClasses["Container"]}>
            {/* <div className={ReviewClasses["over-view"]}>
                <header>
                    <h3>Review</h3>
                    <div className={ReviewClasses["header-left"]}>
                        <div>
                            <h4> No of Ratings</h4>
                            <p>0</p>
                        </div>
                        <div>
                            <h4> Average Ratings</h4>
                            <p>No Reviews</p>
                        </div>
                    </div>
                    <div className={ReviewClasses["header-right"]}>
                        <div>
                            <h4> Reviewd Hours</h4>
                            <p>0.0 Hours</p>
                        </div>
                        <div>
                            <h4> Latest Reviews</h4>
                            <p>No Reviews</p>
                        </div>
                    </div>
                </header>
            </div> */}
            {loading ? (
                <Skeleton />
            ) : (
                <div className={ReviewClasses["review"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Batch Name</th>
                                <th>Student Name</th>

                                <th>Review</th>
                                <th>Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Data.length > 0 ? (
                                Data.map((item) => (
                                    <tr
                                        onClick={() => {
                                            Navigate(
                                                `/scheduledClasses/Details?scheduleID=${item.ScheduleClassID}`
                                            );
                                        }}>
                                        <td>{item.BatchName}</td>
                                        <td>{item.StudentName}</td>
                                        <td>{item.Review}</td>
                                        <td>{item.Rating}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={4}
                                        style={{ textAlign: "center" }}>
                                        No Reviews Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}

export default Review;
