import React, { useState } from "react";

import DashboardClasses from "../dashboard.module.css";
import Sales from "./Sales";
import Cancellation from "./Cancellation";
import Complaints from "./Complains";
import Balances from "./Balances";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
    AreaChart,
    Area,
    XAxis,
    Bar,
    BarChart,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

const data3 = [
    { month: "Feb 2023", "Lesson Taken": 4000 },
    { month: "Mar 2023", "Lesson Taken": 3000 },
    { month: "Apr 2023", "Lesson Taken": 2000 },
    { month: "May 2023", "Lesson Taken": 2780 },
    { month: "Jun 2023", "Lesson Taken": 1890 },
    { month: "Jul 2023", "Lesson Taken": 2390 },
    { month: "Aug 2023", "Lesson Taken": 3490 },
    { month: "Sep 2023", "Lesson Taken": 4000 },
    { month: "Oct 2023", "Lesson Taken": 3000 },
    { month: "Nov 2023", "Lesson Taken": 2000 },
    { month: "Dec 2023", "Lesson Taken": 2780 },
    { month: "Jan 2024", "Lesson Taken": 1890 },
];
const data = [
    { month: "Feb 2023", "Batches Created": 4000 },
    { month: "Mar 2023", "Batches Created": 3000 },
    { month: "Apr 2023", "Batches Created": 2000 },
    { month: "May 2023", "Batches Created": 2780 },
    { month: "Jun 2023", "Batches Created": 1890 },
    { month: "Jul 2023", "Batches Created": 2390 },
    { month: "Aug 2023", "Batches Created": 3490 },
    { month: "Sep 2023", "Batches Created": 4000 },
    { month: "Oct 2023", "Batches Created": 3000 },
    { month: "Nov 2023", "Batches Created": 2000 },
    { month: "Dec 2023", "Batches Created": 2780 },
    { month: "Jan 2024", "Batches Created": 1890 },
];
function Admin() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <div className={DashboardClasses["admin_Container"]}>
            <Sales />
            <Cancellation />
            <Complaints />
            <Balances />
            <div
                className={DashboardClasses["chart"]}
                style={{ gridColumn: "1/-1", maxHeight: "450px" }}>
                <h3>Lesson Hours </h3>
                <div className={DashboardClasses["date-picker"]}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        maxDate={new Date()}
                        dateFormat={"yyyy"}
                        showYearPicker
                        className={DashboardClasses["date-picker-container"]}
                    />
                </div>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={data3}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey="Lesson Taken"
                            fill="#355ba1"
                            barSize={40}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div
                className={DashboardClasses["chart"]}
                style={{ gridColumn: "1/-1", maxHeight: "450px" }}>
                <h3>Batches </h3>
                <div className={DashboardClasses["date-picker"]}>
                    <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        maxDate={new Date()}
                        dateFormat={"yyyy"}
                        showYearPicker
                        className={DashboardClasses["date-picker-container"]}
                    />
                </div>
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart
                        data={data}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar
                            dataKey="Batches Created"
                            fill="#355ba1"
                            barSize={40}
                        />
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}

export default Admin;
