import React, { useState, useEffect, useRef } from "react";
import detailsClasses from "./details.module.css";
import { AiFillEdit } from "react-icons/ai";
import { Services } from "../../../Services";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../Components/Layout";
import Mainloader from "../../../Components/loader/loader";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import swal from "sweetalert";
import { Error, Success, SuccessSwal } from "../../../Util/Toast";
import { toast } from "react-toastify";
import { AccessControl } from "Util/AccessControl";

import { AccessKeys } from "Routes/config";
function Details() {
    const Navigate = useNavigate();
    const DemoRef = useRef();
    const adminData = JSON.parse(localStorage.getItem("admindata"));
    const [params] = useSearchParams();
    const [Tutors, setTutors] = useState([]);
    const [loading, setLoading] = useState(true);
    let [data, setData] = useState({});

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const backButton = () => {
        Navigate(-1);
    };
    function getEnrolledDetails() {
        Services.enrolled_List_Details("GET", null, token, params.get("id"))
            .then((response) => {
                if (response.Status === 1) {
                    setData(response.CourseLesson);

                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                }
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
                alert("something went wrong try again");
            });
    }
    const FetchDropDowns = async () => {
        try {
            const [response1] = await Promise.all([
                Services.GetTutors(
                    "POST",
                    JSON.stringify({ TutorStatus: 1 }),
                    token
                ),
            ]);

            setTutors(response1.Tutors);
        } catch (error) {
            alert("something went wrong please try again");
            console.error("Error fetching data:", error);
        }
    };
    useEffect(() => {
        getEnrolledDetails();
        FetchDropDowns();
        // eslint-disable-next-line
    }, []);

    const claimHandler = (e) => {
        let EnrollCourseID = data.EnrollCourseID;
        let body = { IsClaimed: 0, ClaimedBy: 0 };
        Services.Claim_Enroll_Course(
            "PUT",
            JSON.stringify(body),
            token,
            EnrollCourseID
        )
            .then((res) => {
                if (res.Status === 1) {
                    SuccessSwal("Success", "Claim Status Reset successful");
                    getEnrolledDetails();
                }
            })
            .catch((err) => {
                console.log(err);
                Error("something went Wrong Please try again");
            });
    };
    const overRideHandler = (e) => {
        let message = "Are you Sure You Want to Reset Claim Status?";

        swal(message, {
            icon: "warning",
            // className: detailsClass["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Confirm",
                    value: "Logout",
                    className: detailsClasses["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    claimHandler();
                    break;
            }
        });
    };
    const editHandler = () => {
        sessionStorage.setItem("enrolled", JSON.stringify(data));
        Navigate(`/edit-enroll/${data.EnrollCourseID}`);
    };
    const changeHandler = (e) => {
        Services.updateEnrolledCourse(
            "PUT",
            JSON.stringify({
                DemoTakenBy: e.target.value,
            }),
            token,
            params.get("id")
        )
            .then((response) => {
                if (response.Status === 1) {
                    setData((prev) => ({
                        ...prev,
                        DemoTakenBy: e.target.value,
                    }));
                    SuccessSwal("Success", " Updated Successfully");
                    // toast.success(`${response.Message}`, {
                    //     position: "top-center",
                    //     autoClose: 1000,
                    //     hideProgressBar: true,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                } else if (response.Status === 0) {
                    setLoading(false);
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Enrolled Course Details</title>
            </Helmet>
            <Layout Active={"Enrolled-list"}>
                <div className={"scheduledClasses"}></div>
                {loading ? (
                    <Mainloader />
                ) : (
                    <div className={detailsClasses["container"]}>
                        <button
                            onClick={backButton}
                            className={detailsClasses["back"]}>
                            Back
                        </button>
                        <div className={detailsClasses["wrapper"]}>
                            <div className={detailsClasses["header-wrapper"]}>
                                <div className={detailsClasses["header"]}>
                                    <h3>Enrolled Course Details</h3>
                                    {adminData.Type == 1 &&
                                        data.ClaimedBy != "NA" && (
                                            <button onClick={overRideHandler}>
                                                Reset Claim Status
                                            </button>
                                        )}
                                </div>

                                <div className={detailsClasses["action-btns"]}>
                                    {data.DemoTakenBy == 0 && (
                                        <select
                                            ref={DemoRef}
                                            defaultValue={data.DemoTakenBy}
                                            onChange={changeHandler}>
                                            <option value={0}>
                                                Demo Taken By
                                            </option>
                                            {Tutors?.map((item) => (
                                                <option value={item.TutorID}>
                                                    {item.TutorName}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                    {AccessControl(
                                        AccessKeys["Student Registrations"]
                                    ).edit == 1 &&
                                        data.IsAddedToBatch === 0 && (
                                            <button onClick={editHandler}>
                                                <AiFillEdit /> Edit
                                            </button>
                                        )}
                                </div>
                            </div>
                            <div className={detailsClasses["profile"]}>
                                <div
                                    className={detailsClasses["internal"]}
                                    style={{ borderRight: "1px solid #ddd" }}>
                                    <div>
                                        <h3>Course</h3>
                                        <span>:</span>
                                        <p>{data.CourseName}</p>
                                    </div>
                                    <div>
                                        <h3>Batch Size</h3>
                                        <span>:</span>
                                        <p>{data.BatchSize}</p>
                                    </div>

                                    <div>
                                        <h3>ClaimedBy</h3>
                                        <span>:</span>
                                        <p>{data.ClaimedByName} </p>
                                    </div>

                                    <div>
                                        <h3>Enrolled Date</h3>
                                        <span>:</span>
                                        <p>
                                            <Moment format="DD-MM-YYYY">
                                                {new Date(data.CreatedAt)}
                                            </Moment>
                                        </p>
                                    </div>
                                </div>
                                <div className={detailsClasses["internal"]}>
                                    <div>
                                        <h3>Preferred Times</h3>
                                        <span>:</span>
                                        <p>
                                            {data?.PreferredStartTimes?.length >
                                                0 &&
                                                data?.PreferredStartTimes?.join(
                                                    ","
                                                )}
                                        </p>
                                    </div>
                                    <div>
                                        <h3>Preferred Days</h3>
                                        <span>:</span>
                                        <p>
                                            {data.PreferredDays &&
                                                data.PreferredDays}
                                        </p>
                                    </div>
                                    {data.DemoTakenBy !== 0 && (
                                        <div>
                                            <h3>Demo Taken By</h3>
                                            <span>:</span>
                                            <p>{data.DemoTakenByName}</p>
                                        </div>
                                    )}
                                    <div>
                                        <h3>Payment Date</h3>
                                        <span>:</span>
                                        <p>
                                            {String(data.PaymentDateTime).slice(
                                                0,
                                                10
                                            )}
                                            ,
                                            {String(data.PaymentDateTime).slice(
                                                11,
                                                16
                                            )}
                                        </p>
                                    </div>
                                    {adminData?.Type === 1 && (
                                        <div>
                                            <h3>Postal Address</h3>
                                            <span>:</span>
                                            <p>
                                                {data.Address},{data.City}{" "}
                                                <br />
                                                {data.Country} ,{data.Pincode}{" "}
                                                <br />
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={detailsClasses["Table"]}>
                                <h3>Student Details</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Student Name </th>
                                            <th>School Name</th>
                                            <th>Parent Details</th>
                                            <th>Sessions Purchased</th>
                                            <th>Total Sessions</th>
                                            <th>Amount Paid</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            onClick={() =>
                                                Navigate(
                                                    `/Students/details?id=${data.StudentID}`
                                                )
                                            }>
                                            <td>
                                                {data.StudentName}(
                                                {data.StudentAge} Years)
                                            </td>
                                            <td>{data.SchoolName}</td>
                                            <td>
                                                {data.ParentName}
                                                <br /> {data.MobileNumber}{" "}
                                                <br /> {data.EmailID}
                                            </td>
                                            <td>{data.SessionsPurchased}</td>
                                            <td>{data.TotalSessions}</td>
                                            <td> ₹ {data.PricePaid}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                )}
            </Layout>
        </>
    );
}

export default Details;
