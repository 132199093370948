import React, { useState, useEffect } from "react";
import { Services } from "../../../../../Services";
import LinkClass from "./links.module.css";
import Skeleton from "./skeleton";
import { useSearchParams } from "react-router-dom";
import Moment from "react-moment";
// import PaymentGenerate from "../generatePaymentLink/generate";
import useStore from "../../../../../store";
import detailsClass from "../index.module.css";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
function Transactions() {
    const [params] = useSearchParams();
    const [Loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);

    const [forcepage, setforcepage] = useState(0);
    const RecordCount = useStore((item) => item.NoOfRecords);
    const [transaction, setTransactions] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [batchType, setBatchType] = useState("Current");
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    useEffect(() => {
        getAllBarches(0, batchType);
    }, []);
    async function getAllBarches(skip, batchType) {
        try {
            const Transactions = await Services.getBatchByTutor(
                "POST",
                JSON.stringify({
                    TutorID: params.get("TutorId"),
                    BatchType: batchType,
                    toSkip: skip,
                }),
                token
            );
            setTimeout(() => {
                setLoading(false);
            }, 500);
            if (Transactions.Status === 1) {
                setTransactions(Transactions.Batches);
                if (
                    Transactions.Batches.length == 0 ||
                    Transactions.Count <= RecordCount
                ) {
                    setShowPagiate(false);
                } else {
                    if (!showPaginate) {
                        setShowPagiate(true);
                    }
                    setpageCount(
                        Math.ceil(
                            Number(Transactions?.Count) / Number(RecordCount)
                        )
                    );
                }
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            alert("Something went wrong try again");
        }
    }
    const handlePageChange = (selectedObject) => {
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        getAllBarches(selectedObject.selected * RecordCount, batchType);
        setLoading(true);
    };
    const tabHandler = (type) => {
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(0);
        setBatchType(type);
        getAllBarches(0, type);

        setLoading(true);
    };
    return (
        <div className={LinkClass["Container"]}>
            <div className={detailsClass["control-btns"]}>
                <button
                    className={`${detailsClass["button"]} ${
                        batchType == "Current" ? detailsClass["btn-active"] : ""
                    }`}
                    btn-type="Current"
                    onClick={() => {
                        tabHandler("Current");
                    }}>
                    Current
                </button>
                <button
                    className={`${detailsClass["button"]} ${
                        batchType == "Past" ? detailsClass["btn-active"] : ""
                    }`}
                    btn-type="Past"
                    onClick={() => {
                        tabHandler("Past");
                    }}>
                    Past
                </button>
            </div>
            {/* <header>
                <h3>Batches</h3>
            </header> */}
            {Loading ? (
                <Skeleton />
            ) : (
                <>
                    <table>
                        <thead>
                            <tr>
                                <th>Batch Name</th>
                                <th>Batch Size</th>
                                <th>Batch Status</th>
                                <th>Payment Status</th>
                                <th>Start Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transaction.length > 0 ? (
                                transaction?.map((item) => (
                                    <tr>
                                        <td>
                                            <NavLink
                                                to={`/Batches/Details?BatchId=${item.BatchID}`}>
                                                {item?.BatchName}
                                            </NavLink>
                                        </td>
                                        <td>{item?.BatchSize}</td>
                                        <td>
                                            {item?.Status == 1 &&
                                                item?.IsTutorAccepted == 0 &&
                                                "Need Approval"}
                                            {item?.Status == 1 &&
                                                item?.IsTutorAccepted == 1 &&
                                                "Ongoing"}
                                            {item?.Status == 1 &&
                                                item?.IsTutorAccepted == 2 &&
                                                "Rejected Batch"}
                                            {item?.Status == 3 && "Finished"}
                                            {item?.Status == 4 && "Gone Cold"}
                                            {item?.Status == 5 &&
                                                "Batch Dissolved"}
                                            {item?.Status == 6 &&
                                                "Parent Discontinued"}
                                        </td>
                                        <td>{item?.CourseName}</td>
                                        <td>
                                            <Moment format="DD-MM-YYYY">
                                                {new Date(item.StartDate)}
                                            </Moment>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td
                                        colSpan={5}
                                        style={{ textAlign: "center" }}>
                                        No Record Found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </>
            )}
            {showPaginate && (
                <div
                    style={{
                        justifySelf: "right",
                    }}>
                    <ReactPaginate
                        pageCount={pageCount}
                        // pageRange={2}
                        forcePage={forcepage}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        containerClassName={"pagination"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </div>
    );
}

export default Transactions;
