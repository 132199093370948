import React, { useState, useEffect } from "react";
import Layout from "../../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import S3FileUpload from "react-s3";
import { config } from "../../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../../Services";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import MainLoader from "../../../../Components/loader/loader";
import { BsFiletypePpt } from "react-icons/bs";
import { AiFillFilePdf } from "react-icons/ai";
import Swal from "sweetalert";
import { AiFillDelete } from "react-icons/ai";
import Select from "react-select";
import { compareObjects, CheckIsObjectEmpty } from "Util/Util";
import { NullCheck, ValidateInput } from "../../../../Components/validators";
import { SuccessSwal, warningSwal } from "../../../../Util/Toast";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [lessonPlanDetails, setLessonPlanDetails] = useState();
    const [edit, setEdit] = useState(false);
    const [mainLoad, setMainLoader] = useState(false);
    const [SongSearchOption, setSongSearchOptions] = useState([]);
    const [songSearhLoad, setSongSearchLoad] = useState(false);
    const [songSearchString, setSongsearchString] = useState(null);
    const [selectedOPtion, setSelectedOptions] = useState(null);
    const [alloptions, setAlloptions] = useState({
        song: [],
        story: [],
        Game: [],
    });
    const [StorySearchOption, setStorySearchOptions] = useState([]);
    const [StorySearhLoad, setStorySearchLoad] = useState(false);
    const [StorySearchString, setStorysearchString] = useState(null);
    const [selectedStoryOPtion, setSelectedStoryOptions] = useState(null);

    const [GameSearchOption, setGameSearchOptions] = useState([]);
    const [GameSearhLoad, setGameSearchLoad] = useState(false);
    const [GameSearchString, setGamesearchString] = useState(null);
    const [selectedGameOPtion, setSelectedGameOptions] = useState(null);

    const [referOptions, setreferOptions] = useState([]);
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            minHeight: state.hasValue ? "3.3rem" : "3rem", // Adjust the height based on your preference
        }),
    };
    const [lessonppt, setlessonppt] = useState("");
    const [HomeWork, setHomeWork] = useState("");
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        control,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const formData = watch();
    useEffect(() => {
        if (params.get("planid")) {
            setEdit(true);
            setMainLoader(true);
            Services.GET_lessonplanDetails(
                "GET",
                null,
                token,
                params.get("planid")
            )
                .then((response) => {
                    setMainLoader(false);
                    if (response.Status === 1) {
                        setLessonPlanDetails({
                            ...response.LessonPlans[0],
                            StoryURLs: response.LessonPlans[0].StoryURLs.map(
                                (resource) => resource.ResourceID
                            ),
                            SongURLs: response.LessonPlans[0].SongURLs.map(
                                (resource) => resource.ResourceID
                            ),
                            GamesURL: response.LessonPlans[0].GamesURL.map(
                                (resource) => resource.ResourceID
                            ),
                        });
                        if (response.LessonPlans.length > 0) {
                            reset({
                                PlanNumber: response.LessonPlans[0].PlanNumber,
                                OneDrivelink:
                                    response.LessonPlans[0].OneDrivelink,
                                Topic: response.LessonPlans[0].Topic,
                                PPT_Type: response.LessonPlans[0].PPT_Type,
                                SongurlLink:
                                    response.LessonPlans[0].SongurlLink,
                            });
                            setSelectedStoryOptions(
                                response.LessonPlans[0].StoryURLs.map(
                                    (resource) => ({
                                        label: resource.ResourceName,
                                        value: resource.ResourceID,
                                    })
                                )
                            );
                            setSelectedOptions(
                                response.LessonPlans[0].SongURLs.map(
                                    (resource) => ({
                                        label: resource.ResourceName,
                                        value: resource.ResourceID,
                                    })
                                )
                            );
                            setSelectedGameOptions(
                                response.LessonPlans[0].GamesURL.map(
                                    (resource) => ({
                                        label: resource.ResourceName,
                                        value: resource.ResourceID,
                                    })
                                )
                            );
                            setAlloptions({
                                song: response.LessonPlans[0].SongURLs,
                                story: response.LessonPlans[0].StoryURLs,
                                Game: response.LessonPlans[0].GamesURL,
                            });

                            setlessonppt(response.LessonPlans[0].OneDrivelink);
                            if (
                                response.LessonPlans[0].HomeworkURL != "NA" &&
                                response?.LessonPlans[0]?.HomeworkURL?.length >
                                    0
                            ) {
                                setHomeWork(
                                    response.LessonPlans[0].HomeworkURL
                                );
                            }
                        }

                        setTimeout(() => {
                            setMainLoader(false);
                        }, 300);
                    } else if (response.Status === 0) {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    setMainLoader(false);
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            setValue(
                "PlanNumber",
                localStorage.getItem("count")
                    ? Number(localStorage.getItem("count")) + 1
                    : 1
            );
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (songSearchString) {
                setSongSearchLoad(true);

                try {
                    const data = await Services.ResourceSearch(
                        "POST",
                        JSON.stringify({
                            ResourceName: songSearchString,
                            ResourceType: 1,
                        }),
                        token
                    );
                    setreferOptions((prev) => [...prev, ...data.Resources]);
                    const formattedOptions = data.Resources.map((resource) => ({
                        label: resource.ResourceName,
                        value: resource.ResourceID,
                    }));
                    setSongSearchOptions(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setSongSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
        // eslint-disable-next-line
    }, [songSearchString]);
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (StorySearchString) {
                setStorySearchLoad(true);

                try {
                    const data = await Services.ResourceSearch(
                        "POST",
                        JSON.stringify({
                            ResourceName: StorySearchString,
                            ResourceType: 2,
                        }),
                        token
                    );
                    setreferOptions((prev) => [...prev, ...data.Resources]);
                    const formattedOptions = data.Resources.map((resource) => ({
                        label: resource.ResourceName,
                        value: resource.ResourceID,
                    }));
                    setStorySearchOptions(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setStorySearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
        // eslint-disable-next-line
    }, [StorySearchString]);
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (GameSearchString) {
                setGameSearchLoad(true);

                try {
                    const data = await Services.ResourceSearch(
                        "POST",
                        JSON.stringify({
                            ResourceName: GameSearchString,
                            ResourceType: 3,
                        }),
                        token
                    );
                    setreferOptions((prev) => [...prev, ...data.Resources]);
                    const formattedOptions = data.Resources.map((resource) => ({
                        label: resource.ResourceName,
                        value: resource.ResourceID,
                    }));
                    setGameSearchOptions(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setGameSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
        // eslint-disable-next-line
    }, [GameSearchString]);

    function filehandleChange(files) {
        setMainLoader(true);
        if (
            files.type ===
                "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
            files.type === "application/vnd.ms-powerpoint"
        ) {
            S3FileUpload.uploadFile(files, {
                ...config,
                dirName: "LessonPlans",
            })
                .then((res) => {
                    setMainLoader(false);
                    setlessonppt(res.location);
                    setValue("LessonpptLink", `${files.name}`);
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                    setMainLoader(false);
                });
        } else {
            setMainLoader(false);
            alert(`${files.name} is neither PPT NOR PPTX`);
            return false;
        }
        return true;
    }
    function filehandleChangePDF(files, type) {
        console.log(files);
        setMainLoader(true);
        if (files.type === "application/pdf") {
            const timestamp = Date.now();

            const newfile = new File([files], `${timestamp}.PDF`);

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "LessonPlans",
            })
                .then((res) => {
                    setMainLoader(false);
                    if (type === 1) {
                        setValue("HomeworkURL", `${timestamp}.PDF`);
                        setHomeWork(res.location);
                    }
                })
                .catch((err) => {
                    alert(err);
                    setMainLoader(false);
                    console.log(err);
                });
        } else {
            setMainLoader(false);
            console.log();
            alert(`${files.name} is not a PDF file`);
            return false;
        }
        return true;
    }

    const onSubmit = (data) => {
        // setLoading(true);

        let SongurlLink = [];
        let Storyurl = [];
        let gameurl = [];
        if (selectedOPtion) {
            SongurlLink = selectedOPtion.map((item) => item.value);
        }
        if (selectedStoryOPtion) {
            Storyurl = selectedStoryOPtion.map((item) => item.value);
        }
        if (selectedGameOPtion) {
            gameurl = selectedGameOPtion.map((item) => item.value);
        }
        if (lessonppt.length === 0) {
            Swal({
                icon: "error",
                title: "Lesson PPT is Required",
            });
            return;
        }
        if (edit) {
            delete data.SongurlLink;
            delete data.StoryURLs;
            delete data.GamesURL;
            delete data.SongURLs;
            let updatedBody = compareObjects(
                {
                    ...data,
                    SongURLs: SongurlLink,
                    StoryURLs: Storyurl,
                    GamesURL: gameurl,
                },
                lessonPlanDetails
            );
            if (CheckIsObjectEmpty(updatedBody)) {
                warningSwal("Warning", "Nothing To update");
                return;
            }

            setLoading(true);
            Services.updateLesson(
                "PUT",
                JSON.stringify({
                    ...data,
                    CourseID: params.get("id"),
                    ...updatedBody,
                }),
                token,
                params.get("planid")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Lesson plan updated", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            setLoading(true);
            delete data.SongurlLink;
            delete data.StoryURLs;
            delete data.GamesURL;
            delete data.SongURLs;
            Services.add_Lesson_plan(
                "POST",
                JSON.stringify({
                    ...data,
                    GamesURL: gameurl,
                    CourseID: params.get("id"),
                    StoryURLs: Storyurl,
                    SongURLs: SongurlLink,
                }),
                token
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Lesson plan Added", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.PPT will be accepted)</p>
        </div>
    );
    const fileuploadDesignPDF = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.PDF will be accepted)</p>
        </div>
    );

    const backButton = () => {
        Navigate(-1);
    };
    const onSongSelect = (option) => {
        setSelectedOptions(option);
        let matchingObjects = referOptions.filter((obj1) =>
            option.some((obj2) => obj1.ResourceID === obj2.value)
        );
        setAlloptions((prev) => ({
            ...prev,
            song: Array.from(
                new Set(matchingObjects.map(JSON.stringify)),
                JSON.parse
            ),
        }));
    };
    const onGameSelect = (option) => {
        setSelectedGameOptions(option);
        let matchingObjects = referOptions.filter((obj1) =>
            option.some((obj2) => obj1.ResourceID === obj2.value)
        );
        setAlloptions((prev) => ({
            ...prev,
            Game: Array.from(
                new Set(matchingObjects.map(JSON.stringify)),
                JSON.parse
            ),
        }));
    };
    const onsongsearch = (e) => {
        setSongsearchString(e);
    };
    const onGamesearch = (e) => {
        setGamesearchString(e);
    };
    const onStorySelect = (option) => {
        setSelectedStoryOptions(option);
        let matchingObjects = referOptions.filter((obj1) =>
            option.some((obj2) => obj1.ResourceID === obj2.value)
        );
        setAlloptions((prev) => ({
            ...prev,
            story: Array.from(
                new Set(matchingObjects.map(JSON.stringify)),
                JSON.parse
            ),
        }));
    };
    const onStorysearch = (e) => {
        setStorysearchString(e);
    };
    const DeleteHandler = (e) => {
        const type = e.currentTarget.getAttribute("btn-type");
        const id = e.currentTarget.getAttribute("ResourceID");
        if (type == 1) {
            setSelectedOptions((prev) =>
                prev.filter((item) => item.value != id)
            );
            setAlloptions((prev) => ({
                ...prev,
                song: prev.song.filter((item) => item.ResourceID != id),
            }));
        }
        if (type == 2) {
            setSelectedStoryOptions((prev) =>
                prev.filter((item) => item.value != id)
            );
            setAlloptions((prev) => ({
                ...prev,
                story: prev.story.filter((item) => item.ResourceID != id),
            }));
        }
        if (type == 3) {
            setSelectedGameOptions((prev) =>
                prev.filter((item) => item.value != id)
            );
            setAlloptions((prev) => ({
                ...prev,
                Game: prev.Game.filter((item) => item.ResourceID != id),
            }));
        }
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    console.log(formData?.PPT_Type);
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Lesson Plan</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add  Lesson Plan" : "Update Lesson Plan"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="PlanNumber">
                                Lesson Plan Number
                            </label>
                            <input
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                disabled
                                {...register("PlanNumber", {
                                    required: "this field is required",
                                    validate: (val) =>
                                        ValidateInput(val, "Invalid Number"),
                                })}
                            />
                            {errors.PlanNumber && (
                                <span>{errors?.PlanNumber.message}</span>
                            )}
                        </div>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="BatchSize">Topic</label>
                            <input
                                {...register("Topic", {
                                    required: true,
                                    validate: NullCheck,
                                })}
                            />
                            {errors.Topic && (
                                <span>This field is required</span>
                            )}
                        </div>

                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="MinStudents">Song Link</label>
                            <Controller
                                name="SongURLs"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={SongSearchOption}
                                            onInputChange={onsongsearch}
                                            onChange={onSongSelect}
                                            value={selectedOPtion}
                                            styles={customStyles}
                                            isLoading={songSearhLoad}
                                            noOptionsMessage={() =>
                                                "No Resource Found"
                                            }
                                            isSearchable={true}
                                            placeholder="Search Song"
                                            isMulti={true}
                                            closeMenuOnSelect={true}
                                            className={TutorClasses["select"]}
                                            classNames={{
                                                control: (state) =>
                                                    TutorClasses["control"],
                                                indicatorSeparator: () =>
                                                    TutorClasses["indicator"],
                                                valueContainer: () =>
                                                    TutorClasses[
                                                        "valueContainer"
                                                    ],
                                                input: () =>
                                                    TutorClasses["input"],
                                                indicatorsContainer: () =>
                                                    TutorClasses[
                                                        "indicationContainer"
                                                    ],
                                                singleValue: () =>
                                                    TutorClasses["singleValue"],
                                                option: () =>
                                                    TutorClasses["option"],
                                            }}
                                        />
                                        {errors.SongurlLink && (
                                            <p
                                                className={
                                                    TutorClasses["error-msg"]
                                                }>
                                                This is required
                                            </p>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="MinStudents">Story Url</label>
                            <Controller
                                name="StoryURLs"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={StorySearchOption}
                                            onInputChange={onStorysearch}
                                            onChange={onStorySelect}
                                            value={selectedStoryOPtion}
                                            styles={customStyles}
                                            isLoading={StorySearhLoad}
                                            noOptionsMessage={() =>
                                                "No Resource Found"
                                            }
                                            isSearchable={true}
                                            placeholder="Search story"
                                            isMulti={true}
                                            closeMenuOnSelect={true}
                                            className={TutorClasses["select"]}
                                            classNames={{
                                                control: (state) =>
                                                    TutorClasses["control"],
                                                indicatorSeparator: () =>
                                                    TutorClasses["indicator"],
                                                valueContainer: () =>
                                                    TutorClasses[
                                                        "valueContainer"
                                                    ],
                                                input: () =>
                                                    TutorClasses["input"],
                                                indicatorsContainer: () =>
                                                    TutorClasses[
                                                        "indicationContainer"
                                                    ],
                                                singleValue: () =>
                                                    TutorClasses["singleValue"],
                                                option: () =>
                                                    TutorClasses["option"],
                                            }}
                                        />
                                        {errors.StoryURLs && (
                                            <p
                                                className={
                                                    TutorClasses["error-msg"]
                                                }>
                                                This is required
                                            </p>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="MinStudents">Games Url</label>
                            <Controller
                                name="StoryURLs"
                                control={control}
                                defaultValue={""}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={GameSearchOption}
                                            onInputChange={onGamesearch}
                                            onChange={onGameSelect}
                                            value={selectedGameOPtion}
                                            styles={customStyles}
                                            isLoading={GameSearhLoad}
                                            noOptionsMessage={() =>
                                                "No Resource Found"
                                            }
                                            isSearchable={true}
                                            placeholder="Search Game"
                                            isMulti={true}
                                            closeMenuOnSelect={true}
                                            className={TutorClasses["select"]}
                                            classNames={{
                                                control: (state) =>
                                                    TutorClasses["control"],
                                                indicatorSeparator: () =>
                                                    TutorClasses["indicator"],
                                                valueContainer: () =>
                                                    TutorClasses[
                                                        "valueContainer"
                                                    ],
                                                input: () =>
                                                    TutorClasses["input"],
                                                indicatorsContainer: () =>
                                                    TutorClasses[
                                                        "indicationContainer"
                                                    ],
                                                singleValue: () =>
                                                    TutorClasses["singleValue"],
                                                option: () =>
                                                    TutorClasses["option"],
                                            }}
                                        />
                                        {errors.GamesURL && (
                                            <p
                                                className={
                                                    TutorClasses["error-msg"]
                                                }>
                                                This is required
                                            </p>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                        {params.get("planid") && (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="PlanNumber">PPT TYPE</label>
                                <select
                                    {...register("PPT_Type", {
                                        required: "this field is required",
                                    })}>
                                    <option value="1">URL</option>
                                    <option value="2">File Upload</option>
                                </select>
                                {errors.PPT_Type && (
                                    <span>{errors?.PPT_Type.message}</span>
                                )}
                            </div>
                        )}
                        {(alloptions.song.length > 0 ||
                            alloptions?.story.length > 0 ||
                            alloptions?.Game.length > 0) && (
                            <div className={TutorClasses["Table"]}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Resource Type</th>
                                            <th>Resource Name</th>
                                            <th>Resource Link</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {alloptions.song?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.ResourceType === 1
                                                            ? "Song"
                                                            : item.ResourceType ===
                                                              2
                                                            ? "Story"
                                                            : "Game"}
                                                    </td>
                                                    <td>{item.ResourceName}</td>
                                                    <td>
                                                        <a
                                                            href={
                                                                item.ResourceUrl
                                                            }
                                                            target="_blank"
                                                            rel="norefferer">
                                                            Link
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            btn-type={
                                                                item.ResourceType
                                                            }
                                                            ResourceID={
                                                                item.ResourceID
                                                            }
                                                            onClick={
                                                                DeleteHandler
                                                            }
                                                            className={
                                                                TutorClasses[
                                                                    "delete-resource"
                                                                ]
                                                            }>
                                                            <AiFillDelete />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {alloptions.story?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.ResourceType === 1
                                                            ? "Song"
                                                            : item.ResourceType ===
                                                              2
                                                            ? "Story"
                                                            : "Game"}
                                                    </td>
                                                    <td>{item.ResourceName}</td>
                                                    <td>
                                                        <a
                                                            href={
                                                                item.ResourceUrl
                                                            }
                                                            target="_blank"
                                                            rel="norefferer">
                                                            Link
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            btn-type={
                                                                item.ResourceType
                                                            }
                                                            ResourceID={
                                                                item.ResourceID
                                                            }
                                                            onClick={
                                                                DeleteHandler
                                                            }
                                                            className={
                                                                TutorClasses[
                                                                    "delete-resource"
                                                                ]
                                                            }>
                                                            <AiFillDelete />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                        {alloptions.Game?.map((item) => {
                                            return (
                                                <tr>
                                                    <td>
                                                        {item.ResourceType === 1
                                                            ? "Song"
                                                            : item.ResourceType ===
                                                              2
                                                            ? "Story"
                                                            : "Game"}
                                                    </td>
                                                    <td>{item.ResourceName}</td>
                                                    <td>
                                                        <a
                                                            href={
                                                                item.ResourceUrl
                                                            }
                                                            target="_blank"
                                                            rel="norefferer">
                                                            Link
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <button
                                                            type="button"
                                                            btn-type={
                                                                item.ResourceType
                                                            }
                                                            ResourceID={
                                                                item.ResourceID
                                                            }
                                                            onClick={
                                                                DeleteHandler
                                                            }
                                                            className={
                                                                TutorClasses[
                                                                    "delete-resource"
                                                                ]
                                                            }>
                                                            <AiFillDelete />
                                                        </button>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        )}
                        {formData?.PPT_Type == "1" ? (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="BatchSize">
                                    Lesson PPT Link
                                </label>
                                <input
                                    {...register("OneDrivelink", {
                                        required: false,
                                    })}
                                />
                                {errors.OneDrivelink && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        ) : (
                            <div
                                className={TutorClasses["file-upload-wrapper"]}>
                                <header>
                                    <h3>Lesson Plan PPT</h3>
                                </header>
                                <FileUploader
                                    classes={TutorClasses["upload-image"]}
                                    multiple={false}
                                    handleChange={filehandleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            </div>
                        )}
                        {lessonppt && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                <div className={TutorClasses["ppt-button"]}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(lessonppt, "_blank");
                                        }}>
                                        <BsFiletypePpt size={39} />
                                    </button>
                                </div>
                            </div>
                        )}

                        <div className={TutorClasses["file-upload-wrapper"]}>
                            <header>
                                <h3>Home work </h3>
                            </header>
                            <FileUploader
                                classes={TutorClasses["upload-image"]}
                                multiple={false}
                                handleChange={(e) => filehandleChangePDF(e, 1)}
                                name="file"
                                children={fileuploadDesignPDF}
                                // types={fileTypes}
                            />
                        </div>

                        {HomeWork && (
                            <div
                                className={TutorClasses["file-images-wrapper"]}>
                                <div className={TutorClasses["ppt-button"]}>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(HomeWork, "_blank");
                                        }}>
                                        <AiFillFilePdf size={39} />
                                    </button>
                                </div>
                            </div>
                        )}

                        <button disabled={loading} type="submit">
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
