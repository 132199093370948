import { ValidateInput } from "Components/validators"
import * as yup from "yup";


//Non Zero Validator 

const notZeroValidator = (fieldName) => (
  yup.number()
    .required(`${fieldName} is required`)
    .typeError(`${fieldName} must be a number`)
    .test('not-zero', `${fieldName} Cannot be zero`, value => value !== 0).test('greater-than-zero', `${fieldName} must be greater than zero`, value => value > 0)
);
const ZeroValidator = (fieldName) => (
  yup.number()
    .required(`${fieldName} is required`)
    .typeError(`${fieldName} must be a number`)
    .test('greater-than-zero', `${fieldName} must be greater than zero`, value => value >= 0)
);


// Course Schema 

export const Courseschema = yup.object().shape({
  CourseName: yup
    .string()
    .matches(
      /^(?! +$)[A-Za-z0-9 -]+$/,
      "Name can only contain letters,numbers and hyphens"
    )
    .required("Course Name is required"),
  FromAge: notZeroValidator('From age'),
  ToAge: notZeroValidator('To age')
    .min(yup.ref('FromAge')),
  MinStudents: notZeroValidator('Min Students'),
  MaxStudents: notZeroValidator('Max Students').min(yup.ref('MinStudents'), 'Max Students should be greater than min students'),
  ClassPerWeek: notZeroValidator('Class Per Week'),
  One_One_Duration: ZeroValidator('One on One Duration'),
  One_One_Price: ZeroValidator('One on One Price'),
  Group_Duration: ZeroValidator('Group Duration'),
  Group_Price: ZeroValidator('Group Price'),
  NoOfSessions: notZeroValidator('No of Sessions'),
  MinSessionsToPurchase: notZeroValidator('Min Sessions To Purchase').max(yup.ref('NoOfSessions'), ' should be less than no of Sessions'),
  Language: yup
    .string().required(`${"language is required"}`)
    .matches(
      /^(?! +$)[A-Za-z]+$/,
      "Language can only contain letters"
    ),
  Overview: yup
    .string().required(`${"Overview is required"}`),

  What_You_Learn: yup
    .string().required(`${"What you learn is required"}`)
  ,

});



// Validate Age signle input
export const AgeValidator = (Data) => {
  ValidateInput(Data, 'Invalid Age ')
}