import React, { useRef, useState } from "react";
import Modal from "react-modal";
import Styles from "./index.module.css";
import { Services } from "../../Services";
import ReactLoading from "react-loading";
import { warningSwal, SuccessSwal } from "../../Util/Toast";
Modal.setAppElement("#root");
function ComplaintModal({ modal, setModal, getDetails, getAllCategories }) {
    const inputRef = useRef();
    const [loading, setLoading] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const closeModal = () => {
        setModal(false);
    };
    const resolveHanlder = () => {
        setLoading(true);
        Services.updateComplaint(
            "PUT",
            JSON.stringify({
                ResolvedComments: inputRef.current.value,
                Status: 1,
            }),
            token,
            getDetails.ComplaintID
        )
            .then((response) => {
                if (response.Status === 1) {
                    closeModal();

                    SuccessSwal("Success", response.Message);
                    getAllCategories();
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            // onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["modal_Container"]}>
                <header>
                    <h3>Complaint</h3>
                </header>
                <div className={Styles["Complaint_Details"]}>
                    <div className={Styles["Complaint_Details_sub"]}>
                        <h3>Nature of Complaint :</h3>
                        <ul>
                            {getDetails?.NatureOfComplaint?.length === 0 ? (
                                <li>Others</li>
                            ) : (
                                getDetails?.NatureOfComplaint?.filter(
                                    (item) => item != "Other(Please Specify)"
                                )?.map((item, index) => (
                                    <li>
                                        {index + 1}) {item}
                                    </li>
                                ))
                            )}
                        </ul>
                    </div>
                    <div className={Styles["Complaint_Details_sub"]}>
                        <h3>Summary :</h3>
                        <p>{getDetails.Summary}</p>
                    </div>
                    <div className={Styles["Complaint_Details_sub"]}>
                        <h3>Comments:</h3>
                        {getDetails?.Status == 1 ? (
                            <p>{getDetails?.ResolvedComments}</p>
                        ) : (
                            <textarea
                                disabled={getDetails?.Status == 1}
                                ref={inputRef}
                                placeholder="Resolve Comments"
                                rows={5}
                            />
                        )}
                    </div>
                </div>
                {loading ? (
                    <div className={Styles["loader"]}>
                        <ReactLoading
                            color="green"
                            type="spokes"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    <div className={Styles["control_Buttons"]}>
                        {getDetails?.Status != 1 && (
                            <button
                                className={Styles["resolve"]}
                                onClick={resolveHanlder}>
                                Resolve
                            </button>
                        )}
                        <button
                            className={Styles["close"]}
                            onClick={closeModal}>
                            Close
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ComplaintModal;
