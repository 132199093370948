import React from "react";
import TransactionClasses from "./links.module.css";
import Skeleton from "react-loading-skeleton";

function Communications() {
    return (
        <div className={TransactionClasses["Container"]}>
            <table>
                <thead>
                    <tr>
                        {/* <th>Transaction Id</th> */}
                        <th>Batch Name</th>
                        <th>Batch Size</th>
                        <th>Payment Status</th>
                        <th>Start Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* <td>
                            <Skeleton />
                        </td> */}
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Communications;
