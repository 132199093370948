import React from "react";
import ActivityClasses from "./Activity.module.css";
import Skeleton from "./Skeleton";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../../../Services";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Moment from "react-moment";
function Index({ data }) {
    const Navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [Loading, setLoading] = useState(true);
    const [Active, setActive] = useState("Opened");
    const [Activity, setActivity] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.ActivityLogs("GET", null, token, params.get("ID"), data.Type)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setActivity(res.ScheduleClasess);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <>
            {Loading ? (
                <Skeleton />
            ) : (
                <div className={ActivityClasses["profile-data"]}>
                    <div>
                        <h3>Tasks</h3>
                        <span>Not a Client Manager</span>
                    </div>
                    <div className={ActivityClasses["Activity-feed"]}>
                        <h3>Activity Feed</h3>
                        <ul>
                            {Activity.length > 0 ? (
                                Activity.map((item) => (
                                    <li>
                                        <span>
                                            {item.ActionByName} • {item.Action}
                                        </span>
                                        <span>
                                            {" "}
                                            <Moment format="Do MMM YYYY hh:mm a">
                                                {new Date(item.CreatedAt)}
                                            </Moment>
                                        </span>
                                    </li>
                                ))
                            ) : (
                                <li>No Logs Found</li>
                            )}
                            {/* {Activity.map((item) => {
                                let link = "";
                                if (item.ContentType === 2) {
                                    link = `/scheduledClasses/Details?scheduleID=${item.ContentID}`;
                                }
                                if (item.ContentType === 1) {
                                    link = `/Batches/Details?BatchId=${item.ContentID}`;
                                }
                                if (item.ContentType === 6) {
                                    link = `/parent/Details?Id=${item.ContentID}`;
                                }
                                if (item.ContentType === 7) {
                                    link = `/Students/details?id=${item.ContentID}`;
                                }
                                if (item.ContentType === 11) {
                                    link = `/Student-registration/details?id=${item.ContentID}`;
                                }
                                if (
                                    item.ContentType === 3 ||
                                    item.ContentType === 4 ||
                                    item.ContentType === 5 ||
                                    item.ContentType === 8 ||
                                    item.ContentType === 9 ||
                                    item.ContentType === 17 ||
                                    item.ContentType === 10
                                ) {
                                    return (
                                        <p>
                                            {" "}
                                            {item.ActionByName} • {item.Action}
                                        </p>
                                    );
                                }
                                if (link.length > 0) {
                                    return (
                                        <li>
                                            <NavLink to={link}>
                                                {item.ActionByName} •{" "}
                                                {item.Action}
                                            </NavLink>
                                        </li>
                                    );
                                }
                                return "-";
                            })} */}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
}

export default Index;
