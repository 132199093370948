import React from "react";
import detailsClass from "./index.module.css";
import swal from "sweetalert";
import { Services } from "../../../../Services";
import { Error_Dark } from "../../../../Util/Toast";
import { useSearchParams } from "react-router-dom";
import { AccessKeys } from "../../../../Routes/config";
import { useNavigate } from "react-router-dom";
import { warningSwal } from "../../../../Util/Toast";
function Actionbtns({ data, setData }) {
    const [params] = useSearchParams();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const Navigate = useNavigate();
    async function updateStatus(status, id) {
        try {
            let update = await Services.UpdateTutorById(
                "PUT",
                JSON.stringify({ TutorStatus: status }),
                token,
                id
            );
            if (update.Status == 1) {
                setData((prev) => ({ ...prev, TutorStatus: status }));
                if (status == 1) {
                    swal(
                        "Tutor Approved ",
                        "Tutor Approved Successfully",
                        "success"
                    );
                } else if (status == 3) {
                    swal(
                        "Tutor Rejected",
                        " Tutor Rejected Successfully",
                        "success"
                    );
                } else {
                    swal(
                        "Tutor Dormanted",
                        "Tutor Dormanted Successfully",
                        "success"
                    );
                }
                // window.location.reload();
            } else {
                warningSwal("warning", update.Message);
            }
        } catch (err) {
            console.log(err);
            Error_Dark("something went wrong try again later");
        }
    }
    const updateHandler = (e) => {
        const id = params.get("TutorId");
        const AccessItems = JSON.parse(localStorage.getItem("AccessItems"));
        let requiredPermission = AccessItems.filter(
            (item) => item.name === AccessKeys.Tutor
        );
        if (requiredPermission && requiredPermission.length > 0) {
            if (requiredPermission[0].Permissions.write == 0) {
                return Navigate("/forbidden");
            }
        }
        const btnType = e.target.value;
        let message;
        if (btnType == 1) {
            message = "Are you sure you want to approve Tutor?";
        } else if (btnType == 3) {
            message = "Are you sure you want to reject Tutor?";
        } else {
            message = "Are you sure want to Dormant Tutor?";
        }
        swal(message, {
            icon: "warning",
            className: detailsClass["logout-popup"],
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Confirm",
                    value: "Logout",
                    className: detailsClass["cancel"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    updateStatus(btnType, id);
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const backgroundColor = (status) => {
        return status == 2
            ? "orange"
            : status == 3
            ? "red"
            : status == 4
            ? "rgb(94 174 174)"
            : "green";
    };
    return (
        <div className={detailsClass["status-buttons"]}>
            <select
                onChange={updateHandler}
                value={data?.TutorStatus}
                style={{
                    color: "white",
                    backgroundColor: backgroundColor(data?.TutorStatus),
                }}>
                <option value="2" disabled>
                    {" "}
                    pending
                </option>
                <option value="1">
                    {data?.TutorStatus == 1 ? "Approved" : "Approve"}
                </option>

                <option value="3" disabled={data?.TutorStatus == 1}>
                    {data?.TutorStatus == 3 ? "Rejected" : "Reject"}
                </option>
                <option value="4">Dormant</option>
            </select>
            {/* {(data?.ApprovedStatus == 2 || data?.ApprovedStatus == 4) &&
                data?.ApprovedStatus != 3 &&
                data?.ApprovedStatus != 1 && (
                    <button
                        onClick={updateHandler}
                        btnType="1"
                        className={detailsClass["Approve-button"]}>
                        Approve
                    </button>
                )}
            {data && data?.ApprovedStatus == 2 && data?.ApprovedStatus != 3 && (
                <button
                    onClick={updateHandler}
                    btnType="3"
                    className={detailsClass["reject-button"]}>
                    {" "}
                    Reject
                </button>
            )}
            {data && data?.ApprovedStatus != 3 && data?.ApprovedStatus != 4 && (
                <button
                    onClick={updateHandler}
                    btnType="4"
                    className={detailsClass["dormant-button"]}>
                    Dormant
                </button>
            )} */}
        </div>
    );
}

export default Actionbtns;
