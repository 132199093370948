import React, { useState, useEffect } from "react";
import Layout from "../../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { config } from "../../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import S3FileUpload from "react-s3";
import { warningSwal, SuccessSwal } from "../../../../Util/Toast";
import uuid from "react-uuid";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../../Services";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import {
    NullCheck,
    isEmail,
    PhoneNumberValidate,
} from "../../../../Components/validators";
import Mainloader from "../../../../Components/loader/loader";
import user from "../../../../Assets/user.svg";
import { Country } from "country-state-city";
import ReactFlagsSelect from "react-flags-select";
import moment from "moment-timezone";
import "moment-timezone";

import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import { compareObjects, CheckIsObjectEmpty } from "Util/Util";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddTutor() {
    const labelStyle = "original";
    const timezones = {
        ...allTimezones,
        "Europe/Berlin": "Frankfurt",
    };
    const { options, parseTimezone } = useTimezoneSelect({
        labelStyle,
        timezones,
    });
    const [loading, setLoading] = useState(false);
    const [params] = useSearchParams();
    const [data, setData] = useState([]);
    const [load, setLoad] = useState(true);
    const [edit, setEdit] = useState(false);
    const [deleted, setDeleted] = useState([]);
    const Navigate = useNavigate();
    const [fileCount, setFileCount] = useState(0);
    const [base64, setBase64] = useState(user);
    const [editUploadData, setEditUploadData] = useState([]);
    const [SavedData, setSavedData] = useState([]);
    const [uploadFileNames, setUploadFileNames] = useState([]);
    const [adminData, setAdminData] = useState([]);
    let Countries = Country.getAllCountries();
    const [selectedcountry, setSelectedCountry] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    const [selectedcountry1, setSelectedCountr1] = useState({
        PhoneCode: "91",
        Country: "IN",
        Name: "India",
    });
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getDetails = () => {
        setEdit(true);
        Services.getParentDetailsByID("GET", null, token, params.get("id"))
            .then((response) => {
                console.log(response);

                if (response.Status === 1) {
                    setData(response.Parents);
                    setBase64(response.Parents.ProfilePicture);
                    let updateData = response.Parents;
                    // delete updateData.ProfilePicture;
                    reset(updateData);
                    setSavedData(JSON.parse(JSON.stringify(updateData)));
                    let phoneNumber = response?.Parents?.CountryCode;
                    if (phoneNumber.startsWith("+")) {
                        phoneNumber = phoneNumber.substring(1);
                        const primaryCountrycodes = Countries.filter(
                            (item) => item.phonecode == phoneNumber
                        );
                        if (primaryCountrycodes.length > 0) {
                            setSelectedCountry({
                                PhoneCode: primaryCountrycodes[0].phonecode,
                                Country: primaryCountrycodes[0].isoCode,
                                Name: primaryCountrycodes[0].name,
                            });
                        }
                    }
                    let phoneNumber1 =
                        response?.Parents?.SecondaryMobNo_CountryCode;
                    if (phoneNumber1.startsWith("+")) {
                        phoneNumber1 = phoneNumber1.substring(1);
                        const SecondaryCountrycodes = Countries.filter(
                            (item) => item.phonecode == phoneNumber1
                        );
                        if (SecondaryCountrycodes.length > 0) {
                            setSelectedCountr1({
                                PhoneCode: SecondaryCountrycodes[0].phonecode,
                                Country: SecondaryCountrycodes[0].isoCode,
                                Name: SecondaryCountrycodes[0].name,
                            });
                        }
                    }
                    setTimeout(() => {
                        setLoad(false);
                    }, 300);
                    let modifiedFilesArray = response?.Parents?.Documents?.map(
                        (item) => {
                            return {
                                fileName: item.Document,
                                DocumentID: item.DocumentID,
                                id: uuid(),
                                location: item.Get_Documents,
                                fileType: String(item.Document).split(".")[1],
                            };
                        }
                    );
                    setUploadFileNames(modifiedFilesArray);
                    setFileCount(modifiedFilesArray?.length);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        Services.GetAdmin("POST", null, token)
            .then((response) => {
                if (response.Status === 1) {
                    setAdminData(
                        response?.AdminUsers?.filter((item) => item.Type === 5)
                    );
                    if (params.get("id")) {
                        getDetails();
                    } else {
                        setTimeout(() => {
                            setLoad(false);
                        }, 300);
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);

    const profilePicUpload = (files) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "Parents/ProfilePictures",
            })
                .then((res) => {
                    setValue(
                        "ProfilePicture",
                        `${timestamp}.${String(files.type).split("/")[1]}`
                    );
                    setBase64(res.location);
                    // if (edit) {
                    //     setEditUploadData((prev) => [...prev, data]);
                    // }
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    const onSubmit = (data) => {
        const loginData = JSON.parse(localStorage.getItem("admindata"));
        console.log(data);
        let body = {
            ...data,
            AdminUserType: loginData.Type,
            CountryCode: `+${selectedcountry.PhoneCode}`,
            SecondaryMobNo_CountryCode: `+${selectedcountry1.PhoneCode}`,
        };
        if (edit) {
            body = {
                ...body,
                AdminUserType: loginData.Type,
                Documents: editUploadData.map((item) => item.fileName),
                Deleted: deleted,
            };
            delete body.Status;
            let newBody = compareObjects(body, SavedData);
            if (CheckIsObjectEmpty(newBody)) {
                warningSwal("Warning", "Nothing to update");
                return;
            }
            setLoading(true);
            Services.UpdateParent(
                "PUT",
                JSON.stringify(newBody),
                token,
                params.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            body = {
                ...body,
                Documents: uploadFileNames.map((item) => item.fileName),
            };
            setLoading(true);
            Services.AddParent("POST", JSON.stringify(body), token)
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        toast.success(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else if (response.Status === 0) {
                        setLoading(false);
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const profileupload = (
        <div className={TutorClasses["profile-img"]}>
            <img src={base64} alt={"profilepic"} />
        </div>
    );
    const phoneChangeHandler = (data) => {
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            console.log(selectedCountry);
            setSelectedCountry({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    console.log(options);
    const phoneChangeHandler1 = (data) => {
        const selectedCountry = Countries.filter(
            (item) => item.isoCode == data
        );
        if (selectedCountry.length > 0) {
            setSelectedCountr1({
                PhoneCode: selectedCountry[0].phonecode,
                Country: selectedCountry[0].isoCode,
                Name: selectedCountry[0].name,
            });
        }
    };
    const backButton = () => {
        Navigate(-1);
    };
    const NullCheck_Names = (value) => {
        if (!value) {
            return "This field is required";
        }

        // Check for alphabetic characters without numbers or special characters
        if (/^[a-zA-Z ]+$/.test(value)) {
            return true; // Validation passed
        } else {
            return "Only alphabetic characters are allowed";
        }
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Layout Active={"Parent"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Parent</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Parent" : "Update Parent"}</h3>
                    {load ? (
                        <Mainloader />
                    ) : (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <h3>Personal Info</h3>
                            <div className={TutorClasses["profile-upload"]}>
                                <div>
                                    <FileUploader
                                        classes={
                                            TutorClasses["profile-upload-input"]
                                        }
                                        multiple={false}
                                        handleChange={profilePicUpload}
                                        name="file"
                                        children={profileupload}
                                    />
                                </div>
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="First Name">
                                    First Name{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    {...register("FirstName", {
                                        required: "This is required",
                                        validate: NullCheck_Names,
                                    })}
                                />
                                {errors.FirstName && (
                                    <span>{errors?.FirstName.message}</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="Last Name">
                                    Last Name{" "}
                                    <span className="important">*</span>
                                </label>
                                <input
                                    {...register("LastName", {
                                        required: "this is required",
                                        validate: NullCheck_Names,
                                    })}
                                />
                                {errors.LastName && (
                                    <span>{errors?.LastName?.message}</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="EmailID">
                                    Email <span className="important">*</span>{" "}
                                </label>
                                <input
                                    // disabled={edit}
                                    type="email"
                                    {...register("EmailID", {
                                        required: true,
                                        validate: isEmail,
                                    })}
                                />
                                {errors.EmailID && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Primary Contact Number{" "}
                                    <span className="important">*</span>
                                </label>

                                <div className={TutorClasses["select-code"]}>
                                    <input
                                        // disabled={edit}
                                        onWheel={blurHandler}
                                        onScroll={blurHandler}
                                        type="text"
                                        {...register("MobileNumber", {
                                            required: "this field is required",
                                            // validate: PhoneNumberValidate,
                                        })}
                                    />
                                    <div>
                                        <ReactFlagsSelect
                                            selected={selectedcountry.Country}
                                            showSelectedLabel={false}
                                            onSelect={phoneChangeHandler}
                                            fullWidth={false}
                                            // customLabels={customLabels}
                                            placeholder=""
                                            className={
                                                TutorClasses["select-btn"]
                                            }
                                        />
                                    </div>
                                </div>
                                {errors.MobileNumber && (
                                    <span>{errors.MobileNumber.message}</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="ContactNumber">
                                    Secondary Contact Number
                                </label>

                                <div className={TutorClasses["select-code"]}>
                                    <input
                                        // disabled={edit}
                                        type="text"
                                        onWheel={blurHandler}
                                        onScroll={blurHandler}
                                        {...register("SecondaryMobileNo", {
                                            required: false,
                                            // validate: PhoneNumberValidate,
                                        })}
                                    />
                                    <div>
                                        <ReactFlagsSelect
                                            selected={selectedcountry1.Country}
                                            showSelectedLabel={false}
                                            onSelect={phoneChangeHandler1}
                                            fullWidth={false}
                                            // customLabels={customLabels}
                                            placeholder=""
                                            className={
                                                TutorClasses["select-btn"]
                                            }
                                        />
                                    </div>
                                </div>
                                {errors.SecondaryMobileNo && (
                                    <span>
                                        {errors.SecondaryMobileNo.message}
                                    </span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="Address">
                                    Address <span className="important">*</span>
                                </label>
                                <input
                                    // disabled={edit}
                                    type="text"
                                    {...register("Address", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.Address && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="City">
                                    City <span className="important">*</span>
                                </label>
                                <input
                                    // disabled={edit}
                                    type="text"
                                    {...register("City", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.City && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="State">
                                    State <span className="important">*</span>
                                </label>
                                <input
                                    // disabled={edit}
                                    type="text"
                                    {...register("State", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.State && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="State">
                                    Country <span className="important">*</span>
                                </label>
                                <input
                                    // disabled={edit}
                                    type="text"
                                    {...register("Country", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.Country && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="Pincode">
                                    Pincode <span className="important">*</span>
                                </label>
                                <input
                                    // disabled={edit}
                                    type="text"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    {...register("Pincode", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.Pincode && (
                                    <span>This field is required</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="SalesManager">
                                    SalesManager
                                </label>

                                <select
                                    defaultValue={""}
                                    {...register("SalesManager", {
                                        required: false,
                                    })}>
                                    <option value="" disabled>
                                        Select Sales Manager
                                    </option>
                                    {adminData?.map((item) => (
                                        <option value={item.AdminUserID}>
                                            {item.FirstName} {item.LastName}
                                        </option>
                                    ))}
                                </select>
                                {errors.SalesManager && (
                                    <span>{errors.SalesManager.message}</span>
                                )}
                            </div>
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="Time Zone">Time Zone</label>

                                <select
                                    {...register("TimeZone", {
                                        required: false,
                                    })}
                                    defaultValue={""}>
                                    <option value="" disabled>
                                        Select Time zone
                                    </option>
                                    {moment.tz
                                        .names()
                                        .map((tz) => ({
                                            value: tz,
                                            label: tz,
                                        }))
                                        .sort((a, b) => {
                                            const labelA = a.label;
                                            const labelB = b.label;
                                            return labelA.localeCompare(labelB);
                                        })
                                        .map((option) => (
                                            <option value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                </select>
                                {errors.TimeZone && (
                                    <span>{errors.TimeZone.message}</span>
                                )}
                            </div>
                            {/*   <div
                                className={TutorClasses["form-control"]}
                                style={{ gridColumn: "1/-1" }}>
                                <label htmlFor="Pincode">
                                    Notes <span className="important">*</span>
                                </label>
                                <textarea
                                    // disabled={edit}
                                    rows={5}
                                    placeholder="Notes"
                                    type="number"
                                    onWheel={blurHandler}
                                    onScroll={blurHandler}
                                    {...register("Notes", {
                                        required: true,
                                        validate: NullCheck,
                                    })}
                                />
                                {errors.Notes && (
                                    <span>This field is required</span>
                                )}
                            </div> */}
                            {/* <div
                                className={TutorClasses["file-upload-wrapper"]}>
                                <header>
                                    <h3>Upload Documents</h3>
                                </header>
                                <FileUploader
                                    classes={TutorClasses["upload-image"]}
                                    multiple={true}
                                    handleChange={filehandleChange}
                                    name="file"
                                    children={fileuploadDesign}
                                    // types={fileTypes}
                                />
                            </div>
                            {uploadFileNames?.length > 0 && (
                                <div
                                    className={
                                        TutorClasses["file-images-wrapper"]
                                    }>
                                    {uploadFileNames.map((item) => (
                                        <div className={TutorClasses["image"]}>
                                            {item.fileType == "application" ||
                                            item.fileType == "pdf" ? (
                                                <img
                                                    src={pdf}
                                                    onClick={() => {
                                                        window.open(
                                                            item.location,
                                                            "_blank"
                                                        );
                                                    }}
                                                    alt="pdf"
                                                />
                                            ) : (
                                                <img
                                                    src={item.location}
                                                    onClick={() => {
                                                        window.open(
                                                            item.location,
                                                            "_blank"
                                                        );
                                                    }}
                                                />
                                            )}
                                            <button
                                                type="button"
                                                onClick={closeHandler}
                                                id={item.id}
                                                docid={item.DocumentID}>
                                                <AiOutlineClose
                                                    color={"white"}
                                                    className={
                                                        TutorClasses["icon"]
                                                    }
                                                />
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )} */}
                            <button disabled={loading}>
                                {" "}
                                {loading ? (
                                    <ReactLoading
                                        color="green"
                                        type="spokes"
                                        height={30}
                                        width={30}
                                    />
                                ) : (
                                    "Submit"
                                )}
                            </button>
                        </form>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default AddTutor;
