import React from "react";
import profileClasses from "./profle.module.css";
import { GrMail } from "react-icons/gr";
import { FaPhone } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import Moment from "react-moment";
import { AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import user from "../../../../../Assets/square-user.jpg";
import { AccessKeys } from "../../../../../Routes/config";
import { AccessControl } from "../../../../../Util/AccessControl";
function Profile({ data }) {
    const Navigate = useNavigate();

    return (
        <div className={profileClasses["wrapper"]}>
            <div className={profileClasses["profile"]}>
                <div className={profileClasses["Contact"]}>
                    <div>
                        <div
                            style={{
                                border: "1px solid #ddd",
                                padding: "0.2rem",
                                width: "fit-content",
                            }}>
                            <header className={profileClasses["initials"]}>
                                <img
                                    src={data.ProfilePicture}
                                    alt={"profile"}
                                    onError={(e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = user;
                                    }}
                                />
                            </header>
                        </div>
                        {AccessControl(AccessKeys.Parents)?.edit === 1 && (
                            <button
                                className={profileClasses["action-btn"]}
                                onClick={() => {
                                    Navigate(
                                        `/Parent/Update?id=${data.ParentID}`
                                    );
                                }}>
                                <AiFillSetting size={18} />
                                Update
                            </button>
                        )}
                    </div>
                    <div className={profileClasses["card-data"]}>
                        <h4> Contact</h4>

                        {data.EmailID?.length > 0 && (
                            <p>
                                {" "}
                                <GrMail color="#016997" size={18} />
                                <span>{data.EmailID}</span>
                            </p>
                        )}
                        {data.MobileNumber && (
                            <p>
                                {" "}
                                <FaPhone color="#016997" size={13} />
                                <span>
                                    {" "}
                                    {data?.CountryCode != "NA" &&
                                        data.CountryCode}{" "}
                                    {data.MobileNumber}
                                </span>
                            </p>
                        )}
                        {data.SecondaryMobileNo && (
                            <p>
                                {" "}
                                <FaPhone color="#016997" size={13} />
                                <span>
                                    {" "}
                                    {data?.SecondaryMobNo_CountryCode != "NA" &&
                                        data.SecondaryMobNo_CountryCode}{" "}
                                    {data.SecondaryMobileNo}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
                <div className={profileClasses["internal"]}>
                    <h3>Profile</h3>
                    <div>
                        <h3>ID:</h3>
                        <p>{data.ParentID}</p>
                    </div>

                    <div>
                        <h3>Date Created:</h3>
                        <p>
                            <Moment format="DD/MM/YYYY">
                                {data.CreatedAt}
                            </Moment>
                        </p>
                    </div>
                    <div>
                        <h3>Sales Manager:</h3>
                        <p>{data.SalesManagerName}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;
