import Modal from "react-modal";
import React, { useEffect } from "react";
import Styles from "./report.module.css";
import { useForm, Controller } from "react-hook-form";
import moment from "moment";
import { Services } from "../../../Services";
import { warningSwal } from "../../../Util/Toast";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm = ({ modal, setModal, getDetails, id }) => {
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: { errors },
    } = useForm();
    const token = {
        Authorization: `token ${localStorage.getItem("token")}`,
    };


    // CLOSE FORM
    const closeModal = () => {
        setModal(false);
        reset();
    };
    useEffect(() => {
        let startTime = String(getDetails?.data?.Scheduled_StartTime).split(':')
        let EndTime = String(getDetails?.data?.Scheduled_EndTime).split(':')

        let StartDate = moment(getDetails?.data?.Scheduled_StartDate).set({ hour: Number(startTime[0]), minute: Number(startTime[1]) })

        let EndDate = moment(getDetails?.data?.Scheduled_StartDate).set({ hour: Number(EndTime[0]), minute: Number(EndTime[1]) })

        reset({ Scheduled_StartDate: StartDate.format('YYYY-MM-DDTHH:mm'), Scheduled_EndDate: EndDate.format('YYYY-MM-DDTHH:mm') })


    }, [])
    // FORM SUBMIT
    const onSubmit = (formdata) => {


        console.log(formdata)
        const start = moment(formdata.Scheduled_StartDate);
        const end = moment(formdata.Scheduled_EndDate);

        // Calculate the difference in minutes
        const duration = moment.duration(end.diff(start));
        const minutesDifference = duration.asMinutes();
        if (minutesDifference < 0) {
            warningSwal('Warning', "Invalid Date Selection");
            return
        }
        let data = JSON.stringify({
            ScheduleClassID: getDetails.data.ScheduleClassID,
            Scheduled_StartDate: moment(formdata?.Scheduled_StartDate).format('YYYY-MM-DD'),
            Scheduled_StartTime: moment(formdata?.Scheduled_StartDate).format('HH:mm:ss'),
            Scheduled_EndDate: moment(formdata?.Scheduled_EndDate).format('YYYY-MM-DD'),
            Scheduled_EndTime: moment(formdata?.Scheduled_EndDate).format('HH:mm:ss'),
            ClassDuration: minutesDifference
        });
        Services.updateClassDuration("POST", data, token)
            .then((response) => {
                console.log(response);
                if (response.Status === 1) {
                    getDetails.setLoading(true);
                    getDetails.details(id);
                    swal("Success", response.Message, "success");
                    closeModal();
                } else if (response.Status === 0) {
                    warningSwal('Warning', response.Message);
                }
            })
            .catch((err) => {
                console.log(err);
                warningSwal('Warning', "something went wrong please try again");
            });
    };

    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Class Duration</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">Scheduled Start Date :</label>
                        <div className={Styles['input']}>
                            <input type="datetime-local" {...register('Scheduled_StartDate', { required: 'this field is required' })} />
                            {errors.Scheduled_StartDate && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">
                            Scheduled End Date <span>:</span>
                        </label>
                        <div className={Styles['input']}>

                            <input type="datetime-local"  {...register("Scheduled_EndDate", { required: "This field is required" })} />
                            {errors.Scheduled_EndDate && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                    </div>
                    {/* <div className={Styles["form-control"]}>
                        <label htmlFor="">
                            Topic taken today <span>:</span>
                        </label>
                        <div className={Styles["input"]}>
                            <input
                                type="text"
                                {...register("NewTopicDone", {
                                    required: "true",
                                })}
                            />
                            {errors.NewTopicDone && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Description of Topic <span>:</span>
                        </label>

                        <textarea
                            {...register("Comments", {
                                required: false,
                            })}></textarea>
                    </div> */}
                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            Submit
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            className={Styles["close"]}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
