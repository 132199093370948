import { useState, useEffect } from "react";
import React from "react";
import Layout from "../../Components/Layout";
import AvailabilityClasses from "./Availability.module.css";
import Select from "react-select";
import { Services } from "../../Services";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment/moment";
import { CalenderDataGenerator } from "./eventGenerator";
import { useForm } from "react-hook-form";
import Loader from "../../Components/loader/loader";
import { Error_Dark } from "../../Util/Toast";
const localizer = momentLocalizer(moment);
function Availability() {
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [tutors, setTutors] = useState([]);
    const [tutorSearchString, setTutorSearchString] = useState(null);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    const [event, setEvents] = useState([]);
    const [loading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const Get_Calender = async (body) => {
        setLoading(true);
        try {
            const data = await Services.List_tutoravailability(
                "POST",
                JSON.stringify(body),
                token
            );
            if (data.Status === 1) {
                let formatter = CalenderDataGenerator(data.TutorAvailability);
                setEvents(formatter);
            } else {
                Error_Dark(data.Message);
            }
        } catch (error) {
            console.error("Error fetching options:", error);
        } finally {
            setLoading(false);
            setTutorSearchLoad(false);
        }
    };

    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);

    const handleChange = (selectedOption) => {
        setTutorSearchString(selectedOption);
    };
    const onTutorSelect = (option) => {
        if (option) {
            setSelectedTutor(option);
            let body = {
                TutorID: option.value,
            };

            Get_Calender(body);
        }
    };
    const onSubmit = (data) => {
        if (data.FromDate > data.ToDate) {
            setError("ToDate", { message: "Invalid Date Range" });
        }
        if (!selectedTutor) {
            alert("please select Tutor");
            return;
        }
        let body = {
            TutorID: selectedTutor.value,
            ...data,
        };
        Get_Calender(body);
    };
    return (
        <Layout Active={"availability"}>
            {loading && <Loader />}
            <div className={AvailabilityClasses["Container"]}>
                <div className={AvailabilityClasses["tutor"]}>
                    <h3>Tutor Aavailability</h3>
                    <div className={AvailabilityClasses["filters"]}>
                        <div className={AvailabilityClasses["form-control"]}>
                            <label htmlFor="">Tutor</label>
                            <Select
                                options={tutors}
                                onInputChange={handleChange}
                                onChange={onTutorSelect}
                                value={selectedTutor}
                                isLoading={tutorSearchLoad}
                                placeholder="Search Tutor"
                                noOptionsMessage={() => "No Tutor Found"}
                                classNames={AvailabilityClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                            />
                        </div>
                        {/* <form
                            onSubmit={handleSubmit(onSubmit)}
                            className={AvailabilityClasses["date-filter"]}>
                            <div
                                className={AvailabilityClasses["form-control"]}>
                                <label htmlFor="">From Date</label>
                                <input
                                    type="date"
                                    {...register("FromDate", {
                                        required: "this field is required",
                                    })}
                                />
                                {errors.FromDate && (
                                    <span>{errors.FromDate.message}</span>
                                )}
                            </div>
                            <div
                                className={AvailabilityClasses["form-control"]}>
                                <label htmlFor="">To Date</label>
                                <input
                                    type="date"
                                    {...register("ToDate", {
                                        required: "this field is required",
                                    })}
                                />
                                {errors.FromDate && (
                                    <span>{errors.FromDate.message}</span>
                                )}
                            </div>
                            <button type="submit">Filter</button>
                            <button
                                type="button"
                                className={AvailabilityClasses["clear"]}>
                                Clear{" "}
                            </button>
                        </form> */}
                    </div>
                    <div className={AvailabilityClasses["calender"]}>
                        <Calendar
                            events={event}
                            localizer={localizer}
                            views={["month"]}
                            popup
                            defaultView="month"
                            defaultDate={moment().toDate()}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default Availability;
