import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import { Helmet } from "react-helmet";
import approvalclasses from "./ClassCancel/approval.module.css";
import PenaltyRequest from "./ClassCancel/approval";
import { useSearchParams } from "react-router-dom";
import LeaveRequests from "./LeaveRequest/approval";
function Index() {
    const [params, setParams] = useSearchParams();
    const [Active, setActive] = useState("penalty_requests");
    const NavHandler = (e) => {
        let Type = e.currentTarget.getAttribute("btn-type");
        setActive(Type);
        setParams({ type: Type });
    };

    useEffect(() => {
        let type = params.get("type");
        if (type) {
            setActive(type);
        }
    }, []);
    return (
        <Layout Active={"Approvals"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Approval</title>
            </Helmet>
            <div className={approvalclasses["Container"]}>
                <div className={approvalclasses["Tutor"]}>
                    <h3>Approval</h3>
                    <div className={approvalclasses["Navigator"]}>
                        <button
                            btn-type="penalty_requests"
                            className={
                                Active === "penalty_requests"
                                    ? approvalclasses["active"]
                                    : ""
                            }
                            onClick={NavHandler}>
                            Penalty Requests
                        </button>
                    </div>
                    {Active === "penalty_requests" && (
                        <PenaltyRequest Active={Active} />
                    )}
                    {Active === "leave" && <LeaveRequests />}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
