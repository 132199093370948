import React from "react";
import DashboardClasses from "../dashboard.module.css";
import CountUp from "react-countup";
function Summary({ Count, icon, heading, prefix }) {
    return (
        <div className={DashboardClasses["summary"]}>
            <p>{icon}</p>
            <div className={DashboardClasses["summary_text"]}>
                <h3>{heading}</h3>
                <CountUp
                    start={0}
                    className={DashboardClasses["counter"]}
                    end={Count}
                    duration={1.75}
                    prefix={prefix}></CountUp>
            </div>
        </div>
    );
}

export default Summary;
