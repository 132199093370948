import React, { useState } from "react";
import Layout from "../../../../Components/Layout";
import detailsClass from "./details.module.css";
import { FaUser } from "react-icons/fa";
import { AiFillThunderbolt } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import Profile from "./Profile/profile";
import { Helmet } from "react-helmet";
import ActivityData from "./Activity";
import Communications from "./Communications";
import { useNavigate } from "react-router-dom";
function Details() {
    const Navigate = useNavigate();
    const [isActive, setIsActive] = useState("Profile");
    const [loading, setLoading] = useState(true);

    const [data, setData] = useState([]);

    const tabHandler = (e) => {
        const btnType = e.currentTarget.getAttribute("btn-type");
        setIsActive(btnType);
        setLoading(true);
    };
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Administrators"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Administrator</title>
            </Helmet>
            <div className={detailsClass["Container"]}>
                <button onClick={backButton} className={detailsClass["back"]}>
                    Back
                </button>
                <div className={detailsClass["wrapper"]}>
                    <h3>
                        <span></span> Administrator:
                        {data.FirstName} {data.LastName}
                    </h3>
                    <div className={detailsClass["control-btns"]}>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Profile"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Profile"
                            onClick={tabHandler}>
                            <FaUser /> Profile
                        </button>

                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Activity"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Activity"
                            onClick={tabHandler}>
                            <AiFillThunderbolt />
                            Activity
                        </button>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Email"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Email"
                            onClick={tabHandler}>
                            <MdOutlineEmail />
                            Email
                        </button>
                    </div>
                    {isActive === "Profile" && (
                        <Profile
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            setData={setData}
                        />
                    )}
                    {isActive === "Activity" && <ActivityData data={data} />}
                    {isActive === "Email" && <Communications />}
                </div>
            </div>
        </Layout>
    );
}

export default Details;
