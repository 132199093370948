import React, { useState, useEffect } from "react";
import approvalclasses from "./approval.module.css";
import { Services } from "../../../Services";
import SkeletonLoad from "../../../Components/TableLoad/load";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";

import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import Moment from "react-moment";
function Tutor() {
    const Navigate = useNavigate();
    // eslint-disable-next-line

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    // const admindata = JSON.parse(localStorage.getItem("admindata"));
    const Get_Classes = (data) => {
        Services.GetTutorLeave("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.status === 1) {
                    setData(response.TutorLeaves);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        Get_Classes(1);
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line

    async function Api_Cancel_request(
        scheduleId,
        ZoomMeetingID,
        IsPenalityCharged,
        ScheduleClass_ApprovalID
    ) {
        const body = JSON.stringify({
            ZoomMeetingID,
            IsPenalityCharged,
            ScheduleClass_ApprovalID,
        });
        try {
            const cancel = await Services.ApprovalCancelrequest(
                "PUT",
                body,
                token,
                scheduleId
            );
            if (cancel?.Status == 1) {
                SuccessSwal(cancel.Message, "");
                Get_Classes();
            }
            if (cancel?.Status == 0) {
                warningSwal("warning", cancel.Message);
            }
        } catch (err) {
            alert("something went wrong try again");
            console.log(err);
        }
    }
    const ApproveCancelRequest = (
        scheduleId,
        ZoomMeetingID,
        IsPenalityCharged,
        ScheduleClass_ApprovalID
    ) => {
        let message =
            IsPenalityCharged == 0
                ? "Are you sure you want to waive penality?"
                : "Are you sure you want to approve Cancel request?";
        swal(message, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: "Approve",
                    value: "Logout",
                    className: approvalclasses["Delete"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Api_Cancel_request(
                        scheduleId,
                        ZoomMeetingID,
                        IsPenalityCharged,
                        ScheduleClass_ApprovalID
                    );
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };

    let headers = ["TutorName", "Date", "No Of Days", "type", "Actions"];
    return (
        <>
            {loading ? (
                <SkeletonLoad TableHead={headers} />
            ) : data.length > 0 ? (
                <div className={approvalclasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                {headers.map((item) => (
                                    <th>{item}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => {
                                let DateFormat =
                                    item.IsCustomLeave == 2
                                        ? "DD MMM YYYY"
                                        : "DD MMM ";
                                return (
                                    <tr
                                        id={item.LeaveRequestID}
                                        name={item.TutorName}>
                                        <td>{item.TutorName}</td>
                                        <td>
                                            <Moment format={DateFormat}>
                                                {new Date(item.FromDate)}
                                            </Moment>

                                            {item.IsCustomLeave != 2 && (
                                                <>
                                                    <b>-</b>
                                                    <Moment format="DD MMM YYYY">
                                                        {new Date(item.ToDate)}
                                                    </Moment>
                                                </>
                                            )}
                                            {item.IsCustomLeave != 0 && (
                                                <>
                                                    <br />
                                                    <span>
                                                        {item.CustomDates[0]
                                                            .FromDayType == 1
                                                            ? `(First Half)`
                                                            : "(Second Half)"}
                                                    </span>
                                                </>
                                            )}
                                            {item.IsCustomLeave == 1 && (
                                                <>
                                                    <b>-</b>
                                                    <span>
                                                        {item.CustomDates[0]
                                                            .ToDayType == 1
                                                            ? `(First Half)`
                                                            : "(Second Half)"}
                                                    </span>
                                                </>
                                            )}
                                        </td>
                                        <td>{item.NoOfDays}</td>
                                        {/* <td
                                            style={{
                                                width: "13rem",
                                            }}>
                                            {item.LeaveNote}
                                        </td> */}
                                        <td>
                                            {item.LeaveType === 1
                                                ? "Casual Leave"
                                                : item.LeaveType === 2
                                                ? "Marriage"
                                                : item.LeaveType === 3
                                                ? "Sick Leave"
                                                : "Unpaid"}
                                        </td>
                                        <td>
                                            {item.ApproveStatus == 2 ? (
                                                <div
                                                    className={
                                                        approvalclasses[
                                                            "action-btn"
                                                        ]
                                                    }>
                                                    <button
                                                        className={
                                                            approvalclasses[
                                                                "Approved"
                                                            ]
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            ApproveCancelRequest(
                                                                item?.ScheduleClassID,
                                                                item?.ZoomMeetingID,
                                                                1,
                                                                item?.ScheduleClass_ApprovalID
                                                            );
                                                        }}>
                                                        Approve
                                                    </button>
                                                    <button
                                                        className={
                                                            approvalclasses[
                                                                "reject"
                                                            ]
                                                        }
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            ApproveCancelRequest(
                                                                item?.ScheduleClassID,
                                                                item?.ZoomMeetingID,
                                                                0,
                                                                item?.ScheduleClass_ApprovalID
                                                            );
                                                        }}>
                                                        Reject
                                                    </button>
                                                </div>
                                            ) : (
                                                <span
                                                    className={
                                                        item.ApproveStatus == 2
                                                            ? approvalclasses[
                                                                  "pending"
                                                              ]
                                                            : item.ApproveStatus ==
                                                              1
                                                            ? approvalclasses[
                                                                  "Approved"
                                                              ]
                                                            : approvalclasses[
                                                                  "reject"
                                                              ]
                                                    }>
                                                    {item.ApproveStatus == 2
                                                        ? "Pending"
                                                        : item.ApproveStatus ==
                                                          1
                                                        ? "Approved"
                                                        : item.ApproveStatus ===
                                                          3
                                                        ? "Reject"
                                                        : "User Cancel"}
                                                </span>
                                            )}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Records Found</p>
                </div>
            )}
        </>
    );
}

export default Tutor;
