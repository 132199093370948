import React, { useState, useEffect } from "react";
import { Services } from "../../../../../Services";
import transactionClasses from "./transactions.module.css";
import Skeleton from "./skeleton";
import { useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import WalletAdjustment from "./adjustment/generate";
import RefundModal from "./adjustment/refund";
import { NavLink } from "react-router-dom";
function Transactions() {
    const [params] = useSearchParams();
    const [Loading, setLoading] = useState(true);
    const [refund, setRefund] = useState(false);
    const [Invoice, setInvoice] = useState(null);
    const [modal, setModal] = useState(false);
    const [transaction, setTransactions] = useState({
        transaction: [],
        TotalWalletAmount: 0,
        MobileNumber: "",
    });
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        Transactions();
    }, []);
    async function Transactions() {
        try {
            const Transactions = await Services.parentWallet(
                "GET",
                null,
                token,
                params.get("Id")
            );
            setTimeout(() => {
                setLoading(false);
            }, 500);
            if (Transactions.Status === 1) {
                setTransactions({
                    transaction: Transactions.Wallets,
                    InvoiceBalance: Transactions.InvoiceBalance,
                    MobileNumber: Transactions.MobileNumber,
                    TotalWalletAmount: Transactions?.TotalWalletAmount
                        ? Transactions?.TotalWalletAmount
                        : 0,
                });
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            alert("Something went wrong try again");
        }
    }
    const refundHandler = (item) => {
        setInvoice(item);
        setRefund(true);
    };
    return (
        <div className={transactionClasses["Container"]}>
            <WalletAdjustment
                showPaymentModal={modal}
                setShowPaymentModal={setModal}
                MobileNumber={transaction.MobileNumber}
                Transactions={Transactions}
                TotalWalletAmount={transaction.TotalWalletAmount}
            />
            {refund && (
                <RefundModal
                    showPaymentModal={refund}
                    invoice={Invoice}
                    setShowPaymentModal={setRefund}
                    MobileNumber={transaction.MobileNumber}
                    Transactions={Transactions}
                    TotalWalletAmount={transaction.TotalWalletAmount}
                />
            )}
            <header>
                <h3>Payments </h3>
                <div>
                    <span className={transactionClasses["balance"]}>
                        Wallet Balance :
                        <bold>
                            {" "}
                            ₹{" "}
                            {Number(transaction?.TotalWalletAmount).toFixed(2)}
                        </bold>
                    </span>
                    <span className={transactionClasses["balance"]}>
                        Invoice Balance :
                        <bold>
                            {" "}
                            ₹ {Number(transaction?.InvoiceBalance).toFixed(2)}
                        </bold>
                    </span>
                    <button onClick={() => setModal(true)}>
                        Wallet Adjustment
                    </button>
                </div>
            </header>
            {Loading ? (
                <Skeleton />
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Transaction Date</th>
                            <th>Class Date</th>
                            <th>Type</th>
                            <th>Comments</th>
                            <th>Credit</th>
                            <th>Debit</th>
                            <th>Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transaction?.transaction?.length > 0 ? (
                            transaction?.transaction?.map((item) => (
                                <tr>
                                    <td>
                                        <Moment format="DD-MM-YYYY">
                                            {new Date(item.CreatedAt)}
                                        </Moment>
                                    </td>
                                    <td>
                                        {item.Type == 1 || item?.Type == 4 ? (
                                            <NavLink
                                                to={`/scheduledClasses/Details?scheduleID=${item.ScheduleClassID}`}>
                                                <Moment format="DD-MM-YYYY">
                                                    {item?.Scheduled_StartDate}
                                                </Moment>
                                            </NavLink>
                                        ) : (
                                            "NA"
                                        )}
                                    </td>
                                    <td>
                                        {item.Type === 1
                                            ? "Lesson Complete"
                                            : item.Type === 2
                                            ? "Manual balance Adjustment"
                                            : item.Type == 3
                                            ? "Top up"
                                            : item.Type === 4
                                            ? "Class Cancel"
                                            : item.Type === 5
                                            ? "Course Registration"
                                            : item.Type === 6
                                            ? "Payment from Payment Link(Admin generated)"
                                            : "Payment from Payment Link(Low Balance Link)"}
                                    </td>
                                    <td style={{ maxWidth: "9rem" }}>
                                        {item.Comments}
                                    </td>
                                    <td>
                                        {item.IsCredited == 1 ? (
                                            <span
                                                style={{
                                                    color: "darkgreen",
                                                    fontWeight: "600",
                                                }}>
                                                ₹{" "}
                                                {Number(item.Amount).toFixed(2)}
                                            </span>
                                        ) : (
                                            "⎼⎼⎼⎼⎼⎼"
                                        )}
                                    </td>
                                    <td>
                                        {" "}
                                        {item.IsCredited == 2 ||
                                        item.IsCredited == 0 ? (
                                            <span
                                                style={{
                                                    color: "red",
                                                    fontWeight: "600",
                                                }}>
                                                ₹{" "}
                                                {Number(item.Amount).toFixed(2)}
                                            </span>
                                        ) : (
                                            "⎼⎼⎼⎼⎼⎼"
                                        )}
                                    </td>
                                    <td>
                                        <div
                                            className={
                                                transactionClasses[
                                                    "refund_btns"
                                                ]
                                            }>
                                            {item?.InvoiceFile != "NA" && (
                                                <a
                                                    href={item?.InvoiceFile}
                                                    target="_blank">
                                                    Link
                                                </a>
                                            )}
                                            {item?.InvoiceFile != "NA" &&
                                                item?.IsCredited == 1 &&
                                                item?.IsRefunded == 0 && (
                                                    <button
                                                        className={
                                                            transactionClasses[
                                                                "refund-btn"
                                                            ]
                                                        }
                                                        onClick={() =>
                                                            refundHandler(item)
                                                        }>
                                                        Refund
                                                    </button>
                                                )}
                                            {item?.IsRefunded == 1 && (
                                                <p
                                                    className={
                                                        transactionClasses[
                                                            "refunded"
                                                        ]
                                                    }>
                                                    Refunded
                                                </p>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} style={{ textAlign: "center" }}>
                                    No Record Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Transactions;
