import React, { useState, useEffect } from "react";
import { Services } from "../../Services";
import { warningSwal } from "../../Util/Toast";
import LogClasses from "./log.module.css";
import Moment from "react-moment";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
function Log({ ActionFor, ActionByType }) {
    const [Activity, setActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const Navigate = useNavigate();
    useEffect(() => {
        Services.ActivityLogs("GET", null, token, ActionFor, ActionByType)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setActivity(res.ScheduleClasess);
                } else if (res.Status === 0) {
                    warningSwal("Warning", res.Message);
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <div className={LogClasses["Activity-feed"]}>
            {/* <h3>Activity Feed</h3> */}
            {loading ? (
                <>
                    <p style={{ margin: "0.1rem" }}>
                        <Skeleton height={"10px"} />
                    </p>
                    <p style={{ margin: "0.1rem" }}>
                        <Skeleton height={"10px"} />
                    </p>
                    <p style={{ margin: "0.1rem" }}>
                        <Skeleton height={"10px"} />
                    </p>
                </>
            ) : (
                <ul>
                    {Activity.length > 0
                        ? Activity.map((item) => (
                              <li>
                                  {" "}
                                  <span>
                                      {item.ActionByName} • {item.Action}
                                  </span>
                                  <span>
                                      {" "}
                                      <Moment format="Do MMM YYYY hh:mm a">
                                          {new Date(item.CreatedAt)}
                                      </Moment>
                                  </span>
                              </li>
                          ))
                        : "No Logs found"}
                </ul>
            )}
        </div>
    );
}

export default Log;
