import React from "react";
import Skeleton from "react-loading-skeleton";
import loadClasses from "./load.module.css";
function Load() {
    return (
        <div className={loadClasses["Table"]}>
            <table>
                <thead>
                    <tr>
                        <th>Batch ID</th>
                        <th>Batch Size</th>
                        <th>Course Name</th>
                        <th>Tutor Name</th>
                    </tr>
                </thead>
                <tbody>
                    {Array(3)
                        .fill()
                        .map(() => (
                            <tr>
                                <td>
                                    <Skeleton height={"1rem"} />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default Load;
