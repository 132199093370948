import React from "react";
import profileClasses from "./profle.module.css";
import { GrMail } from "react-icons/gr";
import { FaPhone } from "react-icons/fa";
import { AiFillSetting } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import user from "../../../../../Assets/square-user.jpg";
import { AccessKeys } from "../../../../../Routes/config";
import { AccessControl } from "../../../../../Util/AccessControl";
function Profile({ data }) {
    const Navigate = useNavigate();

    return (
        <div className={profileClasses["wrapper"]}>
            <div className={profileClasses["profile"]}>
                <div className={profileClasses["Contact"]}>
                    <div>
                        <div
                            style={{
                                border: "1px solid #ddd",
                                padding: "0.2rem",
                                width: "fit-content",
                            }}>
                            <header className={profileClasses["initials"]}>
                                <img
                                    src={data.ProfilePicture}
                                    alt={"profile"}
                                    onError={(e) => {
                                        e.currentTarget.onerror = null;
                                        e.currentTarget.src = user;
                                    }}
                                />
                            </header>
                        </div>
                        {AccessControl(AccessKeys.Tutor)?.edit === 1 && (
                            <button
                                className={profileClasses["action-btn"]}
                                onClick={() => {
                                    Navigate(
                                        `/Tutor/update?id=${data.TutorID}`
                                    );
                                }}>
                                <AiFillSetting size={18} />
                                Update
                            </button>
                        )}
                    </div>
                    <div className={profileClasses["card-data"]}>
                        <h4> Contact</h4>

                        {data.EmailID?.length > 0 && (
                            <p>
                                {" "}
                                <GrMail color="#016997" size={18} />
                                <span>{data.EmailID}</span>
                            </p>
                        )}
                        {data.MobileNumber1 && (
                            <p>
                                {" "}
                                <FaPhone color="#016997" size={13} />
                                <span>
                                    {" "}
                                    {data?.CountryCode != "NA" &&
                                        data?.CountryCode}{" "}
                                    {data.MobileNumber1}
                                </span>
                            </p>
                        )}
                    </div>
                    {/* <div className={profileClasses["internal"]}>
                        <h4> Bank Details</h4>
                        <div>
                            <h3>Bank Name:</h3>
                            <p>{data.BankName}</p>
                        </div>
                        <div>
                            <h3>Account Number:</h3>
                            <p>{data.AccountNumber}</p>
                        </div>
                        <div>
                            <h3>IFSC :</h3>
                            <p>{data.IFSC}</p>
                        </div>
                        <div>
                            <h3>Pan Number :</h3>
                            <p>{data.PanNumber}</p>
                        </div>
                    </div> */}
                </div>
                <div className={profileClasses["internal"]}>
                    <h3>Profile</h3>
                    {/* <div>
                        <h3>ID:</h3>
                        <p>{data.TutorID}</p>
                    </div> */}
                    <div>
                        <h3>Profile Completion:</h3>
                        <p>{data.ProfileCompletion}%</p>
                    </div>
                    <div>
                        <h3>Lead RM :</h3>
                        <p>{data.LeadRMName}</p>
                    </div>
                    <div>
                        <h3>Buddy RM :</h3>
                        <p>{data.BuddyRMName}</p>
                    </div>

                    <div>
                        <h3>SecondShift RM(morning) :</h3>
                        <p>{data.SecondShiftRM_MorningName}</p>
                    </div>
                    <div>
                        <h3>SecondShift RM(evening) :</h3>
                        <p>{data.SecondShiftRM_EveningName}</p>
                    </div>
                    <div>
                        <h3>Odd hour Fee :</h3>
                        <p>{data.oddHrsExtraFee}</p>
                    </div>
                    <div>
                        <h3>Zoom MeetingID :</h3>
                        <p>{data.ZoomPersonalMeetingID}</p>
                    </div>
                    <div>
                        <h3>Zoom EmailID :</h3>
                        <p>{data.ZoomEmailID}</p>
                    </div>
                    {/* <div>
                        <h3>Background Check:</h3>
                        <p>
                            {data.BackgroundCheck == 0 ? (
                                <AiOutlineClose size={16} color="red" />
                            ) : (
                                <TiTick />
                            )}
                        </p>
                    </div> */}
                    {/* <div>
                        <h3>Date Created:</h3>
                        <p>
                            <Moment format="DD/MM/YYYY">
                                {data.CreatedAt}
                            </Moment>
                        </p>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Profile;
