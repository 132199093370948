import React, { useState, useEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../Services";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
import ReactLoading from "react-loading";
import TimePicker from "rc-time-picker";
import moment from "moment";
import "rc-time-picker/assets/index.css";
window.Buffer = window.Buffer || require("buffer").Buffer;
function AddTutor() {
    const [loading, setLoading] = useState(false);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const formData = watch();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const batchDetails = JSON.parse(sessionStorage.getItem("batchDetails"));
    const [selectedStudents, setSelectedStudents] = useState([]);

    //Calculate End Time Based on Batch Duration
    function addMinutesToDateAndTime(dateStr, timeStr, minutesToAdd) {
        const [year, month, day] = dateStr.split("-");
        const [hoursStr, minutesStr] = timeStr.split(":");
        const date = new Date(year, month - 1, day, hoursStr, minutesStr);
        date.setMinutes(date.getMinutes() + minutesToAdd);
        const newDateStr = `${date.getFullYear()}-${String(
            date.getMonth() + 1
        ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
        const newTimeStr = `${String(date.getHours()).padStart(
            2,
            "0"
        )}:${String(date.getMinutes()).padStart(2, "0")}`;

        return { date: newDateStr, time: newTimeStr };
    }

    useEffect(() => {
        setSelectedStudents(batchDetails?.ActiveStudents);
    }, []);

    const isPastDate = (date, time) => {
        console.log(date, time);
        const [year, month, day] = date.split("-").map(Number);
        const [hour, minute] = time.split(":").map(Number);

        const scheduledDateTime = new Date(year, month - 1, day, hour, minute);
        const currentDateTime = new Date();

        if (scheduledDateTime < currentDateTime) {
            return true;
        } else {
            return false;
        }
    };

    const onSubmit = (data) => {
        const formattedTime = data.Scheduled_StartTime
            ? moment(data.Scheduled_StartTime).format("HH:mm")
            : "";
        const StartDate = moment(data.Scheduled_StartDate).set({
            hour: moment(data?.Scheduled_StartTime).hour(),
            minute: moment(data?.Scheduled_StartTime).minute(),
        });
        const EndDate = moment(data.Scheduled_EndDate).set({
            hour: moment(data?.Scheduled_EndTime).hour(),
            minute: moment(data?.Scheduled_EndTime).minute(),
        });
        const diffInMinutes = EndDate.diff(StartDate, "minutes");
        if (diffInMinutes <= 0) {
            warningSwal("Warning", "Invalid Date time range");
            return;
        }

        /*  if (isPastDate(data.Scheduled_StartDate, formattedTime)) {
            warningSwal(
                "Warning",
                "The scheduled date and time are in the past"
            );
            return;
        } */
        if (selectedStudents.length === 0) {
            warningSwal("warning", "please select atleast one student");
            return;
        }
        const loginData = JSON.parse(localStorage.getItem("admindata"));

        /* setValue();
        setValue(); */
        let body = {
            ...data,
            ScheduleCreatedBy: JSON.parse(localStorage.getItem("admindata"))
                .AdminUserID,
            AdminUserType: loginData.Type,
            ClassDuration: diffInMinutes,
            Type: JSON.parse(localStorage.getItem("admindata")).Type,
            BatchSize: batchDetails.BatchSize,
            CourseID: batchDetails.CourseID,
            Scheduled_StartTime: moment(data?.Scheduled_StartTime).format(
                "HH:mm"
            ),
            Scheduled_EndTime: moment(data?.Scheduled_EndTime).format("HH:mm"),
            CourseName: batchDetails.CourseName,

            Duration: batchDetails.Duration,
            BatchName: batchDetails.BatchName,
            BatchID: batchDetails.BatchID,
            TutorID: batchDetails.TutorID,
            ZoomEmailID: batchDetails.ZoomEmailID,
            TutorName: batchDetails.TutorName,
            Scheduled_StartTime: formattedTime,
            IsSpecialClass: data.IsSpecialClass ? 1 : 0,
            LessonNumber: Number(batchDetails.LessonNumber),
            Students: selectedStudents?.map((item) => {
                return {
                    EnrollCourseID: item.EnrollCourseID,
                    ParentID: item.ParentID,
                    StudentID: item.StudentID,
                    BatchStudentID: item.BatchStudentID,
                    PricePerClass: item.PricePerClass,
                };
            }),
        };
        console.log(body);
        // return;
        setLoading(true);
        Services.createClasses("POST", JSON.stringify(body), token)
            .then((response) => {
                if (response.Status === 1) {
                    localStorage.removeItem("batchDetails");
                    Navigate(-1);
                    SuccessSwal("Successfully created", response.Message);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const starTimeHandler = (e) => {
        console.log(e);
        const { date, time } = addMinutesToDateAndTime(
            formData.Scheduled_StartDate,
            moment(e).format("HH:mm"),
            batchDetails.Duration
        );
        setValue("Scheduled_EndTime", time);
        setValue("Scheduled_EndDate", date);
    };
    const checkedHandler = (e) => {
        if (e.target.checked) {
            let StudentData = batchDetails?.ActiveStudents.filter(
                (item) => item.StudentID === Number(e.target.value)
            );
            setSelectedStudents((prev) => [...prev, ...StudentData]);
        } else {
            setSelectedStudents((prev) =>
                prev.filter((item) => item.StudentID != e.target.value)
            );
        }
    };

    return (
        <Layout Active={"Batches"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Schedule Class</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["wrapper"]}>
                    <h3>Schedule Class</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                Start Date <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("Scheduled_StartDate", {
                                    required: true,
                                })}
                                // min={new Date().toISOString().split("T")[0]}
                            />
                            {errors.Scheduled_StartDate && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div
                            className={`${TutorClasses["form-control"]} ${TutorClasses["form-control-timer"]}`}>
                            <label htmlFor="Last Name">
                                Start Time <span className="important">*</span>
                            </label>
                            <Controller
                                name="Scheduled_StartTime"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TimePicker
                                        {...field}
                                        // onOpen={checkStartDate}
                                        defaultOpenValue={moment()}
                                        disabled={
                                            formData?.Scheduled_StartDate
                                                ?.length === 0
                                        }
                                        // focusOnOpen={true}
                                        minuteStep={5}
                                        allowEmpty={false}
                                        showSecond={false}
                                        placement={"topRight"}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            // starTimeHandler(date);
                                        }}
                                        format="h:mm a"
                                        use12Hours
                                    />
                                )}
                            />
                            {/* <input
                          
                                type="time"
                                {...register("Scheduled_StartTime", {
                                    required: true,

                                    onChange: (e) => starTimeHandler(e),
                                })}
                                step="300"
                            /> */}
                            {errors.Scheduled_StartTime && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">
                                End Date <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("Scheduled_EndDate", {
                                    required: true,
                                })}
                                // min={new Date().toISOString().split("T")[0]}
                            />
                            {errors.Scheduled_EndDate && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div
                            className={`${TutorClasses["form-control"]} ${TutorClasses["form-control-timer"]}`}>
                            <label htmlFor="Last Name">
                                End Time <span className="important">*</span>
                            </label>
                            <Controller
                                name="Scheduled_EndTime"
                                control={control}
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TimePicker
                                        {...field}
                                        // onOpen={checkStartDate}
                                        defaultOpenValue={moment()}
                                        disabled={
                                            formData?.Scheduled_EndDate
                                                ?.length === 0
                                        }
                                        // focusOnOpen={true}
                                        minuteStep={5}
                                        allowEmpty={false}
                                        showSecond={false}
                                        placement={"topRight"}
                                        onChange={(date) => {
                                            field.onChange(date);
                                            // starTimeHandler(date);
                                        }}
                                        format="h:mm a"
                                        use12Hours
                                    />
                                )}
                            />
                            {/* <input
                          
                                type="time"
                                {...register("Scheduled_StartTime", {
                                    required: true,

                                    onChange: (e) => starTimeHandler(e),
                                })}
                                step="300"
                            /> */}
                            {errors.Scheduled_EndTime && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div
                            className={TutorClasses["Table"]}
                            style={{ gridColumn: "1/-1" }}>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Student Name</th>
                                        <th>Email</th>
                                        <th> Mobile</th>
                                        <th>Secondary Mobile</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {batchDetails?.ActiveStudents?.map(
                                        (item, index) => {
                                            return (
                                                <tr
                                                    key={index}
                                                    // onClick={() =>
                                                    //     Navigate(
                                                    //         `/Students/details?id=${item.StudentID}`
                                                    //     )
                                                    // }
                                                >
                                                    <td
                                                        style={{
                                                            width: "2rem",
                                                            cursor: "pointer",
                                                        }}>
                                                        <input
                                                            value={
                                                                item.StudentID
                                                            }
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            defaultChecked
                                                            type="checkbox"
                                                            onChange={
                                                                checkedHandler
                                                            }
                                                        />
                                                    </td>
                                                    <td>{item.StudentName}</td>
                                                    <td>
                                                        {item.StudentEmailID}
                                                    </td>
                                                    <td>{item.ParentMobile}</td>
                                                    <td>
                                                        {
                                                            item.ParentSecondaryMobile
                                                        }
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <label
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    gridColumn: "1/-1",
                                }}>
                                <Controller
                                    name="IsSpecialClass"
                                    control={control}
                                    defaultValue={false} // Set the initial value for the checkbox
                                    render={({ field }) => (
                                        <input type="checkbox" {...field} />
                                    )}
                                />
                                Special Class
                            </label>
                        </div>
                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default AddTutor;
