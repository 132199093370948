import React, { useEffect, useState } from "react";
import ActivityClasses from "./Activity.module.css";
import Skeleton from "./Skeleton";
import { useSearchParams } from "react-router-dom";
import { Services } from "../../../../../Services";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import { toast } from "react-toastify";
function Index() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [Activity, setActivity] = useState([]);
    const [loading, setLoading] = useState(true);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.ActivityLogs("GET", null, token, params.get("id"), 6)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setActivity(res.ScheduleClasess);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <div className={ActivityClasses["profile-data"]}>
                    <div>
                        <h3>Tasks</h3>
                        <span>Not a Client Manager</span>
                    </div>
                    <div className={ActivityClasses["Activity-feed"]}>
                        <h3>Activity Feed</h3>
                        <ul>
                            {Activity.length > 0
                                ? Activity.map((item) => (
                                      <li>
                                          {" "}
                                          <span>
                                              {item.ActionByName} •{" "}
                                              {item.Action}
                                          </span>
                                          <span>
                                              {" "}
                                              <Moment format="Do MMM YYYY hh:mm a">
                                                  {new Date(item.CreatedAt)}
                                              </Moment>
                                          </span>
                                      </li>
                                  ))
                                : "No Logs found"}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
}

export default Index;
