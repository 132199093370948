import React from "react";
import TransactionClasses from "./transactions.module.css";
import Skeleton from "react-loading-skeleton";

function Communications() {
    return (
        <div className={TransactionClasses["Container"]}>
            <table>
                <thead>
                    <tr>
                        {/* <th>Transaction Id</th> */}
                        <th>Date</th>
                        <th>Type</th>
                        <th>Credit</th>
                        <th>Debit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* <td>
                            <Skeleton />
                        </td> */}
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Communications;
