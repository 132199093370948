import Layout from "../../Components/Layout";
import React, { useEffect, useState, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { Error } from "../../Util/Toast";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Services } from "../../Services";
import EventDetails from "./Events";
import eventClasses from "./events.module.css";
import { CalenderDataGenerator, Status } from "./data";
import Select from "react-select";
import MainLoader from "../../Components/loader/loader";
const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

function Calender() {
    const [events, setEvents] = useState([]);
    const [currentDate, setCurrentDate] = useState(null);
    const [load, setLoad] = useState(true);
    const popupRef = useRef(null);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [selectedId, setSelectedId] = useState({
        tutor: null,
        student: null,
        Status: null,
    });
    const message = { Agenda: "list" };
    const [tutors, setTutors] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchString, setTutorSearchString] = useState(null);
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);

    const [selectedStudent, setSelectedStudent] = useState(null);
    const [StudentSearchString, setStudentSearchString] = useState(null);
    const [StudentSearchLoad, setStudentSearchLoad] = useState(false);
    const [student, setStudent] = useState([]);
    const [status, setStatus] = useState(null);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    // GET scheduled Classes
    async function Get_Scheduled_Classes(body) {
        if (!load) {
            setLoad(true);
        }
        try {
            const response = await Services.get_all_scheduledClasses(
                "POST",
                JSON.stringify(body),
                token
            );
            if (response.Status == 1) {
                setTimeout(() => {
                    setLoad(false);
                    setEvents(CalenderDataGenerator(response.ScheduleClasess));
                }, 400);
            }
        } catch (err) {
            console.log(err);
            setLoad(false);
            Error("something went wrong try again");
        }
    }

    useEffect(() => {
        let body = { Scheduled_StartDate: new Date() };
        Get_Scheduled_Classes(body);
        const handleOutsideClick = (e) => {
            if (popupRef.current && !popupRef.current.contains(e.target)) {
                setSelectedEvent(null);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof StudentSearchString == "string" &&
                StudentSearchString.length > 0
            ) {
                setStudentSearchLoad(true);

                try {
                    const data = await Services.searchActiveStudent(
                        "POST",
                        JSON.stringify({ SearchString: StudentSearchString }),
                        token
                    );
                    const formattedOptions = data.Students.map((student) => ({
                        label: `${student.FirstName} ${student.LastName}`,
                        value: student.StudentID,
                    }));
                    setStudent(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setStudentSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [StudentSearchString]);

    useEffect(() => {
        let body = { Scheduled_StartDate: currentDate };
        if (selectedId.tutor) {
            body = { ...body, IsTutorSearch: 1, TutorID: selectedId.tutor };
        }
        if (selectedId.student) {
            body = {
                ...body,
                StudentID: selectedId.student,
                IsStudentSearch: 1,
            };
        }
        if (currentDate) {
            Get_Scheduled_Classes(body);
        }
    }, [currentDate]);
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);

    const handleChange = (selectedOption) => {
        setTutorSearchString(selectedOption);
    };
    const handleChangeStudent = (selectedOption) => {
        setStudentSearchString(selectedOption);
    };
    const onTutorSelect = (option) => {
        if (option) {
            setSelectedId((prev) => {
                return { ...prev, tutor: option.value };
            });
            setSelectedTutor(option);
            let body = {
                IsTutorSearch: 1,
                TutorID: option.value,
                Scheduled_StartDate: currentDate ? currentDate : new Date(),
            };
            if (selectedId.student) {
                body = {
                    ...body,
                    StudentID: selectedId.student,
                    IsStudentSearch: 1,
                };
            }
            if (selectedId.Status) {
                body = {
                    ...body,
                    ClassStatus: selectedId.Status,
                    IsClassStatusSearch: 1,
                };
            }
            Get_Scheduled_Classes(body);
        }
    };
    const onStatusChange = (option) => {
        setSelectedId((prev) => {
            return { ...prev, Status: option.value };
        });
        let body = {
            ClassStatus: option.value,
            IsClassStatusSearch: 1,
            Scheduled_StartDate: currentDate ? currentDate : new Date(),
        };
        if (selectedId.student) {
            body = {
                ...body,
                StudentID: selectedId.student,
                IsStudentSearch: 1,
            };
        }
        setStatus(option);
        if (selectedId.tutor) {
            body = {
                ...body,
                TutorID: selectedId.tutor,
                IsTutorSearch: 1,
            };
        }
        Get_Scheduled_Classes(body);
    };
    const onStudentSelect = (option) => {
        if (option) {
            setSelectedId((prev) => {
                return { ...prev, student: option.value };
            });
            console.log("2");
            setSelectedStudent(option);
            let body = {
                IsStudentSearch: 1,
                StudentID: option.value,
                Scheduled_StartDate: currentDate ? currentDate : new Date(),
            };
            if (selectedId.tutor) {
                body = {
                    ...body,
                    TutorID: selectedId.tutor,
                    IsTutorSearch: 1,
                };
            }
            if (selectedId.Status) {
                body = {
                    ...body,
                    ClassStatus: selectedId.Status,
                    IsClassStatusSearch: 1,
                };
            }
            Get_Scheduled_Classes(body);
        }
    };

    const TutorClearHandler = () => {
        setTutorSearchString(null);
        setSelectedTutor(null);

        setSelectedId((prev) => {
            return { ...prev, tutor: null };
        });
        let body = {
            Scheduled_StartDate: currentDate ? currentDate : new Date(),
        };
        if (selectedId.student) {
            body = {
                ...body,
                StudentID: selectedId.student,
                IsStudentSearch: 1,
            };
        }
        Get_Scheduled_Classes(body);
    };
    const StudentClearHandler = () => {
        setStudentSearchLoad(null);
        setSelectedStudent(null);

        setSelectedId((prev) => {
            return { ...prev, student: null };
        });
        let body = {
            Scheduled_StartDate: currentDate ? currentDate : new Date(),
        };
        if (selectedId.tutor) {
            body = {
                ...body,
                TutorID: selectedId.tutor,
                IsTutorSearch: 1,
            };
        }
        Get_Scheduled_Classes(body);
    };
    const statusClear = () => {
        setStatus(null);

        setSelectedId((prev) => {
            return { ...prev, Status: null };
        });
        let body = {
            Scheduled_StartDate: currentDate ? currentDate : new Date(),
        };
        if (selectedId.tutor) {
            body = {
                ...body,
                TutorID: selectedId.tutor,
                IsTutorSearch: 1,
            };
        }
        if (selectedId.student) {
            body = {
                ...body,
                StudentID: selectedId.student,
                IsStudentSearch: 1,
            };
        }
        Get_Scheduled_Classes(body);
    };
    const eventStyleGetter = (event) => {
        const style = {
            backgroundColor: event.color,
            fontWeight: "550",
            fontSize: "12px",
            color: "white",
        };
        return {
            style,
        };
    };
    function handleNavigate(newDate) {
        console.log(newDate);
        setCurrentDate(newDate);
    }

    //Detailed View
    function handleEventSelect(event, e) {
        setSelectedEvent(event);

        const bounds = e.target.getBoundingClientRect();
        setTooltipPosition({
            top: bounds.top - 10, // Adjust as needed
            left: bounds.right, // Display to the right of the selected date
        });
    }
    const closeTooltip = () => {
        setSelectedEvent(null);
    };
    return (
        <>
            <Layout Active={"Calender"}>
                {load && <MainLoader />}
                <div className={eventClasses["contain"]}>
                    <div className={eventClasses["search"]}>
                        {/* <h3>Filters</h3> */}
                        <div className={eventClasses["form-control"]}>
                            <label htmlFor="Tutor">Tutor</label>
                            <Select
                                options={tutors}
                                onInputChange={handleChange}
                                onChange={onTutorSelect}
                                value={selectedTutor}
                                isLoading={tutorSearchLoad}
                                placeholder="Search Tutor"
                                noOptionsMessage={() => "No Tutor Found"}
                                classNames={eventClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                                isClearable
                                components={{
                                    ClearIndicator: () => (
                                        <button onClick={TutorClearHandler}>
                                            Clear
                                        </button>
                                    ),
                                }}
                            />
                        </div>
                        <div className={eventClasses["form-control"]}>
                            <label htmlFor="Tutor">Student</label>
                            <Select
                                options={student}
                                onInputChange={handleChangeStudent}
                                onChange={onStudentSelect}
                                value={selectedStudent}
                                placeholder="Search Student"
                                isLoading={StudentSearchLoad}
                                noOptionsMessage={() => "No Student Found"}
                                classNames={eventClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                                isClearable
                                components={{
                                    ClearIndicator: () => (
                                        <button onClick={StudentClearHandler}>
                                            Clear
                                        </button>
                                    ),
                                }}
                            />
                        </div>
                        <div className={eventClasses["form-control"]}>
                            <label htmlFor="Tutor">Class Status</label>
                            <Select
                                options={Status}
                                onChange={onStatusChange}
                                value={status}
                                placeholder="By Status"
                                // noOptionsMessage={() => "No Student Found"}
                                classNames={eventClasses["select"]}
                                styles={{
                                    // Set the width to 100%
                                    control: (provided) => ({
                                        ...provided,
                                        width: "100%",
                                    }),
                                }}
                                isClearable
                                components={{
                                    ClearIndicator: () => (
                                        <button onClick={statusClear}>
                                            Clear
                                        </button>
                                    ),
                                }}
                            />
                        </div>
                    </div>
                    <div className={eventClasses["calender-wrapper"]}>
                        <div className={eventClasses["calender"]}>
                            <DnDCalendar
                                defaultDate={moment().toDate()}
                                defaultView="month"
                                events={events}
                                eventPropGetter={eventStyleGetter}
                                localizer={localizer}
                                resizable
                                handleDragStart={() => console.log("1")}
                                style={{ height: "90vh", width: "100%" }}
                                onSelectEvent={handleEventSelect}
                                popup
                                eventOverlap="yes"
                                timeslots={1}
                                dayLayoutAlgorithm={"no-overlap"}
                                messages={{
                                    agenda: "List",
                                    previous: "Previous",
                                }}
                                onNavigate={handleNavigate}
                            />

                            {selectedEvent && (
                                <div
                                    className="event-tooltip"
                                    ref={popupRef}
                                    style={{
                                        top: tooltipPosition.top,
                                        left: tooltipPosition.left,
                                    }}>
                                    <EventDetails
                                        event={selectedEvent}
                                        closeTooltip={closeTooltip}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
}

export default Calender;
