import React from "react";
import CommunicationClasses from "./communications.module.css";
import Skeleton from "react-loading-skeleton";
function Communications() {
    return (
        <div className={CommunicationClasses["Container"]}>
            <h3>Releated Emails</h3>
            <table>
                <thead>
                    <tr>
                        <th>Subject</th>
                        <th>Message</th>
                        <th>Send Time</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Communications;
