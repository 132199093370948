import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { SuccessSwal, warningSwal } from "Util/Toast";
import EarningClasses from "./Earning.module.css";
import Moment from "react-moment";
import { Services } from "Services";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmationDiaolog } from "Util/Toast";
import Loader from "Components/loader/loader";
import { AccessControl } from "../../../../../Util/AccessControl";
import { AccessKeys } from "../../../../../Routes/config";
import Penalty from "./../Penalty";
function Earnings({ payoutId, setType, Type }) {
    const Navigate = useNavigate();
    const [StatusLoader, setStatusLoader] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showPenalty, setShowPenalty] = useState(false);
    const [penaltyDetails, setPenaltyDetails] = useState({});
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [params] = useSearchParams();

    const [earning, setEarning] = useState({
        Earnings: [],
        CreatedAt: new Date(),
        AmountToPay: 0,
        PayoutStatus: 2,
    });
    const get_Earnings = (body) => {
        Services.PayoutDetails("POST", JSON.stringify(body), token)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setStatusLoader(false);
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setEarning(res.Get_Payout_Earnings[0]);
                } else if (res.Status === 0) {
                    warningSwal("Error", res?.Message);
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                if (StatusLoader) {
                    setStatusLoader(false);
                }
                // alert(err);
                warningSwal("Error", "Something went wrong ");
                console.log(err);
            });
    };
    useEffect(() => {
        get_Earnings({ PayoutID: payoutId });
    }, []);
    const paidHandler = () => {
        const confirmPayout = () => {
            setStatusLoader(true);
            Services.update_payouts(
                "PUT",
                JSON.stringify({ PayoutStatus: 1 }),
                token,
                payoutId
            )
                .then((res) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 300);
                    if (res.Status === 1) {
                        SuccessSwal("success", res?.Message);
                        get_Earnings({ PayoutID: payoutId });
                    } else if (res.Status === 0) {
                        warningSwal("Error", res?.Message);
                        if (res.Message === "Token expired") {
                            Navigate("/");
                        }
                    }
                })
                .catch((err) => {
                    setLoading(false);
                    // alert(err);
                    warningSwal("Error", "Something went wrong ");
                    console.log(err);
                });
        };
        ConfirmationDiaolog(
            "Are You sure you want to Mark Payout as Paid?",
            "Mark Paid",
            confirmPayout
        );
    };
    const checkPenalty = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("id");
        // id={TutorEarningID}
        let [earningData] = earning.Earnings.filter(
            (item) => item.TutorEarningID == id
        );
        setPenaltyDetails(earningData);
        setShowPenalty(true);
    };
    return (
        <>
            {StatusLoader && <Loader />}
            {showPenalty && (
                <Penalty
                    modal={showPenalty}
                    setModal={setShowPenalty}
                    getDetails={{
                        get_Earnings,
                        penaltyDetails: penaltyDetails,
                        TutorID: params.get("TutorId"),
                        payoutId: payoutId,

                        payoutDetails: true,
                    }}
                />
            )}
            <ol className={EarningClasses["breadcrumb"]}>
                <li style={{ cursor: "pointer" }} onClick={() => setType(1)}>
                    <span>Payouts</span>
                </li>
                <li className={EarningClasses["current"]}>
                    <span>{payoutId}</span>
                </li>
            </ol>
            <div className={EarningClasses["details_wrapper"]}>
                <div className={EarningClasses["Payout-details"]}>
                    <header>
                        <div>
                            <h3>Payout Details</h3>
                            <p
                                className={
                                    earning?.PayoutStatus === 1
                                        ? EarningClasses["paid"]
                                        : EarningClasses["unpaid"]
                                }>
                                {earning?.PayoutStatus === 1
                                    ? "paid"
                                    : "pending"}
                            </p>
                        </div>{" "}
                        {earning.PayoutStatus === 2 && (
                            <button onClick={paidHandler}>Mark as Paid</button>
                        )}{" "}
                    </header>
                    <div className={EarningClasses["payout_data"]}>
                        <div>
                            <p>Amount :</p>
                            <h4>
                                ₹{" "}
                                {isNaN(earning.AmountToPay)
                                    ? 0.0
                                    : Number(earning.AmountToPay).toFixed(2)}
                            </h4>
                        </div>
                        <div>
                            <p>Payout Date :</p>
                            <h4>
                                <Moment format="DD-MM-YYYY">
                                    {new Date(earning.CreatedAt)}
                                </Moment>
                            </h4>
                        </div>
                    </div>
                </div>

                <div className={EarningClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Class Date</th>
                                <th>Class Time</th>
                                <th>Duration</th>
                                <th>Batch Name</th>
                                <th>Type</th>
                                <th>Credit</th>
                                <th> Debit</th>
                                {AccessControl(AccessKeys.Tutor)?.edit ===
                                    1 && <th>Actions</th>}
                            </tr>
                        </thead>
                        {loading ? (
                            <tbody>
                                {[1, 1].map((item) => (
                                    <tr className={EarningClasses["loading"]}>
                                        <td>
                                            <Skeleton />
                                        </td>
                                        <td>
                                            <Skeleton />
                                        </td>

                                        <td>
                                            <Skeleton />
                                        </td>
                                        <td>
                                            <Skeleton />
                                        </td>
                                        <td>
                                            <Skeleton />
                                        </td>
                                        <td>
                                            <Skeleton />
                                        </td>
                                        <td>
                                            <Skeleton />
                                        </td>
                                        {AccessControl(AccessKeys.Tutor)
                                            ?.edit === 1 && (
                                            <td>
                                                <Skeleton />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        ) : (
                            <tbody>
                                {earning?.Earnings?.length > 0 ? (
                                    earning?.Earnings?.map((item) => {
                                        return (
                                            <tr>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {
                                                            new Date(
                                                                item.Scheduled_StartDate
                                                            )
                                                        }
                                                    </Moment>
                                                </td>
                                                <td>
                                                    {item.Scheduled_StartTime}
                                                </td>

                                                <td>
                                                    {item.DurationInMinutes} Min
                                                </td>
                                                <td
                                                    style={{
                                                        maxWidth: "12rem",
                                                        wordBreak: "break-word",
                                                    }}>
                                                    {item.BatchName}
                                                </td>
                                                <td>
                                                    {item.EarningType ===
                                                        "Lesson_Cancel" &&
                                                        "Lesson Cancel"}
                                                    {item.EarningType ===
                                                        "Lesson_Complete" &&
                                                        "Lesson Complete"}
                                                    {/* <span
                                                    className={
                                                        item.PayoutStatus === 1
                                                            ? EarningClasses[
                                                                  "paid"
                                                              ]
                                                            : EarningClasses[
                                                                  "unpaid"
                                                              ]
                                                    }>
                                                    {item.PayoutStatus === 1
                                                        ? "paid"
                                                        : "pending"}
                                                </span>{" "} */}
                                                </td>
                                                <td
                                                    style={{
                                                        color:
                                                            String(
                                                                item.Credit_Or_Debit
                                                            ).toLowerCase() ===
                                                            "DEBIT".toLowerCase()
                                                                ? "red"
                                                                : "green",
                                                    }}>
                                                    {item.Credit_Or_Debit !=
                                                    "DEBIT"
                                                        ? isNaN(item.Amount)
                                                            ? 0.0
                                                            : `₹ ${Number(
                                                                  item.Amount
                                                              ).toFixed(2)}`
                                                        : "-"}
                                                </td>
                                                <td
                                                    style={{
                                                        color:
                                                            String(
                                                                item.Credit_Or_Debit
                                                            ).toLowerCase() ===
                                                            "DEBIT".toLowerCase()
                                                                ? "red"
                                                                : "green",
                                                    }}>
                                                    {item.Credit_Or_Debit ==
                                                    "DEBIT"
                                                        ? isNaN(item.Amount)
                                                            ? 0.0
                                                            : `₹ ${Number(
                                                                  item.Amount
                                                              ).toFixed(2)}`
                                                        : "-"}
                                                </td>
                                                {AccessControl(AccessKeys.Tutor)
                                                    ?.edit === 1 && (
                                                    <td>
                                                        {item.IsPenalityChallenged ===
                                                        2 ? (
                                                            <>
                                                                <button
                                                                    id={
                                                                        item.TutorEarningID
                                                                    }
                                                                    onClick={
                                                                        checkPenalty
                                                                    }
                                                                    style={{
                                                                        padding:
                                                                            "0.5rem",
                                                                        border: "1px solid #ccc",
                                                                        backgroundColor:
                                                                            "white",
                                                                    }}>
                                                                    View Penalty
                                                                    Challenge
                                                                </button>
                                                            </>
                                                        ) : (
                                                            "NA"
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td
                                            colSpan={8}
                                            style={{ textAlign: "center" }}>
                                            No Record Found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        )}
                    </table>
                </div>
            </div>
        </>
    );
}

export default Earnings;
