import React, { useState, useEffect } from "react";
import TutorClasses from "./config.module.css";
import Layout from "../../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../../Services";
import SkeletonLoad from "./TableLoad/load";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import { FaRegEdit } from "react-icons/fa";
import { warningSwal } from "../../../Util/Toast";
import { AccessKeys } from "../../../Routes/config";
import { AccessControl } from "../../../Util/AccessControl";
function Tutor() {
    const Navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        Services.get_config("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Configuration);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories(2);
        // eslint-disable-next-line
    }, []);
    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");

        let values = data.filter((item) => item.ConfigID == id);
        localStorage.setItem("configvalue", JSON.stringify(values[0]));
        Navigate(`/config/edit?id=${id}`);
    };

    return (
        <Layout Active={"Configuration"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Configuration</title>
            </Helmet>
            <div className={TutorClasses["Container"]}>
                <div className={TutorClasses["control-btns"]}>
                    {AccessControl(AccessKeys.Categories).write == 1 && (
                        <button
                            className={TutorClasses["button"]}
                            onClick={() => {
                                Navigate("/config/add");
                            }}>
                            <BiPlus size={20} /> Create New Config
                        </button>
                    )}
                </div>
                <div className={TutorClasses["Tutor"]}>
                    <h3>Configurations</h3>
                    {/* <h2>All</h2>{" "} */}
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={TutorClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Value</th>
                                        <th>Section</th>
                                        <th>Description</th>
                                        {AccessControl(AccessKeys.Config)
                                            ?.edit === 1 && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr>
                                            <td>{item.Key}</td>
                                            <td>{item.Value}</td>
                                            <td
                                                style={{
                                                    paddingLeft: "0.3rem",
                                                }}>
                                                {item.Section}
                                            </td>
                                            <td>{item.Description}</td>
                                            {AccessControl(AccessKeys.Config)
                                                ?.edit === 1 && (
                                                <td>
                                                    <div
                                                        className={
                                                            TutorClasses[
                                                                "Action-btns"
                                                            ]
                                                        }>
                                                        <button
                                                            id={item.ConfigID}
                                                            onClick={
                                                                editHandler
                                                            }>
                                                            <FaRegEdit />
                                                        </button>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <p>No Config Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
