import React, { useState } from "react";
import Layout from "../../../../Components/Layout";
import detailsClass from "./index.module.css";
import { FaUser } from "react-icons/fa";
import { AiFillThunderbolt, AiFillStar } from "react-icons/ai";
import { MdOutlineEmail } from "react-icons/md";
import Profile from "./Profile/profile";
import { Helmet } from "react-helmet";
import Activity from "./Activity/Activity";
import { Services } from "../../../../Services";
import Communications from "./Communications";
import { FaLayerGroup } from "react-icons/fa";

import WalletTransactions from "./WalletTransactions/transactions";
import Review from "./Reviews";
import { GiWallet } from "react-icons/gi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AccessControl } from "../../../../Util/AccessControl";
import { AccessKeys } from "../../../../Routes/config";
import { SuccessSwal, warningSwal } from "../../../../Util/Toast";
import PaymentLink from "./PaymentLinks/links";
import Batches from "./Batches/links";
function Index() {
    const Navigate = useNavigate();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [params] = useSearchParams();
    const [isActive, setIsActive] = useState("Profile");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const tabHandler = (e) => {
        const btnType = e.currentTarget.getAttribute("btn-type");
        setIsActive(btnType);
        setLoading(true);
    };
    const backButton = () => {
        Navigate(-1);
    };
    const updateHandler = (e) => {
        let btnType = e.target.value;
        let body = {
            Status: btnType,
            LastName: data?.LastName,
            FirstName: data?.FirstName,
        };
        console.log(params.get("Id"));
        Services.UpdateParent(
            "PUT",
            JSON.stringify(body),
            token,
            params.get("Id")
        )
            .then((response) => {
                if (response.Status === 1) {
                    setData((prev) => ({ ...prev, Status: Number(btnType) }));
                    SuccessSwal("Success", response.Message);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <Layout Active={"Parent"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Parent</title>
            </Helmet>
            <div className={detailsClass["Container"]}>
                <button onClick={backButton} className={detailsClass["back"]}>
                    Back
                </button>
                <div className={detailsClass["wrapper"]}>
                    <h3>
                        <span></span>Parent:{data?.FirstName} {data?.LastName}
                        {AccessControl(AccessKeys.Parents)?.edit === 1 && (
                            <div className={detailsClass["status-buttons"]}>
                                <select
                                    onChange={updateHandler}
                                    value={data?.Status}>
                                    <option value={1}>Active</option>
                                    <option value={2}>Dormant</option>
                                </select>
                            </div>
                        )}
                    </h3>
                    <div className={detailsClass["control-btns"]}>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Profile"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Profile"
                            onClick={tabHandler}>
                            <FaUser /> Profile
                        </button>

                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Activity"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Activity"
                            onClick={tabHandler}>
                            <AiFillThunderbolt />
                            Activity
                        </button>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Email"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Email"
                            onClick={tabHandler}>
                            <MdOutlineEmail />
                            Communications
                        </button>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Review"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Review"
                            onClick={tabHandler}>
                            <AiFillStar />
                            Reviews
                        </button>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Transactions"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Transactions"
                            onClick={tabHandler}>
                            <GiWallet />
                            Wallet Transactions
                        </button>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Payment Links"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Payment Links"
                            onClick={tabHandler}>
                            <GiWallet />
                            Payment Links
                        </button>
                        <button
                            className={`${detailsClass["button"]} ${
                                isActive == "Batches"
                                    ? detailsClass["btn-active"]
                                    : ""
                            }`}
                            btn-type="Batches"
                            onClick={tabHandler}>
                            <FaLayerGroup />
                            Batches
                        </button>
                    </div>
                    {isActive === "Profile" && (
                        <Profile
                            loading={loading}
                            setLoading={setLoading}
                            data={data}
                            setData={setData}
                        />
                    )}
                    {isActive === "Activity" && <Activity />}
                    {isActive === "Email" && <Communications />}
                    {isActive === "Review" && <Review />}
                    {isActive === "Transactions" && <WalletTransactions />}
                    {isActive === "Payment Links" && <PaymentLink />}
                    {isActive === "Batches" && <Batches />}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
