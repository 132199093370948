import React, { useState } from "react";
import Modal from "react-modal";
import Styles from "./add.module.css";
import { Services } from "../../../Services";
import ReactLoading from "react-loading";
import { useForm } from "react-hook-form";
import { SuccessSwal, warningSwal } from "../../../Util/Toast";
function Add({
    completemodal,
    batchData,
    setModal,
    batchId,
    getBatchDetails,
    CompletedClasses,
}) {
    const [loading, setLoading] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useForm();
    const formData = watch();
    async function onSubmit(data) {
        setLoading(true);
        let formContent = { ...data };
        if (data.Status === "6" && data.discontune !== "others") {
            formContent = { ...data, BatchFinishComments: data.discontune };
        }
        delete formContent.discontune;
        if (data.Status === "4") {
            delete formContent.BatchFinishComments;
        }
        if (data.Status === "3") {
            formContent.BatchFinishComments = "Course Completed";
        }
        let RMS = [
            batchData.Buddy_RM,
            batchData.SecondShift_RM_Morning,
            batchData.SecondShift_RM_Evening,
            batchData.Lead_RM,
        ];
        try {
            const batchDetails = await Services.complete_batch(
                "POST",
                JSON.stringify({
                    ...formContent,
                    BatchID: batchId,
                    BatchName: batchData.BatchName,
                    TutorName: batchData.TutorName,
                    CourseName: batchData.CourseName,
                    CompletedClasses: CompletedClasses,
                    BatchStudents: batchData.ActiveStudents,
                    TutorRms: RMS.filter((item) => item != 0),
                    TutorID: batchData.TutorID,
                }),
                token
            );
            if (batchDetails.Status == 1) {
                setModal(false);
                setLoading(false);
                reset({ Status: 0 });
                getBatchDetails(batchId);
                SuccessSwal(batchDetails.Message, "");
            } else {
                setLoading(false);
                warningSwal("", batchDetails.Message);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
        }
    }
    return (
        <div>
            <Modal
                isOpen={completemodal}
                contentLabel="Modal"
                onRequestClose={() => {
                    setModal(false);
                    reset({ Status: 0 });
                }}
                className={Styles["modal"]}
                overlayClassName={Styles["overlay"]}>
                <div className={Styles["wrapper"]}>
                    <header>
                        <h3>Complete Batch</h3>
                    </header>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Reason">Completion Reason</label>
                            <select
                                defaultValue={""}
                                {...register("Status", {
                                    required: true,
                                })}>
                                <option value={0} disabled>
                                    Select Reason
                                </option>
                                <option value="4">Gone Cold</option>
                                <option value="3">Course Completed</option>
                                <option value="5">Batch Dissolved</option>
                                <option value="6">Parent discontinued</option>
                            </select>
                            {errors.Status && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>

                        {formData.Status === "6" && (
                            <div className={Styles["form-control"]}>
                                <label htmlFor="Reason">
                                    Reason for discontinue
                                </label>
                                <select
                                    defaultValue={""}
                                    {...register("discontune", {
                                        required: true,
                                    })}>
                                    <option value="">Select Reason</option>
                                    <option
                                        value="Hectic schedule for the child with
                                        school">
                                        Hectic schedule for the child with
                                        school
                                    </option>
                                    <option value="Looking for offline classes">
                                        Looking for offline classes
                                    </option>
                                    <option
                                        value="Child not getting adjusted to online
                                        classes">
                                        Child not getting adjusted to online
                                        classes
                                    </option>
                                    <option value="  Did not like the teacher">
                                        Did not like the teacher
                                    </option>
                                    <option value="Did not like to course content">
                                        Did not like to course content
                                    </option>
                                    <option
                                        value="Wants to go on a break, will get back
                                        once they are ready again">
                                        Wants to go on a break, will get back
                                        once they are ready again
                                    </option>
                                    <option value="others">others</option>
                                </select>
                                {errors.discontune && (
                                    <span style={{ color: "red" }}>
                                        This is required
                                    </span>
                                )}
                            </div>
                        )}
                        {(formData.Status === "5" ||
                            (formData.discontune === "others" &&
                                formData.Status === "6")) && (
                            <div className={Styles["form-control"]}>
                                <label htmlFor="Reason">
                                    {formData.Status === "6"
                                        ? "discontinue"
                                        : "disolved"}{" "}
                                    Reason
                                </label>
                                <textarea
                                    {...register("BatchFinishComments", {
                                        required: true,
                                    })}></textarea>
                                {errors.BatchFinishComments && (
                                    <span style={{ color: "red" }}>
                                        This is required
                                    </span>
                                )}
                            </div>
                        )}
                        {loading ? (
                            <div
                                style={{
                                    justifySelf: "center",
                                    marginTop: "1rem",
                                }}>
                                {" "}
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            </div>
                        ) : (
                            <div className={Styles["Control-btns"]}>
                                <button
                                    type="button"
                                    onClick={() => setModal(false)}>
                                    Cancel
                                </button>
                                <button type="submit">Save</button>
                            </div>
                        )}
                    </form>
                </div>
            </Modal>
        </div>
    );
}

export default Add;
