import React, { useState, useEffect } from "react";
import ProfileData from "../../../../../Components/profile/Profile";
import SkeletonLoad from "./Skeleton";
import ProfileClasses from "./profile.module.css";
import { Services } from "../../../../../Services";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import pdf from "../../../../../Assets/pdf.png";
import { useNavigate } from "react-router-dom";
function Profile({ loading, setLoading, data, setData }) {
    const Navigate = useNavigate();
    const [params] = useSearchParams();

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.getStudentDetailsByID("GET", null, token, params.get("id"))
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Students);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    const update = () => {
        Navigate(`/student/update?StudentID=${data.StudentID}`);
    };
    return (
        <div>
            {loading ? (
                <SkeletonLoad />
            ) : (
                <>
                    <ProfileData
                        data={data}
                        update={update}
                        id={data.StudentID}
                    />
                    <div className={ProfileClasses["profile-data"]}>
                        <div>
                            <h3>Associated Parents</h3>
                            <button
                                onClick={() => {
                                    Navigate(
                                        `/parent/Details?Id=${data?.Associated_Parents?.ParentID}`
                                    );
                                    localStorage.setItem(
                                        "name",
                                        `${data?.Associated_Parents?.FirstName} ${data?.Associated_Parents?.LastName}`
                                    );
                                }}>
                                {data?.Associated_Parents?.FirstName != "NA" &&
                                    data?.Associated_Parents?.FirstName}
                                {"  "}
                                {data?.Associated_Parents?.LastName != "NA" &&
                                    data?.Associated_Parents?.LastName}
                                {data.RelationWithParent != "NA" &&
                                    `(${data.RelationWithParent})`}
                            </button>
                            {/* <p>View Accounting</p> */}
                        </div>
                        <div>
                            <h3>Notes</h3>
                            <p>No Notes </p>
                        </div>
                        <div>
                            <h3>Academic Details</h3>
                            <p>
                                {data.SchoolName != "NA"
                                    ? `${data.SchoolName}(${data.AcademicYear})`
                                    : "NA"}{" "}
                            </p>
                        </div>
                        <div>
                            <h3>Uploaded Documents</h3>
                            {data?.Documents?.length > 0 ? (
                                <div
                                    className={
                                        ProfileClasses["updateddocuments"]
                                    }>
                                    {data.Documents.map((item) => {
                                        return item?.Document?.split(".")[1] ===
                                            "pdf" ? (
                                            <img
                                                src={pdf}
                                                onClick={() =>
                                                    window.open(
                                                        item.Get_Documents,
                                                        "_blank"
                                                    )
                                                }
                                            />
                                        ) : (
                                            <img
                                                src={item.Get_Documents}
                                                onClick={() =>
                                                    window.open(
                                                        item.Get_Documents,
                                                        "_blank"
                                                    )
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            ) : (
                                <p>No Documents Found</p>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Profile;
