import React from "react";
import Skeleton from "react-loading-skeleton";
import RegisterClasses from "./Activity.module.css";
function SkeletonLoad() {
    return (
        <div className={RegisterClasses["Container"]}>
            <div className={RegisterClasses["Container"]}>
                <div className={RegisterClasses["jobs"]}>
                    <header>
                        <div className={RegisterClasses["header-left"]}>
                            <Skeleton count={2} width={"50%"} />
                        </div>
                        <div className={RegisterClasses["header-right"]}>
                            <Skeleton count={2} />
                        </div>
                    </header>
                </div>
                <div className={RegisterClasses["Assigned-Jobs"]}>
                    <div className={RegisterClasses["table-container"]}>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <Skeleton count={1} />
                                    </th>
                                    <th>
                                        <Skeleton count={1} />
                                    </th>
                                    <th>
                                        <Skeleton count={1} />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={RegisterClasses["applications"]}>
                    <header>
                        <div className={RegisterClasses["header-left"]}>
                            <Skeleton count={2} width={"70%"} />
                            <Skeleton count={2} width={"70%"} />
                        </div>
                        <div className={RegisterClasses["header-righ"]}>
                            <Skeleton
                                count={2}
                                width={"70%"}
                                style={{ marginLeft: "1rem" }}
                            />
                            <Skeleton
                                count={2}
                                width={"70%"}
                                style={{ marginLeft: "1rem" }}
                            />
                        </div>
                    </header>
                </div>
            </div>
        </div>
    );
}

export default SkeletonLoad;
