import React from "react";
import CommunicationClasses from "./communications.module.css";
function Communications() {
    return (
        <div className={CommunicationClasses["Container"]}>
            <h3>Releated Emails</h3>
            <table>
                <thead>
                    <tr>
                        {/*    <th>Subject</th>
                        <th>Status</th>
                        <th>Send Time</th> */}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {/* <td>
                            <a href="/" target="_blank">
                                Welcome to Learn2Read
                            </a>
                        </td>
                        <td>Opened & clicked on</td>
                        <td>03/02/2023 5:17 pm</td> */}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Communications;
