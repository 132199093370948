import React from "react";
import Skeleton from "react-loading-skeleton";
import loadClasses from "./load.module.css";
function Load() {
    return (
        <div className={loadClasses["Table"]}>
            <table>
                <thead>
                    <tr>
                        <th>Plan Number</th>
                        <th>Topic</th>
                        <th>Song URL </th>
                        <th>Lesson PPT</th>
                        <th>Story URL</th>
                        <th>Homework URL</th>
                        <th>Games URL</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Array(3)
                        .fill()
                        .map(() => (
                            <tr>
                                <td>
                                    <Skeleton height={"1rem"} />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                                <td>
                                    <Skeleton />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}

export default Load;
