import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../../../../Components/Layout";
import AddClasses from "./add.module.css";
import { useForm } from "react-hook-form";
import ReactLoading from "react-loading";
import { Services } from "../../../../Services";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import user from "../../../../Assets/user.svg";
import { useSearchParams } from "react-router-dom";
import { config } from "../../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import S3FileUpload from "react-s3";
import { compareObjects, CheckIsObjectEmpty } from "Util/Util";
import { NullCheck, ValidateInput } from "../../../../Components/validators";
import { warningSwal, SuccessSwal } from "../../../../Util/Toast";
function Index() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [base64, setBase64] = useState(user);
    const [savedData, setSavedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [parents, setParents] = useState([]);
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        setError,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        //ListAllParents
        if (!params.get("StudentID")) {
            Services.ListAllParents_Dropdown(
                "get",
                null,
                token,
                params.get("id")
            )
                .then((response) => {
                    setLoading(false);

                    if (response.Status === 1) {
                        setParents(response.Parents);
                        if (params.get("parentId")) {
                            reset({ ParentID: params.get("parentId") });
                        }
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        if (params.get("StudentID")) {
            setLoading(true);
            setEdit(true);
            Services.getStudentDetailsByID(
                "GET",
                null,
                token,
                params.get("StudentID")
            )
                .then((response) => {
                    setLoading(false);
                    setEdit(false);
                    if (response.Status === 1) {
                        reset({
                            RelationWithParent:
                                response.Students.RelationWithParent,
                            ParentID: response.Students.ParentID,
                            ParentName: response.Students.ParentName,
                            AcademicYear: response.Students.AcademicYear,
                            SchoolName: response.Students.SchoolName,
                            DOB: String(response.Students.DOB).substring(0, 10),
                            Gender: response.Students.Gender,
                            LastName: response.Students.LastName,
                            FirstName: response.Students.FirstName,
                        });
                        setSavedData({
                            RelationWithParent:
                                response.Students.RelationWithParent,
                            ParentID: response.Students.ParentID,
                            AcademicYear: response.Students.AcademicYear,
                            SchoolName: response.Students.SchoolName,
                            DOB: String(response.Students.DOB).substring(0, 10),
                            Gender: response.Students.Gender,
                            LastName: response.Students.LastName,
                            FirstName: response.Students.FirstName,
                            ProfilePicture: response.Students.ProfilePicture,
                        });
                        setBase64(response.Students.ProfilePicture);
                    } else if (response.Status === 0) {
                        toast.error(`${response.Message}`, {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    setEdit(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    }, []);
    function calculateAge(birthdate) {
        const birthDate = new Date(birthdate);
        const currentDate = new Date();

        const ageInMilliseconds = currentDate - birthDate;
        const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); // Taking into account leap years

        const years = Math.floor(ageInYears);
        const months = Math.floor((ageInYears - years) * 12);

        return `${years}.${months}`;
    }

    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    const onSubmit = (data) => {
        let body = {
            ...data,
            Notes: "",
        };
        // const currDate = new Date();
        const diff = calculateAge(data.DOB);

        if (parseInt(diff) < 2 || parseInt(diff) > 12) {
            console.log("121");
            setError("DOB", { message: "Age should be between 2 to 12 years" });
            return;
        }
        localStorage.setItem("name", data.FirstName);

        if (params.get("StudentID")) {
            let newBody = compareObjects(body, savedData);

            if (CheckIsObjectEmpty(newBody)) {
                warningSwal("Warning", "Nothing to update");
                return;
            }
            setLoading(true);
            Services.updateStudent(
                "PUT",
                JSON.stringify(newBody),
                token,
                params.get("StudentID")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
            return;
        }
        setLoading(true);
        Services.AddStudent("POST", JSON.stringify(body), token)
            .then((response) => {
                if (response.Status === 1) {
                    Navigate(-1);
                    SuccessSwal("Success", response.Message);
                } else if (response.Status === 0) {
                    setLoading(false);
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                // alert(err);
                setLoading(false);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const profilePicUpload = (files) => {
        if (
            files.type === "image/png" ||
            files.type === "image/jpeg" ||
            files.type === "image/jpg"
        ) {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "Students/ProfilePictures",
            })
                .then((res) => {
                    setValue(
                        "ProfilePicture",
                        `${timestamp}.${String(files.type).split("/")[1]}`
                    );
                    setBase64(res.location);
                    // if (edit) {
                    //     setEditUploadData((prev) => [...prev, data]);
                    // }
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`${files.name} is neither PNG Nor JPEG`);
            return false;
        }
        return true;
    };
    const profileupload = (
        <div className={AddClasses["profile-img"]}>
            <img src={base64} alt={"profilepic"} />
        </div>
    );
    const backButton = () => {
        Navigate(-1);
    };
    const NullCheck_Names = (value) => {
        if (!value) {
            return "This field is required";
        }

        // Check for alphabetic characters without numbers or special characters
        if (/^[a-zA-Z ]+$/.test(value)) {
            return true; // Validation passed
        } else {
            return "Only alphabetic characters are allowed";
        }
    };
    return (
        <Layout Active={"Students"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                    {params.get("StudentID") ? "Update" : "Add"} Stundent
                </title>
            </Helmet>
            <div className={AddClasses["Container"]}>
                <button onClick={backButton} className={AddClasses["back"]}>
                    Back
                </button>
                <div className={AddClasses["wrapper"]}>
                    <h3>
                        {!params.get("StudentID")
                            ? "Add Student"
                            : "Update Student"}
                    </h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={AddClasses["profile-upload"]}>
                            <div>
                                <FileUploader
                                    classes={AddClasses["profile-upload-input"]}
                                    multiple={false}
                                    handleChange={profilePicUpload}
                                    name="file"
                                    children={profileupload}
                                    // types={fileTypes}
                                />
                            </div>
                        </div>
                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="First Name">
                                First Name <span className="important">*</span>
                            </label>
                            <input
                                {...register("FirstName", {
                                    required: "this field is required",
                                    // validate: NullCheck_Names,
                                })}
                            />
                            {errors.FirstName && (
                                <span>{errors?.FirstName.message}</span>
                            )}
                        </div>
                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="Last Name">
                                Last Name <span className="important">*</span>
                            </label>
                            <input
                                {...register("LastName", {
                                    required: "this field is required",
                                    validate: NullCheck_Names,
                                })}
                            />
                            {errors.LastName && (
                                <span>{errors?.LastName.message}</span>
                            )}
                        </div>

                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="DOB">
                                DOB <span className="important">*</span>
                            </label>
                            <input
                                type="date"
                                {...register("DOB", {
                                    required: "this field is required",
                                })}
                                min={
                                    (new Date().getFullYear() - 12).toString() +
                                    "-01-01"
                                }
                                max={
                                    (new Date().getFullYear() - 2).toString() +
                                    "-12-31"
                                }
                            />
                            {errors.DOB && <span>{errors.DOB.message}</span>}
                        </div>
                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="Gender">Gender</label>
                            <select
                                {...register("Gender", {
                                    required: false,
                                })}>
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                            </select>
                            {errors.Gender && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                School Name <span className="important">*</span>
                            </label>
                            <input
                                type="text"
                                {...register("SchoolName", {
                                    required: "This field is required",
                                    validate: NullCheck_Names,
                                })}
                            />
                            {errors.SchoolName && (
                                <span>{errors?.SchoolName.message}</span>
                            )}
                        </div>
                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Academic Year{" "}
                            </label>
                            <input
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                type="text"
                                {...register("AcademicYear", {
                                    required: false,
                                    /*  validate: (val) =>
                                        ValidateInput(
                                            val,
                                            "Enter a valid Academic Year"
                                        ), */
                                })}
                            />
                            {errors.AcademicYear && (
                                <span>This field is required</span>
                            )}
                        </div>

                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Parent <span className="important">*</span>
                            </label>
                            {params.get("StudentID") ? (
                                <input
                                    disabled
                                    {...register("ParentName", {
                                        required: false,
                                    })}
                                />
                            ) : (
                                <select
                                    defaultValue={""}
                                    style={{ width: "100%" }}
                                    disabled={
                                        params.get("StudentID") != null ||
                                        params.get("parentId")
                                    }
                                    {...register("ParentID", {
                                        required: true,
                                        // validate: NullCheck,
                                    })}>
                                    <option value="" disabled>
                                        Select Parent
                                    </option>
                                    {parents?.map((item) => (
                                        <option value={item.ParentID}>
                                            {item.FirstName} {item.LastName}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {errors.ParentID && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={AddClasses["form-control"]}>
                            <label htmlFor="ContactNumber">
                                Relation With Parent{" "}
                            </label>
                            <input
                                type="text"
                                {...register("RelationWithParent", {
                                    required: false,
                                    // validate: NullCheck,
                                })}
                            />
                            {errors.RelationWithParent && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Index;
