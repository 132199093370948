import randomColor from "randomcolor";

export const Status = [{ label: 'Active', value: '1' }, { label: 'Completed', value: '2' }, { label: 'Cancelled', value: "3" }]


const timeConversion = (inputTimeString) => {

 // Parse the input time string
 const inputTime = new Date(`2000-01-01T${inputTimeString}`);

 // Convert to AM/PM format with local time zone
 const formattedTime = inputTime.toLocaleTimeString(undefined, {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
 });
 return formattedTime
}

const DateTimeGenerator = (time, dateStr) => {
 const [hours, minutes] = time.split(":").map(Number);
 const formatted_date = new Date(dateStr);
 formatted_date.setUTCHours(hours);
 formatted_date.setUTCMinutes(minutes);
 const istOffset = 5.5 * 60 * 60 * 1000;
 return new Date(formatted_date.getTime() - istOffset);
};

export const CalenderDataGenerator = (data) => {
 let calenderData = data.map((item, index) => {
  return {
   id: index,
   start: DateTimeGenerator(item.Scheduled_StartTime, item.Scheduled_StartDate),
   title: `${item.ClassStatus == 3 ? "✖" : ""} ${timeConversion(item.Scheduled_StartTime)} ${item.BatchName}`,
   end: DateTimeGenerator(item.Scheduled_EndTime, item.Scheduled_StartDate),
   color: (item?.ClassStatus == 3 || item?.CancelRequestStatus == 1) ? "red" : item.ClassStatus == 1 ? 'Burlywood' : 'rgba(4, 135, 54, 0.96)',
   TutorName: item.TutorName,
   BatchSize: item.BatchSize,
   BatchName: item.BatchName,
   CourseName: item.CourseName,
   ScheduleClassID: item.ScheduleClassID,
  };
 });

 return calenderData;
};