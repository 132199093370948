import React, { useState } from "react";
import EarningClasses from "./Earning.module.css";
import AllPayouts from "./earnings";
import Details from "./details";
function Payout() {
    const [Type, setType] = useState(1);
    const [payoutId, setPayoutId] = useState("");
    return (
        <div
            className={`${EarningClasses["Container"]} ${
                Type == 2 ? EarningClasses["Container_2"] : ""
            } `}>
            {Type === 1 && (
                <AllPayouts
                    Type={Type}
                    setType={setType}
                    setPayoutId={setPayoutId}
                />
            )}
            {Type === 2 && (
                <Details
                    Type={Type}
                    setType={setType}
                    setPayoutId={setPayoutId}
                    payoutId={payoutId}
                />
            )}
        </div>
    );
}

export default Payout;
