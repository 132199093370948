import React, { useState } from "react";
import RegisterClasses from "./Activity.module.css";
import { useSearchParams } from "react-router-dom";
import SkeletonLoad from "./skeleton";
import { useNavigate } from "react-router-dom";
import { Services } from "../../../../../Services";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";
function Activity() {
    const [params, setParams] = useSearchParams();
    const Navigate = useNavigate();
    const [Active, setActive] = useState("Opened");
    const [Activity, setActivity] = useState([]);
    const [Loading, setLoading] = useState(true);
    const navHandler = (e) => {
        let btntype = e.currentTarget.getAttribute("btntype");
        setActive(btntype);
    };
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        const loginData = JSON.parse(localStorage.getItem("admindata"));
        Services.ActivityLogs("GET", null, token, params.get("TutorId"), 3)
            .then((res) => {
                console.log(res);
                setTimeout(() => {
                    setLoading(false);
                }, 300);
                if (res.Status === 1) {
                    setActivity(res.ScheduleClasess);
                } else if (res.Status === 0) {
                    toast.error(`${res.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                    if (res.Message === "Token expired") {
                        Navigate("/");
                    }
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    return (
        <>
            {Loading ? (
                <SkeletonLoad />
            ) : (
                <div className={RegisterClasses["Container"]}>
                    <div className={RegisterClasses["jobs"]}>
                        <header>
                            <h3>Jobs</h3>
                            <div className={RegisterClasses["header-left"]}>
                                <div>
                                    <h4>Open Jobs : </h4>
                                    <p>0 </p>
                                </div>
                                <div>
                                    <h4>Total Jobs : </h4>
                                    <p>0 </p>
                                </div>
                            </div>
                            <div className={RegisterClasses["header-right"]}>
                                <div>
                                    <h4>Total hours worked : </h4>
                                    <p>0 hours </p>
                                </div>
                            </div>
                        </header>
                        <div className={RegisterClasses["Assigned-Jobs"]}>
                            <div className={RegisterClasses["sub-nav"]}>
                                <button
                                    className={
                                        Active == "Opened"
                                            ? RegisterClasses["Active"]
                                            : ""
                                    }
                                    onClick={navHandler}
                                    btntype="Opened">
                                    Open
                                </button>
                                <button
                                    className={
                                        Active == "Finished"
                                            ? RegisterClasses["Active"]
                                            : ""
                                    }
                                    onClick={navHandler}
                                    btntype="Finished">
                                    Finished
                                </button>
                            </div>
                            <div className={RegisterClasses["table-container"]}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Date Created</th>
                                            <th>Students</th>
                                            <th>Clients</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* <tr>
                                            <td>
                                                <a href="">
                                                    Active_1on1_Raisha Gupta
                                                </a>
                                            </td>
                                            <td> 13/04/2023</td>
                                            <td>
                                                <a href="/">Raisha Gupta</a>
                                            </td>
                                            <td>
                                                {" "}
                                                <a href="">Isha Gupta</a>
                                            </td>
                                            <td>
                                                <p
                                                    className={
                                                        RegisterClasses[
                                                            "inprogress"
                                                        ]
                                                    }>
                                                    In Progress
                                                </p>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td
                                                colSpan={5}
                                                style={{ textAlign: "center" }}>
                                                No Data Found
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={RegisterClasses["applications"]}>
                        <header>
                            <h3>Job Applications</h3>
                            <div className={RegisterClasses["header-left"]}>
                                <div>
                                    <h4>Total Applications : </h4>
                                    <p>0</p>
                                </div>
                                <div>
                                    <h4>Rejected applications : </h4>
                                    <p>0</p>
                                </div>
                            </div>
                            <div className={RegisterClasses["header-right"]}>
                                <div>
                                    <h4>Accepted Applications: </h4>
                                    <p>0 </p>
                                </div>
                                <div>
                                    <h4>Withdrawn Applications: </h4>
                                    <p>0 </p>
                                </div>
                            </div>
                        </header>
                    </div>
                    <div className={RegisterClasses["charges-tasks"]}>
                        <div className={RegisterClasses["charges"]}>
                            <h3>Ad Hoc charges</h3>
                            <p>No Ad Hoc Charges</p>
                        </div>
                        <div className={RegisterClasses["charges"]}>
                            <h3>Tasks</h3>
                            <p>No Tasks</p>
                        </div>
                        <div className={RegisterClasses["Activity-feed"]}>
                            <h3>Activity Feed</h3>
                            <ul>
                                {Activity.length > 0
                                    ? Activity.map((item) => (
                                          <li>
                                              {" "}
                                              <span>
                                                  {item.ActionByName} •{" "}
                                                  {item.Action}
                                              </span>
                                              <span>
                                                  {" "}
                                                  <Moment format="Do MMM YYYY hh:mm a">
                                                      {new Date(item.CreatedAt)}
                                                  </Moment>
                                              </span>
                                          </li>
                                      ))
                                    : "No Logs found"}
                                {/* {Activity.map((item) => {
                                    let link = "";
                                    if (item.ContentType === 2) {
                                        link = `/scheduledClasses/Details?scheduleID=${item.ContentID}`;
                                    }
                                    if (item.ContentType === 1) {
                                        link = `/Batches/Details?BatchId=${item.ContentID}`;
                                    }
                                    if (
                                        item.ContentType === 3 ||
                                        item.ContentType === 17 ||
                                        item.ContentType === 4
                                    ) {
                                        return (
                                            <p>
                                                {" "}
                                                {item.ActionByName} •{" "}
                                                {item.Action}
                                            </p>
                                        );
                                    }
                                    if (link.length > 0) {
                                        return (
                                            <li>
                                                <NavLink to={link}>
                                                    {item.ActionByName} •{" "}
                                                    {item.Action}
                                                </NavLink>
                                            </li>
                                        );
                                    }
                                    return "-";
                                })} */}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Activity;
