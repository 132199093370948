import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout";
import AdminDashboard from "./Admin/Admin";
import DashboardClasses from "./dashboard.module.css";
import { Helmet } from "react-helmet";
// import { useForm } from "react-hook-form";
// import { Services } from "Services";
// import Loader from "Components/loader/loader";
// import { warningSwal } from "Util/Toast";
function Dasboard() {
    const AdminData = JSON.parse(localStorage.getItem("admindata"));

    // const [loading, setLoading] = useState(true);
    // const {
    //     register,
    //     handleSubmit,
    //     reset,
    //     setValue,
    //     formState: { errors },
    // } = useForm();
    // const [count, setCount] = useState({
    //     AdminCancelCount: 0,
    //     TutorCancelCount: 0,
    //     ParentCancelCount: 0,
    //     TotalCancelledClasses: 0,
    // });
    // const token = { Authorization: `token ${localStorage.getItem("token")}` };
    // const get_cancelledClasses = (queryParams) => {
    //     Services.GetDahboard_Cancelled_Classes("GET", null, token, queryParams)
    //         .then((response) => {
    //             console.log(response);
    //             setTimeout(() => {
    //                 setLoading(false);
    //             }, 200);
    //             if (response.Status === 1) {
    //                 setCount(response.CancelClassCount[0]);
    //             } else if (response.Status === 0) {
    //                 warningSwal("Warning", response.Message);
    //             }
    //         })
    //         .catch((err) => {
    //             setLoading(false);
    //             // alert(err);
    //             alert("something went wrong please try again");
    //             console.log(err);
    //         });
    // };
    // useEffect(() => {
    //     get_cancelledClasses(null);
    // }, []);
    // const onSubmit = (data) => {
    //     if (data.FromDate > data.ToDate) {
    //         warningSwal("Warning", "From Date should be less than To Date");
    //         return false;
    //     }
    //     setLoading(true);
    //     get_cancelledClasses(data);
    // };

    return (
        <Layout Active={"Dashboard"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Dashboard</title>
            </Helmet>
            <div className={DashboardClasses["Container"]}>
                {AdminData?.Type == 1 && <AdminDashboard />}
            </div>
            {/* <div className={DashboardClasses["Container"]}>
                <h3>Dashboard</h3>
                <form
                    className={DashboardClasses["form"]}
                    onSubmit={handleSubmit(onSubmit)}>
                    <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("FromDate", {
                            required: true,
                        })}
                    />
                    <input
                        type="date"
                        max={new Date().toISOString().split("T")[0]}
                        {...register("ToDate", {
                            required: true,
                        })}
                    />
                    <button>Submit</button>
                </form>
                {loading && <Loader />}
                <div className={DashboardClasses["analytics"]}>
                    <div className={DashboardClasses["analytics_card"]}>
                        <p>
                            {" "}
                            {count.TotalCancelledClasses
                                ? count.TotalCancelledClasses
                                : 0}
                        </p>
                        <h3>Total cancelled classes</h3>
                    </div>
                    <div className={DashboardClasses["analytics_card"]}>
                        <p>
                            {" "}
                            {count.AdminCancelCount
                                ? count.AdminCancelCount
                                : 0}
                        </p>
                        <h3>Admin cancelled classes</h3>
                    </div>
                    <div className={DashboardClasses["analytics_card"]}>
                        <p>
                            {" "}
                            {count.ParentCancelCount
                                ? count.ParentCancelCount
                                : 0}
                        </p>
                        <h3>Parent cancelled classes</h3>
                    </div>

                    <div className={DashboardClasses["analytics_card"]}>
                        <p>
                            {" "}
                            {count.TutorCancelCount
                                ? count.TutorCancelCount
                                : 0}
                        </p>
                        <h3>Tutor cancelled classes</h3>
                    </div>
                </div>
            </div> */}
        </Layout>
    );
}

export default Dasboard;
