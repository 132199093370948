import React, { useState, useEffect } from "react";
import Layout from "../../../../Components/Layout";
import AdminClasses from "./admin.module.css";
import { Helmet } from "react-helmet";
import { BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import SkeletonLoad from "./TableLoad/load";
import { Services } from "../../../../Services";
import { toast } from "react-toastify";
import Moment from "react-moment";
import useStore from "../../../../store";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { IoMdSearch } from "react-icons/io";
function Index() {
    const Navigate = useNavigate();
    const [data, setData] = useState([]);
    const [forcepage, setforcepage] = useState(0);
    const [loading, setLoading] = useState(true);
    const RecordCount = useStore((item) => item.NoOfRecords);
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setShowPagiate] = useState(true);
    const [searchString, setSearchString] = useState("");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [params, setparams] = useSearchParams();
    const getAllAdmin = (skip) => {
        let body = {
            toSkip: skip,
            SearchString: searchString,
            whether_Mobile_filter: 0,
            whether_Name_filter: 0,
        };
        if (searchString.length > 0) {
            if (isNaN(searchString)) {
                body = { ...body, whether_Name_filter: 1 };
            } else {
                body = { ...body, whether_Mobile_filter: 1 };
            }
        }
        Services.GetAdmin("POST", JSON.stringify(body), token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.AdminUsers);
                    if (
                        response.AdminUsers.length == 0 ||
                        response.Count <= RecordCount
                    ) {
                        setShowPagiate(false);
                    } else {
                        if (!showPaginate) {
                            setShowPagiate(true);
                        }
                        setpageCount(
                            Math.ceil(
                                Number(response?.Count) / Number(RecordCount)
                            )
                        );
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            let Page = params.get("page");

            setLoading(true);
            getAllAdmin((Number(Page) - 1) * RecordCount);
            setforcepage(Number(params.get("page")) - 1);
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [searchString]);

    const detailsNav = (e) => {
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        let type = e.currentTarget.getAttribute("type");
        Navigate(`/Administrators/details?ID=${id}&type=${type}`);
        localStorage.setItem("name", name);
    };
    const handlePageChange = (selectedObject) => {
        setparams({ page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "instant" });
        setforcepage(selectedObject.selected);
        getAllAdmin(selectedObject.selected * RecordCount);
        setLoading(true);
    };
    const searchHandler = (e) => {
        setparams({ page: 1 });
        setforcepage(0);
        setSearchString(e.target.value);
    };
    return (
        <Layout Active={"Administrators"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Administrators</title>
            </Helmet>
            <div className={AdminClasses["Container"]}>
                <div className={AdminClasses["control-btns"]}>
                    <button
                        className={AdminClasses["button"]}
                        onClick={() => {
                            Navigate("/administrator/add");
                        }}>
                        <BiPlus size={20} /> Create New Administrator
                    </button>
                </div>

                <div className={AdminClasses["admin"]}>
                    <h3>Administrators</h3>
                    {/* <select>
                        <option value="">Label Filter</option>
                    </select> */}
                    <div className={AdminClasses["search"]}>
                        <input
                            type="text"
                            onChange={searchHandler}
                            value={searchString}
                            placeholder="Search by name or mobile number"
                        />
                        <IoMdSearch
                            className={AdminClasses["search-icon"]}
                            size={26}
                        />
                    </div>
                    {loading ? (
                        <SkeletonLoad />
                    ) : (
                        <div className={AdminClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Shift Timings</th>
                                        <th>Admin Type</th>
                                        <th>Created </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.length > 0 ? (
                                        data.map((item) => (
                                            <tr
                                                onClick={detailsNav}
                                                id={item.AdminUserID}
                                                type={item?.Type}
                                                key={item.AdminUserID}
                                                name={`${item.FirstName} ${item.LastName}`}>
                                                <td>
                                                    {item.FirstName}{" "}
                                                    {item.LastName}
                                                </td>
                                                <td>
                                                    {" "}
                                                    {item?.CountryCode !=
                                                        "NA" &&
                                                        `+${item.CountryCode}`}{" "}
                                                    {item.MobileNumber}
                                                </td>
                                                <td>{item.EmailID}</td>
                                                <td>
                                                    {item.ShiftFromTime}-
                                                    {item.ShiftToTime}
                                                </td>
                                                <td>
                                                    {" "}
                                                    {item.Type === 1 && "ADMIN"}
                                                    {item.Type === 2 &&
                                                        "RELATIONSHIP MANAGER"}
                                                    {item.Type === 5 &&
                                                        "SALES MANAGER"}
                                                </td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.CreatedAt}
                                                    </Moment>{" "}
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>No Admin Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Index;
