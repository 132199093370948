import React, { useState } from "react";
import { BsFillPeopleFill } from "react-icons/bs";
import { FaRupeeSign } from "react-icons/fa";
import { MdGroupOff } from "react-icons/md";
import { BsFillPenFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import Summary from "./Summary";
import DashboardClasses from "../dashboard.module.css";
import "react-datepicker/dist/react-datepicker.css";
import {
    AreaChart,
    Area,
    XAxis,
    Bar,
    BarChart,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";

const data = [
    { name: "Day 1", Registrations: 4000 },
    { name: "Day 2", Registrations: 3000 },
    { name: "Day 3", Registrations: 2000 },
    { name: "Day 4", Registrations: 2780 },
    { name: "Day 5", Registrations: 1890 },
    { name: "Day 6", Registrations: 2390 },
    { name: "Day 7", Registrations: 3490 },
];
const data4 = [
    { name: "Day 1", Registrations: 4000 },
    { name: "Day 2", Registrations: 3000 },
    { name: "Day 3", Registrations: 2000 },
    { name: "Day 4", Registrations: 2780 },
    { name: "Day 5", Registrations: 1890 },
    { name: "Day 6", Registrations: 2390 },
    { name: "Day 7", Registrations: 3490 },
    { name: "Day 8", Registrations: 1490 },
    { name: "Day 9", Registrations: 490 },
    { name: "Day 10", Registrations: 790 },
    { name: "Day 11", Registrations: 980 },
    { name: "Day 12", Registrations: 3090 },
    { name: "Day 13", Registrations: 90 },
    { name: "Day 14", Registrations: 150 },
];
const data2 = [
    { week: "Week 1", registrations: 400 },
    { week: "Week 2", registrations: 300 },
    { week: "Week 3", registrations: 200 },
    { week: "Week 4", registrations: 278 },
    { week: "Week 6", registrations: 1178 },
    // Add more weeks as needed
];
const data3 = [
    { month: "Feb 2023", Registrations: 4000 },
    { month: "Mar 2023", Registrations: 3000 },
    { month: "Apr 2023", Registrations: 2000 },
    { month: "May 2023", Registrations: 2780 },
    { month: "Jun 2023", Registrations: 1890 },
    { month: "Jul 2023", Registrations: 2390 },
    { month: "Aug 2023", Registrations: 3490 },
    { month: "Sep 2023", Registrations: 4000 },
    { month: "Oct 2023", Registrations: 3000 },
    { month: "Nov 2023", Registrations: 2000 },
    { month: "Dec 2023", Registrations: 2780 },
    { month: "Jan 2024", Registrations: 1890 },
];
function Sales() {
    const [startDate, setStartDate] = useState(new Date());
    return (
        <>
            <div className={DashboardClasses["sales"]}>
                <header>
                    <h3>Sales</h3>
                    <select>
                        <option value="Sales">Total Sales</option>
                    </select>
                </header>
                <div className={DashboardClasses["Summary_container"]}>
                    <Summary
                        Count={`2000`}
                        icon={<FaRupeeSign size={22} />}
                        heading={"Total Revenue "}
                        prefix={"INR  "}
                    />
                    <Summary
                        Count={`200`}
                        icon={<BsFillPenFill size={28} />}
                        heading={"New Registrations"}
                        prefix={" "}
                    />
                    <Summary
                        prefix={" "}
                        Count={`200`}
                        icon={<BsFillPeopleFill size={28} />}
                        heading={"Live Students"}
                    />
                    <Summary
                        Count={`200`}
                        prefix={" "}
                        icon={<MdGroupOff size={28} />}
                        heading={"Dormant Students"}
                    />
                </div>
            </div>
            <div className={DashboardClasses["Charts"]}>
                <div
                    className={DashboardClasses["chart"]}
                    style={{ gridColumn: "1/-1" }}>
                    <h3>Revenue from new Payments</h3>
                    <ResponsiveContainer width="100%" height={"100%"}>
                        <AreaChart
                            data={data4}
                            margin={{
                                top: 5,
                                right: 10,
                                left: 0,
                                bottom: 5,
                            }}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1">
                                    <stop
                                        offset="5%"
                                        stopColor="#355ba1"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#355ba1"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name" hide={true} />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Area
                                type="monotone"
                                dataKey="Registrations"
                                stroke="#355ba1"
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className={DashboardClasses["chart"]}>
                    <h3>Daily Registrations</h3>
                    <ResponsiveContainer width="100%" height={"100%"}>
                        <AreaChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 10,
                                left: 0,
                                bottom: 5,
                            }}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1">
                                    <stop
                                        offset="5%"
                                        stopColor="#355ba1"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#355ba1"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis dataKey="name" hide={true} />
                            <YAxis />
                            <CartesianGrid strokeDasharray="3 3" />
                            <Tooltip />
                            <Legend />
                            <Area
                                type="monotone"
                                dataKey="Registrations"
                                stroke="#355ba1"
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
                <div className={DashboardClasses["chart"]}>
                    <h3>Weekly Registrations</h3>
                    <div className={DashboardClasses["date-picker"]}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            dateFormat="MM/yyyy"
                            maxDate={new Date()}
                            showMonthYearPicker
                            className={
                                DashboardClasses["date-picker-container"]
                            }
                        />
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                            layout="vertical"
                            data={data2}
                            margin={{
                                top: 5,
                                right: 10,
                                left: 20,
                                bottom: 35,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" hide />
                            <YAxis dataKey="week" type="category" />
                            <Tooltip />
                            <Legend />
                            <Bar
                                dataKey="registrations"
                                fill="#355ba1"
                                barSize={35}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <div
                    className={DashboardClasses["chart"]}
                    style={{ gridColumn: "1/-1", maxHeight: "450px" }}>
                    <h3>Monthly Registrations</h3>
                    <div className={DashboardClasses["date-picker"]}>
                        <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            maxDate={new Date()}
                            dateFormat={"yyyy"}
                            showYearPicker
                            className={
                                DashboardClasses["date-picker-container"]
                            }
                        />
                    </div>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            data={data3}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar
                                dataKey="Registrations"
                                fill="#355ba1"
                                barSize={40}
                            />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </>
    );
}

export default Sales;
