import React, { useState, useEffect } from "react";
import CourseClasses from "./course.module.css";
import Layout from "../../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
import BulkUpload from "../Bulkupload/upload";
import Courses from "./course";
function Tutor() {
    const Navigate = useNavigate();
    const [modal, setModal] = useState(false);
    return (
        <Layout Active={"Courses"}>
            <BulkUpload shwoModal={modal} setModal={setModal} />
            <Helmet>
                <meta charSet="utf-8" />
                <title>Courses</title>
            </Helmet>
            <div className={CourseClasses["Container"]}>
                <div className={CourseClasses["control-btns"]}>
                    <button
                        className={CourseClasses["button"]}
                        onClick={() => {
                            Navigate("/courses/add");
                        }}>
                        <BiPlus size={20} /> Add New Course
                    </button>
                    {/* <button
                        className={CourseClasses["button"]}
                        onClick={() => {
                            setModal(true);
                        }}>
                        <BiPlus size={20} /> Bulk upload
                    </button> */}
                </div>
                <div className={CourseClasses["Tutor"]}>
                    <h3>Courses</h3>

                    <Courses />
                </div>
            </div>
        </Layout>
    );
}

export default Tutor;
