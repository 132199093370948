import React, { useRef, useState, useEffect } from "react";
import Modal from "react-modal";
import Styles from "./demo/demo.module.css";
import { Services } from "../../Services";
import ReactLoading from "react-loading";
import Select from "react-select";
import { warningSwal, SuccessSwal } from "../../Util/Toast";
Modal.setAppElement("#root");
function ComplaintModal({ modal, setModal, getDetails, getAllCategories }) {
    const inputRef = useRef();
    const [loading, setLoading] = useState(false);

    const [tutors, setTutors] = useState([]);
    const [selectedTutor, setSelectedTutor] = useState(null);
    const [tutorSearchString, setTutorSearchString] = useState("");
    const [tutorSearchLoad, setTutorSearchLoad] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const closeModal = () => {
        setModal(false);
    };
    const handleTutorChange = (searchString) => {
        setTutorSearchString(searchString);
    };
    const onTutorSelect = (searchString) => {
        setSelectedTutor(searchString);
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (
                typeof tutorSearchString == "string" &&
                tutorSearchString?.length > 0
            ) {
                setTutorSearchLoad(true);

                try {
                    const data = await Services.searchActiveTutor(
                        "POST",
                        JSON.stringify({ SearchString: tutorSearchString }),
                        token,
                        selectedTutor
                    );
                    const formattedOptions = data.Tutors?.filter(
                        (item) => item.TutorStatus == 1
                    )?.map((tutor) => ({
                        label: `${tutor.FirstName} ${tutor.LastName}`,
                        value: tutor.TutorID,
                        Email: tutor.EmailID,
                    }));
                    setTutors(formattedOptions);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setTutorSearchLoad(false);
                }
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
    }, [tutorSearchString]);
    const resolveHanlder = () => {
        setLoading(true);
        Services.assignDemo(
            "POST",
            JSON.stringify({
                TutorID: selectedTutor.value,
                StudentName: getDetails?.StudentName,
                DemoRequestID: getDetails?.DemoRequestID,
                CourseID: getDetails?.CourseID,
            }),
            token
        )
            .then((response) => {
                if (response.Status === 1) {
                    closeModal();

                    SuccessSwal("Success", response.Message);
                    getAllCategories();
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <Modal
            isOpen={modal}
            style={{ height: "500px" }}
            contentLabel="Modal"
            className={Styles["modal"]}
            // onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["modal_Container"]}>
                <header>
                    <h3>Assign Tutor</h3>
                </header>
                <div className={Styles["Complaint_Details"]}>
                    <div>
                        <h3>Tutor:</h3>
                        <Select
                            options={tutors}
                            maxMenuHeight={90}
                            menuPosition="absolute"
                            menuShouldBlockScroll
                            onInputChange={handleTutorChange}
                            value={selectedTutor}
                            onChange={onTutorSelect}
                            placeholder="Search Tutor"
                            isLoading={tutorSearchLoad}
                            styles={{
                                // Set the width to 100%
                                control: (provided) => ({
                                    ...provided,
                                    width: "100%",
                                }),
                            }}
                        />
                    </div>

                    <div></div>
                </div>
                {loading ? (
                    <div className={Styles["loader"]}>
                        <ReactLoading
                            color="green"
                            type="spokes"
                            height={30}
                            width={30}
                        />
                    </div>
                ) : (
                    <div className={Styles["control_Buttons"]}>
                        {getDetails?.Status != 1 && (
                            <button
                                className={Styles["resolve"]}
                                onClick={resolveHanlder}>
                                Assign
                            </button>
                        )}
                        <button
                            className={Styles["close"]}
                            onClick={closeModal}>
                            Close
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    );
}

export default ComplaintModal;
