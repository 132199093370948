import React, { useState, useEffect } from "react";
import DemoClasses from "./demo.module.css";
import Layout from "../../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../../Services";
import { FaSearch } from "react-icons/fa";
import SkeletonLoad from "./TableLoad/load";
import { Error } from "../../../Util/Toast";
import { Success_Dark } from "../../../Util/Toast";
import Moment from "react-moment";
import Modal from "../Modal";
function Demo() {
    const [details, setdetails] = useState({});
    const [showModal, setModal] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const getAllCategories = () => {
        Services.Demo_Request("GET", null, token)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.DemoRequests);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    useEffect(() => {
        getAllCategories(2);
        // eslint-disable-next-line
    }, []);
    const copyHandler = async (copyText) => {
        // let copyText = textRef.current.value;
        try {
            await navigator.clipboard.writeText(copyText);
            Success_Dark("Copied to Clipboard");
        } catch (err) {
            console.error("Failed to copy: ", err);
        }
    };
    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (searchString) {
                setLoading(true);

                try {
                    const data = await Services.Demo_Request_Search(
                        "GET",
                        null,
                        token,
                        searchString
                    );
                    setData(data.DemoRequests);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                getAllCategories();
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
        // eslint-disable-next-line
    }, [searchString]);

    return (
        <Layout Active={"Demo Requests"}>
            {showModal && (
                <Modal
                    getDetails={details}
                    modal={showModal}
                    setModal={setModal}
                    getAllCategories={getAllCategories}
                />
            )}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Demo Requests</title>
            </Helmet>
            <div className={DemoClasses["Container"]}>
                <div className={DemoClasses["control-btns"]}>
                    <div>
                        <FaSearch className={DemoClasses["search-icon"]} />
                        <input
                            onChange={(e) => setSearchString(e.target.value)}
                            value={searchString}
                            type="number"
                            placeholder="Search By Mobile Number"
                        />
                    </div>
                </div>
                <div className={DemoClasses["Tutor"]}>
                    <h3>Demo Requests</h3>
                    {/* <h2>All</h2>{" "} */}
                    {loading ? (
                        <SkeletonLoad count={8} />
                    ) : data.length > 0 ? (
                        <div className={DemoClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Parent</th>
                                        <th>Student</th>
                                        <th>Course</th>
                                        <th>Demo By</th>
                                        <th>Join Url</th>
                                        <th>Start Url</th>
                                        <th>Recording</th>
                                        <th>Requested Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr
                                            onClick={() => {
                                                if (item.IsDemoCreated != 1) {
                                                    setModal(true);
                                                    setdetails(item);
                                                }
                                            }}>
                                            <td
                                                style={{
                                                    width: "10rem",
                                                    wordBreak: "break-word",
                                                }}>
                                                {" "}
                                                {item.ParentName}
                                                <br />({item.CountryCode}
                                                {item.MobileNumber})
                                            </td>
                                            <td>{item.StudentName}</td>
                                            <td>{item.CourseName}</td>

                                            <td>{item.TutorName}</td>
                                            <td>
                                                {item.IsDemoCreated != 1 ? (
                                                    "NA"
                                                ) : (
                                                    <button
                                                        onClick={() =>
                                                            copyHandler(
                                                                item?.JoinURL
                                                            )
                                                        }>
                                                        Copy
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                {item.IsDemoCreated != 1 ? (
                                                    "NA"
                                                ) : (
                                                    <button
                                                        onClick={() =>
                                                            copyHandler(
                                                                item?.StartURL
                                                            )
                                                        }>
                                                        Copy
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                {item?.IsRecorded == 1 ? (
                                                    <>
                                                        <span>
                                                            DownloadUrl:
                                                            <a
                                                                href={
                                                                    item?.DownloadUrl
                                                                }
                                                                target="_blank">
                                                                Download
                                                            </a>
                                                        </span>
                                                        <br />
                                                        <span>
                                                            JoinUrl:
                                                            <a
                                                                target="_blank"
                                                                href={
                                                                    item?.PlayURL
                                                                }>
                                                                Play
                                                            </a>
                                                        </span>
                                                    </>
                                                ) : (
                                                    "NA"
                                                )}
                                            </td>
                                            <td>
                                                <Moment
                                                    subtract={{
                                                        hours: 5,
                                                        minutes: 30,
                                                    }}
                                                    format="DD-MM-YYYY">
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Record Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Demo;
