import React from "react";
import reportsClasses from "./index.module.css";
function DownloadTable({ data }) {
    return (
        <div className={reportsClasses["Table"]}>
            <table>
                <thead>
                    <tr>
                        {Object.keys(data[0]).map((key, index) => (
                            <th key={index}>{key}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            {Object.values(item).map((value, index) => (
                                <td key={index}>{value}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default DownloadTable;
