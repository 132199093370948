import React, { useState, useEffect, useLayoutEffect } from "react";
import Layout from "../../../Components/Layout";
import TutorClasses from "./index.module.css";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import S3FileUpload from "react-s3";
import { config } from "../../../s3config";
import { FileUploader } from "react-drag-drop-files";
import { BsCloudUpload } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "../../../Services";
import { Success, Error, SuccessSwal, warningSwal } from "../../../Util/Toast";
import { useSearchParams } from "react-router-dom";
import ReactLoading from "react-loading";
import { NullCheck } from "../../../Components/validators";

import MainLoader from "../../../Components/loader/loader";
import { BsFileEarmarkPdf } from "react-icons/bs";
window.Buffer = window.Buffer || require("buffer").Buffer;
function Addcategory() {
    const [loading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [edit, setEdit] = useState(false);
    const [base64, setbase64] = useState("");
    const [mainLoad, setMainLoad] = useState(false);
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: { ResourceType: "1" } });
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const formdata = watch();
    useEffect(() => {
        if (searchParams.get("id")) {
            setEdit(true);
            Services.getResourceDetails(
                "GET",
                null,
                token,
                searchParams.get("id")
            )
                .then((response) => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 200);
                    if (response.Status === 1) {
                        reset({
                            ResourceType: response.Resources.ResourceType,
                            ResourceName: response.Resources.ResourceName,
                            TopicCovered: response.Resources.TopicCovered,
                        });
                        setbase64(response.Resources.ResourceUrl);
                        setTimeout(() => {
                            setMainLoad(false);
                        }, 200);
                    } else if (response.Status === 0) {
                        warningSwal("warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
        // eslint-disable-next-line
    }, []);
    useLayoutEffect(() => {
        if (searchParams.get("id")) {
            setMainLoad(true);
        }
    }, []);
    function filehandleChange(files) {
        if (files.type === "application/pdf") {
            const timestamp = Date.now();
            const newfile = new File(
                [files],
                `${timestamp}.${String(files.type).split("/")[1]}`
            );

            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: "Resources",
            })
                .then((res) => {
                    setbase64(res.location);
                    setValue(
                        "ResourceUrl",
                        `${timestamp}.${String(files.type).split("/")[1]}`
                    );
                })
                .catch((err) => {
                    alert(err);
                    console.log(err);
                });
        } else {
            alert(`please upload pdf file`);
            return false;
        }
        return true;
    }

    const onSubmit = (data) => {
        const loginData = JSON.parse(localStorage.getItem("admindata"));
        if (edit) {
            setLoading(true);
            Services.updateResource(
                "PUT",
                JSON.stringify({ ...data, AdminUserType: loginData.Type }),
                token,
                searchParams.get("id")
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        } else {
            if (!data.ResourceUrl) {
                alert("Resource is required");
                return;
            }
            setLoading(true);
            Services.addResources(
                "POST",
                JSON.stringify({ ...data, AdminUserType: loginData.Type }),
                token
            )
                .then((response) => {
                    if (response.Status === 1) {
                        Navigate(-1);
                        SuccessSwal("Success", response.Message);
                    } else if (response.Status === 0) {
                        setLoading(false);
                        warningSwal("warning", response.Message);
                    }
                })
                .catch((err) => {
                    // alert(err);
                    setLoading(false);
                    alert("something went wrong please try again");
                    console.log(err);
                });
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]}>
            <BsCloudUpload className={TutorClasses["upload-icon"]} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.Pdf will be accepted)</p>
        </div>
    );
    const backButton = () => {
        Navigate(-1);
    };
    return (
        <Layout Active={"Resources"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Resources</title>
            </Helmet>
            {mainLoad && <MainLoader />}
            <div className={TutorClasses["Container"]}>
                <button onClick={backButton} className={TutorClasses["back"]}>
                    Back
                </button>
                <div className={TutorClasses["wrapper"]}>
                    <h3>{!edit ? "Add Resource" : "Update Resource"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {/* <h3>Add Category</h3> */}
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">Resource Name</label>
                            <input
                                type="text"
                                {...register("ResourceName", {
                                    required: true,
                                })}
                            />
                            {errors.ResourceName && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="TopicsCovered">
                                Topics Covered
                            </label>
                            <input
                                type="text"
                                {...register("TopicCovered", {
                                    required: true,
                                })}
                            />
                            {errors.TopicCovered && (
                                <span>This field is required</span>
                            )}
                        </div>
                        <div className={TutorClasses["form-control"]}>
                            <label htmlFor="First Name">Type</label>
                            <select
                                {...register("ResourceType", {
                                    required: true,
                                    validate: NullCheck,
                                    onChange: (e) => {
                                        if (base64) {
                                            setbase64(null);
                                        }
                                    },
                                })}
                                defaultValue={"1"}>
                                <option value="1">Song</option>
                                <option value="2">Story</option>
                                <option value="3">Games</option>
                            </select>
                        </div>
                        {formdata.ResourceType == 1 ||
                        formdata.ResourceType == 3 ? (
                            <div className={TutorClasses["form-control"]}>
                                <label htmlFor="TopicsCovered">
                                    Resource Url
                                </label>
                                <input
                                    type="text"
                                    {...register("ResourceUrl", {
                                        required: true,
                                    })}
                                />
                                {errors.ResourceUrl && (
                                    <span>This field is required</span>
                                )}
                            </div>
                        ) : (
                            <>
                                <div
                                    className={
                                        TutorClasses["file-upload-wrapper"]
                                    }>
                                    <header>
                                        <h3>Upload PDF</h3>
                                    </header>
                                    <FileUploader
                                        classes={TutorClasses["upload-image"]}
                                        multiple={false}
                                        handleChange={filehandleChange}
                                        name="file"
                                        children={fileuploadDesign}
                                        // types={fileTypes}
                                    />
                                </div>
                                {base64 && (
                                    <div
                                        className={
                                            TutorClasses["file-images-wrapper"]
                                        }>
                                        <div className={TutorClasses["image"]}>
                                            <button
                                                alt="uploaded-data"
                                                onClick={() => {
                                                    window.open(
                                                        base64,
                                                        "_blank"
                                                    );
                                                }}>
                                                <BsFileEarmarkPdf />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        <button disabled={loading}>
                            {" "}
                            {loading ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcategory;
