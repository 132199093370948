import React, { useState, useEffect } from "react";
import ProfileData from "../profileData/Profile";
import SkeletonLoad from "./Skeleton";
import ProfileClasses from "./profile.module.css";
import { Services } from "../../../../../Services";
import { toast } from "react-toastify";
import { useSearchParams, useNavigate } from "react-router-dom";
import { GrDocumentPdf } from "react-icons/gr";
import pdf from "../../../../../Assets/pdf.png";
import { NavLink } from "react-router-dom";
import Moment from "react-moment";

function Profile({ loading, setLoading, data, setData }) {
    const [params] = useSearchParams();
    const Navigate = useNavigate();

    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    useEffect(() => {
        Services.getParentDetailsByID("GET", null, token, params.get("Id"))
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Parents);
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    }, []);
    const addHandler = () => {
        Navigate(`/student/add?parentId=${data.ParentID}`);
    };
    return (
        <div>
            {loading ? (
                <SkeletonLoad />
            ) : (
                <>
                    <ProfileData data={data} />
                    <div className={ProfileClasses["profile-data"]}>
                        <div className={ProfileClasses["data-wrap"]}>
                            <header>
                                <h3>Students</h3>
                                <button onClick={addHandler}>+ Add</button>
                            </header>
                            {data?.Students?.length > 0 ? (
                                <div className={ProfileClasses["skills"]}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>DOB</th>
                                                <th>School Name</th>
                                                <th>Relation</th>
                                                <th>Academic Year</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.Students?.map((item) => (
                                                <tr>
                                                    <td>
                                                        <NavLink
                                                            onClick={() => {
                                                                localStorage.setItem(
                                                                    "name",
                                                                    `${item.FirstName} ${item.LastName}`
                                                                );
                                                            }}
                                                            to={`/Students/details?id=${item.StudentID}`}>
                                                            {item.FirstName}{" "}
                                                            {item.LastName}
                                                        </NavLink>
                                                    </td>
                                                    <td>
                                                        <Moment format="DD-MM-YYYY">
                                                            {item.DOB}
                                                        </Moment>
                                                    </td>
                                                    <td>
                                                        <span>
                                                            {item.SchoolName}
                                                        </span>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.RelationWithParent
                                                        }
                                                    </td>
                                                    <td>{item.AcademicYear}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                <p>No Stundents Found</p>
                            )}
                            {/* <p>View Accounting</p> */}
                        </div>
                        <div className={ProfileClasses["Documents"]}>
                            <div className={ProfileClasses["data-wrap"]}>
                                <h3>Uploaded Documents</h3>
                                {data?.Documents?.length > 0 ? (
                                    <div
                                        className={
                                            ProfileClasses["updateddocuments"]
                                        }>
                                        {data.Documents.map((item) => {
                                            return item?.Document?.split(
                                                "."
                                            )[1] === "pdf" ? (
                                                <img
                                                    src={pdf}
                                                    onClick={() =>
                                                        window.open(
                                                            item.Get_Documents,
                                                            "_blank"
                                                        )
                                                    }
                                                />
                                            ) : (
                                                <img
                                                    src={item.Get_Documents}
                                                    onClick={() =>
                                                        window.open(
                                                            item.Get_Documents,
                                                            "_blank"
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <p>No Documents Found</p>
                                )}
                            </div>
                            <div className={ProfileClasses["data-wrap"]}>
                                <h3>Notes</h3>
                                {data?.Notes?.length > 0 ? (
                                    <div className={ProfileClasses["files"]}>
                                        {data?.Notes}
                                    </div>
                                ) : (
                                    <p>No Notes Found</p>
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default Profile;
