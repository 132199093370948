import React, { useState, useEffect } from "react";
import approvalclasses from "./approval.module.css";
import { Services } from "../../../Services";
import SkeletonLoad from "./TableLoad/load";
import Moment from "react-moment";
import {
    Error_Dark,
    SuccessSwal,
    Success_Dark,
    warningSwal,
} from "../../../Util/Toast";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
function Tutor({ Active }) {
    const Navigate = useNavigate();
    // eslint-disable-next-line

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    // const admindata = JSON.parse(localStorage.getItem("admindata"));
    const Get_Classes = (data) => {
        Services.Approvals("GET", null, token, data)
            .then((response) => {
                console.log(response);
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Approvals);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };

    useEffect(() => {
        Get_Classes(Active);
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line

    async function Api_Cancel_request(
        scheduleId,
        ZoomMeetingID,
        IsPenalityCharged,
        ScheduleClass_ApprovalID,
        TutorID,
        TutorName,
        ScheduleClass_RequestID
    ) {
        const body = JSON.stringify({
            ZoomMeetingID,
            IsPenalityCharged,
            ScheduleClass_ApprovalID,
            TutorID,
            TutorName,
            ScheduleClass_RequestID,
        });
        try {
            const cancel = await Services.ApprovalCancelrequest(
                "PUT",
                body,
                token,
                scheduleId
            );
            if (cancel?.Status == 1) {
                SuccessSwal("Success", cancel.Message);
                Get_Classes(1);
            }
            if (cancel?.Status == 0) {
                warningSwal("warning", cancel.Message);
            }
        } catch (err) {
            warningSwal("Error", "something went wrong try again");
            console.log(err);
        }
    }
    const ApproveCancelRequest = (
        scheduleId,
        ZoomMeetingID,
        IsPenalityCharged,
        ScheduleClass_ApprovalID,
        TutorID,
        TutorName,
        ScheduleClass_RequestID
    ) => {
        let message =
            IsPenalityCharged == 0
                ? "Are you sure you want to waive penality?"
                : "Are you sure you want to Charge penality?";
        swal(message, {
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: "Cancel",
                catch: {
                    text: IsPenalityCharged == 0 ? "Waive" : "Charge",
                    value: "Logout",
                    className:
                        IsPenalityCharged == 0
                            ? approvalclasses["waive-btn"]
                            : approvalclasses["Charged-btn"],
                },
                defeat: false,
            },
        }).then((value) => {
            switch (value) {
                case "Logout":
                    Api_Cancel_request(
                        scheduleId,
                        ZoomMeetingID,
                        IsPenalityCharged,
                        ScheduleClass_ApprovalID,
                        TutorID,
                        TutorName,
                        ScheduleClass_RequestID
                    );
                    break;

                // default:
                //     swal("Got away safely!");
            }
        });
    };
    const detailsHandler = (e) => {
        e.stopPropagation();
        let id = e.currentTarget.getAttribute("id");
        let name = e.currentTarget.getAttribute("name");
        Navigate(`/scheduledClasses/Details?scheduleID=${id}`);
        localStorage.setItem("name", name);
    };

    return (
        <>
            {loading ? (
                <SkeletonLoad count={3} />
            ) : data.length > 0 ? (
                <div className={approvalclasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Batch Name</th>
                                {/* <th>Class Lesson Name</th> */}
                                <th>Start Date Time</th>
                                <th>Student Name</th>
                                <th>Tutor Name</th>
                                <th>Status</th>
                                <th>Type</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => {
                                return (
                                    <tr
                                        id={item.ScheduleClassID}
                                        name={item.TutorName}
                                        onClick={detailsHandler}>
                                        <td style={{ width: "8rem" }}>
                                            {item.BatchName}
                                        </td>
                                        <td>
                                            <Moment format="DD-MM-YYYY">
                                                {item?.Scheduled_StartDate}
                                            </Moment>{" "}
                                            {item?.Scheduled_StartTime}
                                        </td>
                                        {/* <td>{item.ClassLessonName}</td> */}
                                        <td>{item.StudentName}</td>

                                        <td>{item.TutorName}</td>
                                        <td>
                                            <span
                                                className={
                                                    item.ApproveStatus == 2 ||
                                                    item.ApproveStatus == 3
                                                        ? approvalclasses[
                                                              "pending"
                                                          ]
                                                        : approvalclasses[
                                                              "Approved"
                                                          ]
                                                }>
                                                {item.ApproveStatus == 2
                                                    ? "Pending"
                                                    : item.ApproveStatus == 1
                                                    ? "Waived"
                                                    : "Charged"}
                                            </span>
                                        </td>
                                        <td>
                                            {item?.ApprovalForType == 2 &&
                                                "Attendance"}
                                            {item?.ApprovalForType == 3 &&
                                                "Challenge Penalty"}
                                        </td>
                                        <td>
                                            <div
                                                className={
                                                    approvalclasses[
                                                        "action-btn"
                                                    ]
                                                }>
                                                <button
                                                    className={
                                                        approvalclasses[
                                                            "charge"
                                                        ]
                                                    }
                                                    disabled={
                                                        item.ApproveStatus !== 2
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        ApproveCancelRequest(
                                                            item?.ScheduleClassID,
                                                            item?.ZoomMeetingID,
                                                            1,
                                                            item?.ScheduleClass_ApprovalID,
                                                            item?.TutorID,
                                                            item?.TutorName,
                                                            item?.ScheduleClass_RequestID
                                                        );
                                                    }}>
                                                    Charge Penalty
                                                </button>
                                                <button
                                                    className={
                                                        approvalclasses["waive"]
                                                    }
                                                    disabled={
                                                        item.ApproveStatus !== 2
                                                    }
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        ApproveCancelRequest(
                                                            item?.ScheduleClassID,
                                                            item?.ZoomMeetingID,
                                                            0,
                                                            item?.ScheduleClass_ApprovalID,
                                                            item?.TutorID,
                                                            item?.TutorName,
                                                            item?.ScheduleClass_RequestID
                                                        );
                                                    }}>
                                                    waive penalty
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            ) : (
                <div>
                    {" "}
                    <p>No Records Found</p>
                </div>
            )}
        </>
    );
}

export default Tutor;
