import React, { useEffect } from "react";
import LoginClasses from "./login.module.css";
import LoginLogo from "../../Assets/signup.jpeg";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { menuItems } from "../../Components/SideNav/config";
import { Services } from "../../Services";

function Login() {
    const Navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    useEffect(() => {
        if (localStorage.getItem("token")) {
            let expiry = localStorage.getItem("expiry");
            if (new Date().getTime() > expiry) {
                localStorage.clear();
            } else {
                const AccessItems = JSON.parse(
                    localStorage.getItem("AccessItems")
                );
                if (AccessItems) {
                    let FirstNavigation = AccessItems?.find((item) => {
                        return item.Permissions.read === 1;
                    });

                    if (FirstNavigation?.name) {
                        let Item = findMenuItemByName(
                            FirstNavigation?.name,
                            menuItems
                        );
                        if (Item) {
                            console.log(Item);
                            if (Item.multiple) {
                                let menulink = findMenuItemByName(
                                    FirstNavigation?.name,
                                    Item.items
                                );
                                if (menulink) {
                                    Navigate(menulink.menuLink);
                                }
                            } else {
                                Navigate(Item.menuLink);
                            }
                        }
                    }
                }
            }
        }
    }, []);
    function findMenuItemByName(searchName, menu) {
        const foundItem = menu.find((item) => item.name === searchName);

        if (foundItem) {
            return foundItem;
        }

        // If multiple is true, check inside items
        const multipleItems = menu.filter((item) => item.multiple);

        for (const multipleItem of multipleItems) {
            if (
                multipleItem.items.some(
                    (subItem) => subItem.name === searchName
                )
            ) {
                return multipleItem;
            }
        }

        return null; // Name not found
    }

    const onSubmit = (data) => {
        const body = JSON.stringify(data);
        Services.Login("POST", body)
            .then((response) => {
                if (response.Status === 1) {
                    localStorage.setItem(
                        "admindata",
                        JSON.stringify(response?.adminDetails)
                    );
                    localStorage.setItem("token", response?.token);
                    localStorage.setItem(
                        "AccessItems",
                        JSON.stringify(response?.AccessItems)
                    );
                    localStorage.setItem(
                        "expiry",
                        new Date().getTime() + 24 * 60 * 60 * 1000
                    );
                    let FirstNavigation = response?.AccessItems.find((item) => {
                        return item.Permissions.read === 1;
                    });

                    if (FirstNavigation?.name) {
                        let Item = findMenuItemByName(
                            FirstNavigation?.name,
                            menuItems
                        );
                        if (Item) {
                            if (Item.multiple) {
                                let menulink = findMenuItemByName(
                                    FirstNavigation?.name,
                                    Item.items
                                );
                                if (menulink) {
                                    Navigate(menulink.menuLink);
                                }
                            } else {
                                Navigate(Item.menuLink);
                            }
                        }
                    }
                } else if (response.Status === 0) {
                    toast.error(`${response.Message}`, {
                        position: "top-center",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                    });
                }
            })
            .catch((err) => {
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    return (
        <div className={LoginClasses["login-wrapper"]}>
            <div className={LoginClasses["login-subwrapper"]}>
                <img src={LoginLogo} alt="login-logo" />

                <div className={LoginClasses["login-form"]}>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                {...register("EmailID", {
                                    required: "Email is required!",
                                })}
                            />
                            {errors?.EmailID && (
                                <p className={LoginClasses.error}>
                                    {errors?.EmailID?.message}
                                </p>
                            )}
                        </div>
                        <div className={LoginClasses["form-control"]}>
                            <label htmlFor="password">Password</label>

                            <input
                                type="password"
                                {...register("Password", {
                                    required: "Password is required!",
                                })}
                            />
                            {errors?.Password && (
                                <p className={LoginClasses.error}>
                                    {errors?.Password?.message}
                                </p>
                            )}
                        </div>
                        <input type="submit" value="Log in" />
                        <span className={LoginClasses.border}></span>
                    </form>

                    {/* <div className={LoginClasses["login-links"]}>
                        <NavLink to="/forgotpassword">
                            forgot your Password?
                        </NavLink>
                        <NavLink to="/createaccount">
                            Create Your Account
                        </NavLink>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Login;
