import React from "react";
import ReviewClasses from "./review.module.css";
import Skeleton from "react-loading-skeleton";
function Review() {
    return (
        <div className={ReviewClasses["Container"]}>
            {/* <div className={ReviewClasses["over-view"]}>
                <header>
                    <div className={ReviewClasses["header-left"]}>
                        <Skeleton count={2} width={"70%"} />
                    </div>
                    <div className={ReviewClasses["header-right"]}>
                        <Skeleton count={2} />
                    </div>
                </header>
            </div>
            <div
                className={ReviewClasses["review"]}
                style={{ textAlign: "center", margin: "0.5rem 0" }}>
                <Skeleton
                    count={4}
                    width={"94%"}
                    style={{ margin: "0.3rem 0" }}
                />
            </div> */}
            <table>
                <thead>
                    <tr>
                        <th>Batch Name</th>
                        <th>Student Name</th>

                        <th>Review</th>
                        <th>Rating</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                        <td>
                            <Skeleton />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default Review;
