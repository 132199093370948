import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./generate.module.css";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Services } from "../../../../../../Services";
import { Error, Success_Dark, Error_Dark } from "../../../../../../Util/Toast";
import { AiOutlineClose } from "react-icons/ai";
import ReactLoading from "react-loading";
Modal.setAppElement("#root");

const Filter = ({
    setShowPaymentModal,
    showPaymentModal,
    invoice,
    Transactions,
    MobileNumber,
    TotalWalletAmount,
}) => {
    const [searchparams] = useSearchParams();
    const [checkbox, setcheckbox] = useState(false);
    const {
        register,
        handleSubmit,
        reset,
        resetField,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ defaultValues: { Type: "Credit" } });
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [load, setLoad] = useState(false);
    const [options, setoptions] = useState([]);
    const watchData = watch();
    // CLOSE FORM
    const closeModal = () => {
        setShowPaymentModal(false);
        reset();
    };

    let option1 = [
        { label: "COURSE_FEE", value: "Course fee payment" },
        { label: "BONUS_CREDIT", value: "Bonus Credit" },
        { label: "REVERSALPENALITY", value: "Reversal of penalty" },
        { label: "BALANCECORRECTION", value: "Balance correction" },
    ];

    let option2 = [
        { label: "BALANCEREFUND", value: "Balance Refund" },
        { label: "BALANCECORRECTION", value: "Balance correction" },
    ];
    useEffect(() => {
        resetField("ReasonType");
        setcheckbox(false);
        if (watchData.Type == "Credit") {
            setoptions(option1);
        } else {
            setoptions(option2);
        }
    }, [watchData.Type]);

    useEffect(() => {
        let Reason = options.filter(
            (item) => item.label == watchData?.ReasonType
        );
        console.log(Reason);
        if (Reason.length > 0) {
            setValue("Reason", Reason[0].value);
        }
        if (
            watchData?.ReasonType == "COURSE_FEE" ||
            watchData?.ReasonType == "BALANCEREFUND"
        ) {
            setcheckbox(true);
        } else if (
            watchData?.ReasonType == "BONUS_CREDIT" ||
            watchData?.ReasonType == "REVERSALPENALITY" ||
            watchData?.ReasonType == "BALANCECORRECTION" ||
            watchData?.ReasonType == "BALANCECORRECTION"
        ) {
            setcheckbox(false);
        }
    }, [watchData?.ReasonType]);
    // FORM SUBMIT
    const onSubmit = (formdata) => {
        console.log(formdata);
        if (
            formdata.Type === "Debit" &&
            Number(formdata.Amount) > TotalWalletAmount
        ) {
            Error_Dark("Amount cannot be greater than  wallet Balance");
            return;
        }
        if (Number(formdata.Amount) > invoice?.Amount) {
            Error_Dark("Refund Amount cannot be greater than  Invoice Amount");
            return;
        }
        let body = JSON.stringify({
            Amount: Number(formdata.Amount),
            Comments: formdata?.Comments,
            MobileNumber: MobileNumber,
            InvoiceName: invoice?.InvoiceName,
            WalletID: invoice?.WalletID,
            ParentID: searchparams.get("Id"),
            Type: "Debit",
            ReasonType: "BALANCEREFUND",
            Reason: "Balance Refund",
            isInvoiceGenerated: 0,
        });

        setLoad(true);
        Services.manual_Adjustment("POST", body, token)
            .then((response) => {
                if (response.Status === 1) {
                    Transactions();
                    setLoad(false);
                    Success_Dark(response.Message);
                    closeModal();
                } else if (response.Status === 0) {
                    Error(response.Message);
                    setLoad(false);
                }
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
                Error("something went wrong please try again");
            });
    };
    const blurHandler = (e) => {
        e.preventDefault();
        e.target.blur();

        return false;
    };
    return (
        <Modal
            isOpen={showPaymentModal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>REFUND</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <input
                                placeholder="Amount"
                                type="number"
                                onWheel={blurHandler}
                                onScroll={blurHandler}
                                step={0.01}
                                {...register("Amount", {
                                    required: "This field is required",
                                    validate: (value) => {
                                        if (value < 0) {
                                            return "Amount cannot be a negative number";
                                        }
                                        return true; // Validation passed
                                    },
                                })}
                            />
                        </div>
                        {errors.Amount && (
                            <span className={Styles["error"]}>
                                {errors.Amount.message}
                            </span>
                        )}
                    </div>

                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <textarea
                                rows={5}
                                placeholder="Comments"
                                {...register("Comments", {
                                    required: true,
                                })}
                                type="text"
                            />
                        </div>
                        {errors.Comments && (
                            <span className={Styles["error"]}>
                                This field is required
                            </span>
                        )}
                    </div>

                    {load ? (
                        <div
                            style={{
                                justifySelf: "center",
                                marginTop: "1rem",
                            }}>
                            {" "}
                            <ReactLoading
                                color="green"
                                type="spokes"
                                height={30}
                                width={30}
                            />
                        </div>
                    ) : (
                        <div className={Styles["buttons"]}>
                            <button type="submit" className={Styles["submit"]}>
                                Submit
                            </button>
                        </div>
                    )}
                </form>
            </div>
        </Modal>
    );
};
export default Filter;
