import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./filter.module.css";
import { useForm } from "react-hook-form";

import { Services } from "../../../../Services";
import { Error } from "../../../../Util/Toast";
import { AiOutlineClose } from "react-icons/ai";
import { Preferred_Time } from "./helper";
Modal.setAppElement("#root");

const Filter = ({
    showFilter,
    setShowFilter,
    setFilter,
    setBody,
    Active,
    body,
    setFilterLoad,
}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [courses, setCourses] = useState([]);
    async function GetCourses() {
        try {
            const Response = await Services.getCourses("GET", null, token);
            if (Response.Status === 1) {
                setCourses(Response.Courses);
                reset({ ...body });
            }
        } catch (err) {
            Error("err");
            throw new Error(err);
        }
    }

    useEffect(() => {
        GetCourses();
    }, []);
    // CLOSE FORM
    const closeModal = () => {
        setShowFilter(false);
    };
    function isTime1BeforeTime2(time1, time2) {
        // Convert time strings to 24-hour format (e.g., "00:00 AM" to "00:00" and "12:00 PM" to "12:00")
        const time124 = convertTo24HourFormat(time1);
        const time224 = convertTo24HourFormat(time2);

        // Compare the times
        return time124 < time224;
    }

    function convertTo24HourFormat(timeString) {
        // Split the time string into hours, minutes, and AM/PM
        const [time, ampm] = timeString.split(" ");

        // Split the time into hours and minutes
        let [hours, minutes] = time.split(":").map(Number);

        // Convert to 24-hour format
        if (ampm === "PM" && hours !== 12) {
            hours += 12;
        } else if (ampm === "AM" && hours === 12) {
            hours = 0;
        }

        // Return the time in 24-hour format as a string (e.g., "08:00")
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
            2,
            "0"
        )}`;
    }

    // FORM SUBMIT
    const onSubmit = (formdata) => {
        let body = {};
        let Counter = 0;
        let filterData = {};
        if (formdata.BatchType.length > 0) {
            body = {
                ...body,
                BatchType: formdata.BatchType,
                whether_Batch_filter: 1,
            };
            filterData = { BatchType: formdata.BatchType };
            Counter++;
        }
        if (formdata.CourseID.length > 0) {
            body = {
                ...body,
                CourseID: formdata.CourseID,
                whether_Course_filter: 1,
                CourseName: courses.filter(
                    (item) => item.CourseID == formdata.CourseID
                )[0].CourseName,
            };
            filterData = {
                ...filterData,
                CourseName: courses.filter(
                    (item) => item.CourseID == formdata.CourseID
                )[0].CourseName,
            };
            Counter++;
        }
        if (formdata.PreferredDays.length > 0) {
            body = {
                ...body,

                PreferredDays: formdata.PreferredDays,
                whether_PreferredDays_filter: 1,
            };
            filterData = {
                ...filterData,
                PreferredDays: formdata.PreferredDays,
            };
            Counter++;
        }

        if (formdata.PaymentStatus.length > 0) {
            body = {
                ...body,
                PaymentStatus: formdata.PaymentStatus,
                whether_Payment_filter: 1,
            };
            filterData = {
                ...filterData,
                PaymentStatus:
                    formdata.PaymentStatus == 1
                        ? "pending"
                        : formdata.PaymentStatus == 2
                        ? "Generated"
                        : "paid",
            };
            Counter++;
        }
        if (formdata.FromAge.length > 0 || formdata.ToAge.length > 0) {
            if (formdata.FromAge.length == 0) {
                alert("from age is required");
                return;
            }
            if (formdata.ToAge.length == 0) {
                alert("To age is required");
                return;
            }
            if (Number(formdata.FromAge) > Number(formdata.ToAge)) {
                alert("From Age should be less than ToAge");
                return;
            }
            body = {
                ...body,
                FromAge: formdata.FromAge,
                ToAge: formdata.ToAge,
                whether_Age_filter: 1,
            };
            filterData = {
                ...filterData,
                "FromAge-ToAge": `${formdata.FromAge}-${formdata.ToAge}`,
            };
            Counter++;
        }

        if (formdata.fromTime.length > 0 || formdata.toTime.length > 0) {
            if (formdata.fromTime.length == 0) {
                alert("from Time is required");
                return;
            }
            if (formdata.toTime.length == 0) {
                alert("To Time is required");
                return;
            }
            if (!isTime1BeforeTime2(formdata.fromTime, formdata.toTime)) {
                alert("Invalid Time Range");
                return;
            }
            body = {
                ...body,
                fromTime: formdata.fromTime,
                toTime: formdata.toTime,
                whether_Time_filter: 1,
            };
            filterData = {
                ...filterData,
                "fromTime-toTime": `${formdata.fromTime}-${formdata.toTime}`,
            };
            Counter++;
        }
        setFilter(filterData);
        setFilterLoad(true);
        setBody(() => ({ ...body, Counter, IsAddedToBatch: Active }));
        closeModal();
    };

    return (
        <Modal
            isOpen={showFilter}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Filters</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("CourseID", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Course Name</option>
                                {courses.map((item) => (
                                    <option value={item.CourseID}>
                                        {item.CourseName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("BatchType", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Batch Type</option>
                                <option value="One-to-One">One-to-One</option>
                                <option value="Group">Group</option>
                            </select>
                        </div>
                    </div>

                    <div className={Styles["form-control"]}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("PaymentStatus", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Payment Status</option>
                                <option value={"0"}>Pending</option>
                                <option value={"2"}>Link Generated</option>
                                <option value={"1"}>Paid</option>
                            </select>
                        </div>
                    </div>
                    <div
                        className={Styles["form-control"]}
                        style={{ gridColumn: "1/-1" }}>
                        <div className={Styles["date-input-container"]}>
                            <select
                                {...register("PreferredDays", {
                                    required: false,
                                })}
                                defaultValue={""}>
                                <option value="">Preferred Days</option>
                                <option value={"Monday-Wednesday-Friday"}>
                                    Monday-Wednesday-Friday
                                </option>
                                <option value={"Tuesday-Thursday-Saturday"}>
                                    Tuesday-Thursday-Saturday
                                </option>
                            </select>
                        </div>
                    </div>
                    <div className={Styles["combiner"]}>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <select
                                    {...register("FromAge", {
                                        required: false,
                                    })}
                                    defaultValue={""}>
                                    <option value="">From Age</option>
                                    {Array.from(
                                        { length: 12 },
                                        (_, i) => i + 1
                                    ).map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <select
                                    {...register("ToAge", {
                                        required: false,
                                    })}
                                    defaultValue={""}>
                                    <option value="">To Age</option>
                                    {Array.from(
                                        { length: 12 },
                                        (_, i) => i + 1
                                    ).map((item) => (
                                        <option value={item}>{item}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className={Styles["combiner"]}>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <select
                                    {...register("fromTime", {
                                        required: false,
                                    })}
                                    defaultValue={""}>
                                    <option value=""> From Time</option>
                                    {Preferred_Time.map((item) => (
                                        <option value={item.label}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={Styles["form-control"]}>
                            <div className={Styles["date-input-container"]}>
                                <select
                                    {...register("toTime", {
                                        required: false,
                                    })}
                                    defaultValue={""}>
                                    <option value=""> To Time</option>
                                    {Preferred_Time.map((item) => (
                                        <option value={item.label}>
                                            {item.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className={Styles["buttons"]}>
                        <button type="submit" className={Styles["submit"]}>
                            Filter
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default Filter;
