import React, { useState, useEffect } from "react";
import { Services } from "../../../../../Services";
import LinkClass from "./links.module.css";
import Skeleton from "./skeleton";
import { useSearchParams } from "react-router-dom";
import Moment from "react-moment";
import PaymentGenerate from "../generatePaymentLink/generate";

function Transactions() {
    const [params] = useSearchParams();
    const [Loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [transaction, setTransactions] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        Transactions();
    }, []);
    async function Transactions() {
        try {
            const Transactions = await Services.manual_PaymentLink(
                "POST",
                JSON.stringify({ ParentID: params.get("Id") }),
                token
            );
            setTimeout(() => {
                setLoading(false);
            }, 500);
            if (Transactions.Status === 1) {
                setTransactions(Transactions.PaymentLinks);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            alert("Something went wrong try again");
        }
    }
    return (
        <div className={LinkClass["Container"]}>
            <PaymentGenerate
                showPaymentModal={modal}
                setShowPaymentModal={setModal}
                Transactions={Transactions}
            />
            <header>
                <h3>Payments Links</h3>
                <div>
                    <button onClick={() => setModal(true)}>
                        Generate Payment Link
                    </button>
                </div>
            </header>
            {Loading ? (
                <Skeleton />
            ) : (
                <table>
                    <thead>
                        <tr>
                            <th>Link</th>
                            <th>Amount</th>
                            <th>Payment Status</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {transaction.length > 0 ? (
                            transaction?.map((item) => (
                                <tr>
                                    <td>
                                        {item.PaymentLink &&
                                        item.PaymentLink != "NA" ? (
                                            <a
                                                href={item.PaymentLink}
                                                target="_blank"
                                                rel="noopener noreffer">
                                                {item.PaymentLink}
                                            </a>
                                        ) : (
                                            "NA"
                                        )}
                                    </td>
                                    <td>₹ {Number(item.Amount).toFixed(2)}</td>
                                    <td>
                                        {item.PaymentStatus === 1 && (
                                            <span
                                                className={
                                                    LinkClass["Status-active"]
                                                }>
                                                Paid
                                            </span>
                                        )}
                                        {item.PaymentStatus === 2 && (
                                            <span
                                                className={
                                                    LinkClass["Status-inactive"]
                                                }>
                                                Pending
                                            </span>
                                        )}
                                        {item.PaymentStatus === 3 && (
                                            <span
                                                className={
                                                    LinkClass["Status-inactive"]
                                                }>
                                                Failed
                                            </span>
                                        )}
                                    </td>
                                    <td>
                                        <Moment format="DD-MM-YYYY">
                                            {new Date(item.CreatedAt)}
                                        </Moment>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} style={{ textAlign: "center" }}>
                                    No Record Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            )}
        </div>
    );
}

export default Transactions;
