import React from "react";
import DashboardClasses from "../dashboard.module.css";
import Summary from "./Summary";
import { TbMessageCancel } from "react-icons/tb";
import { TbUserCancel } from "react-icons/tb";
import { RiParentFill } from "react-icons/ri";
import { FaChalkboardTeacher } from "react-icons/fa";

function Cancellation() {
    return (
        <div className={DashboardClasses["sales"]}>
            <header>
                <h3>Cancellation Metrics</h3>
            </header>
            <div className={DashboardClasses["Summary_container"]}>
                <Summary
                    Count={`2000`}
                    icon={<TbMessageCancel size={28} />}
                    heading={"Total Cancellations"}
                />
                <Summary
                    Count={`2000`}
                    icon={<TbUserCancel size={28} />}
                    heading={"Admin Cancellations"}
                />
                <Summary
                    Count={`245`}
                    icon={<RiParentFill size={28} />}
                    heading={"Parent Cancellations"}
                />
                <Summary
                    Count={`654`}
                    icon={<FaChalkboardTeacher size={28} />}
                    heading={"Tutor Cancellations"}
                />
            </div>
        </div>
    );
}

export default Cancellation;
