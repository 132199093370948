import React, { useState, useEffect } from "react";
import ContactClasses from "./index.module.css";
import Layout from "../../Components/Layout/index";
import { Helmet } from "react-helmet";
import { Services } from "../../Services";
import SkeletonLoad from "./TableLoad/load";
import { Error, Error_Dark } from "../../Util/Toast";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { AccessControl } from "../../Util/AccessControl";
import { AccessKeys } from "../../Routes/config";
import { FaSearch } from "react-icons/fa";
import swal from "sweetalert";
import { AiFillDelete } from "react-icons/ai";
import Loader from "../../Components/loader/loader";
import { Success_Dark, SuccessSwal, warningSwal } from "../../Util/Toast";
function Enquiry() {
    const [loading, setLoading] = useState(true);
    const [deleteLoad, setDeleteLoad] = useState(false);
    const [data, setData] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };
    const [searchString, setSearchString] = useState("");
    const Navigate = useNavigate();
    const getAllCategories = () => {
        Services.GetResources("GET", null, token)
            .then((response) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if (response.Status === 1) {
                    setData(response.Resources);
                } else if (response.Status === 0) {
                    Error(response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                // alert(err);
                alert("something went wrong please try again");
                console.log(err);
            });
    };
    const DeleteResource = async (id, type) => {
        try {
            const data = await Services.delete_resource(
                "Delete",
                JSON.stringify({
                    ResourceType: type,
                }),
                token,
                id
            );
            if (data.Status === 1) {
                SuccessSwal("Success", data.Message);
                setData((prev) => prev.filter((item) => item.ResourceID != id));
            } else {
                warningSwal("Warning", data.Message);
            }
        } catch (error) {
            Error_Dark("Something went wrong try again");
            console.error("Error Deleting Resource:", error);
        } finally {
            setTimeout(() => {
                setDeleteLoad(false);
            }, 200);
        }
    };
    const deleteHandler = async (e) => {
        let id = e.currentTarget.getAttribute("id");
        let ResourceType = e.currentTarget.getAttribute("type");

        try {
            const data = await Services.delete_resource_message(
                "POST",
                JSON.stringify({
                    ResourceID: id,
                }),
                token
            );
            if (data.Status === 1) {
                swal(
                    "Are you sure you want to Delete Resource?",
                    data.Message,
                    {
                        icon: "warning",
                        dangerMode: true,
                        buttons: {
                            cancel: "Cancel",
                            catch: {
                                text: "Delete",
                                value: "Logout",
                                className: ContactClasses["cancel"],
                            },
                            defeat: false,
                        },
                    }
                ).then((value) => {
                    switch (value) {
                        case "Logout":
                            setDeleteLoad(true);
                            DeleteResource(id, ResourceType);
                    }
                });
            } else {
                Error_Dark(data.Message);
            }
        } catch (error) {
            console.error("Error fetching options:", error);
        } finally {
            setTimeout(() => {
                setDeleteLoad(false);
            }, 200);
        }
    };

    useEffect(() => {
        const debouncedSearch = setTimeout(async () => {
            if (searchString) {
                setLoading(true);

                try {
                    const data = await Services.ResourceSearch(
                        "POST",
                        JSON.stringify({
                            SearchString: searchString,
                        }),
                        token
                    );
                    setData(data.Resources);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                getAllCategories();
            }
        }, 300);

        return () => clearTimeout(debouncedSearch);
        // eslint-disable-next-line
    }, [searchString]);

    const addHandler = () => {
        Navigate("/Resource/add");
    };
    const editHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        let id = e.currentTarget.getAttribute("id");
        Navigate(`/Resource/edit?id=${id}`);
    };

    return (
        <Layout Active={"Resources"}>
            {deleteLoad && <Loader />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Resources</title>
            </Helmet>
            <div className={ContactClasses["Container"]}>
                <div className={ContactClasses["control-btns"]}>
                    <button
                        className={ContactClasses["button"]}
                        onClick={addHandler}>
                        Add Resource
                    </button>{" "}
                    <div>
                        <FaSearch className={ContactClasses["search-icon"]} />
                        <input
                            onChange={(e) => setSearchString(e.target.value)}
                            value={searchString}
                            type="text"
                            placeholder="Search By Resource Name"
                        />
                    </div>
                </div>
                <div className={ContactClasses["Tutor"]}>
                    <h3> Resources</h3>
                    {loading ? (
                        <SkeletonLoad count={3} />
                    ) : data.length > 0 ? (
                        <div className={ContactClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Resource </th>
                                        <th>Topic Covered </th>
                                        <th>Resource Type </th>
                                        <th>Material </th>
                                        <th>Created At</th>
                                        {AccessControl(AccessKeys.Resources)
                                            ?.edit === 1 && <th>Actions</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr>
                                            <td> {item.ResourceName}</td>
                                            <td> {item.TopicCovered}</td>
                                            <td>
                                                {item.ResourceType === 1
                                                    ? "song"
                                                    : item.ResourceType === 2
                                                    ? "Story"
                                                    : "GamesUrl"}
                                            </td>
                                            <td style={{ maxWidth: "200px" }}>
                                                <a
                                                    href={item.ResourceUrl}
                                                    target="_blank"
                                                    rel="noreferrer">
                                                    Resource
                                                </a>
                                            </td>

                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {new Date(item.CreatedAt)}
                                                </Moment>
                                            </td>
                                            {AccessControl(AccessKeys.Resources)
                                                ?.edit === 1 && (
                                                <td>
                                                    <div
                                                        className={
                                                            ContactClasses[
                                                                "Action-btns"
                                                            ]
                                                        }>
                                                        <button
                                                            id={item.ResourceID}
                                                            onClick={
                                                                editHandler
                                                            }>
                                                            <FaRegEdit />
                                                        </button>
                                                        <button
                                                            type={
                                                                item.ResourceType
                                                            }
                                                            id={item.ResourceID}
                                                            onClick={
                                                                deleteHandler
                                                            }>
                                                            <AiFillDelete color="red" />
                                                        </button>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            {" "}
                            <p>No Resources Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Enquiry;
